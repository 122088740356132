// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { LoggingFactoryService, DayTimeService } from '@cxt-cee-chat/merc-ng-core';
import { CeeDropdownMenuComponent } from '@cxt-cee-chat/merc-pattern-lib';
import { Store } from '@ngrx/store';
import { BannerDetails } from 'projects/entities/src/lib/domain/models/banner-details';
import { ChatSummaryData } from 'projects/entities/src/lib/domain/models/chatSummaryData';
import { AsyncEngagementTranscript } from 'projects/entities/src/lib/domain/models/chatTranscript';
import { DataCollectionState } from 'projects/entities/src/lib/domain/models/enums';
import { SelectedHistoryEngagementSource } from 'projects/entities/src/lib/domain/models/selected-history-engagement';
import { Suggestion } from 'projects/entities/src/lib/domain/models/suggestion';
import { fromChat, fromSettings } from 'projects/entities/src/lib/domain/selectors';
import { TimestampHelper } from 'projects/entities/src/lib/utils/timestamp-helper';
import {
  AppState, Chat, ChatActions, ChatHelper, ChatInteraction, ChatMessageType, ChatOperations, ChatTranscript,
  FeatureFlags,
  getAsyncEngagementsSummaryPayload,
  getChatMessages,
  getLastAgentAsyncChatMessageSenderId,
  getLastAsyncChatMessageText,
  getLastAsyncChatMessageTimestamp,
  getSelectedHistoryEngagement,
  hasFeatureFlag,
  isCustomerTyping,
  LogHelper,
  SelectedHistoryEngagement,
  SenderType,
  UiActions,
  UserIdentityService
} from 'projects/entities/src/public_api';
import { fromEvent, Observable, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, filter, map, skip, startWith, take, takeUntil } from 'rxjs/operators';
import { SubscriberComponent } from 'src/app/subscribed-container';
import { ObservableHelper } from 'src/testing/observable-helper';

@Component({
  selector: 'merc-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ChatComponent extends SubscriberComponent
  implements OnInit, AfterViewInit {

  public readonly scrollFuzz: number = 100;
  public readonly xaSuggestionScrollFuzz: number = 200;

  @Input() public chat: Chat;

  public allEngagementsLoaded$: Observable<boolean>;
  public chatMessages$: Observable<ChatInteraction[]>;
  public hideAnimation$: Observable<boolean>;
  public isLazyLoadEnabled$: Observable<boolean>;
  public isLoadingPriorEngagements$: Observable<boolean>;
  public selectedChatCustomerIsTyping$: Observable<boolean>;
  public selectedChatCustomerName$: Observable<string>;
  public showJumpToLatestButton$: Observable<boolean>;
  public asyncEngagementsLoaded$: Observable<boolean>;
  public xaSolvesFeatureFlag$: Observable<boolean>;  
  //Also to determine if chat is eligible for translation
  public isTranslatedChat$: Observable<boolean>;
  public bannerDetails$: Observable<BannerDetails>;
  public lastAsyncMessageText$: Observable<string>;
  public lastAsyncMessageSenderId$: Observable<string>;
  public lastAsyncMessageTimestamp$: Observable<number>;
  public asyncEngagementsSummaryData$: Observable<ChatSummaryData>;
  public asyncEngagementsSummaryPayload$: Observable<string>;
  public isChatTranslationConfigEnabled$: Observable<boolean>;

  messageType = ChatMessageType;

  public get customerName(): string {
    return ChatHelper.getCustomerUser(this.chat).name;
  }

  @ViewChild('scrollWindow') private chatWindowElem!: ElementRef;
  @ViewChild('currentChat') private currentChatElem!: ElementRef;
  @ViewChildren('messages') private messagesElem!: QueryList<ElementRef>;
  @ViewChildren('priorEngagement', { read: ElementRef }) private priorEngagementComponents!: QueryList<ElementRef>;
  @ViewChildren('asyncEngagement', { read: ElementRef }) private asyncEngagementComponents!: QueryList<ElementRef>;
  @ViewChild('asyncJump', { read: CeeDropdownMenuComponent}) private asyncJumpDropDown!: CeeDropdownMenuComponent;

  private _dispatchScrollIntersectionEvents: boolean = true;
  private _selectedHistoryEngagement$: Observable<SelectedHistoryEngagement>;

  private observer: IntersectionObserver | undefined;
  private intersect$ = new Subject<{ entry: IntersectionObserverEntry, observer: IntersectionObserver }>();

  constructor(
    private store: Store<AppState>,
    private loggingFactory: LoggingFactoryService,
    private identityService: UserIdentityService,
    private timeService: DayTimeService,
  ) {
    super();
  }

  ngOnInit() {
    this.selectedChatCustomerIsTyping$ = this.store
      .select(isCustomerTyping(this.chat.chatId));

    this.hideAnimation$ = this.store
      .select(fromSettings.hideAnimations);

    this._selectedHistoryEngagement$ = this.store
      .select(getSelectedHistoryEngagement(this.chat.chatId));

    this.lastAsyncMessageText$ = this.store
      .select(getLastAsyncChatMessageText(this.chat.chatId));
    this.lastAsyncMessageSenderId$ = this.store
      .select(getLastAgentAsyncChatMessageSenderId(this.chat.chatId));
    this.lastAsyncMessageTimestamp$ = this.store
      .select(getLastAsyncChatMessageTimestamp(this.chat.chatId));
    this.asyncEngagementsSummaryPayload$ = this.store
      .select(getAsyncEngagementsSummaryPayload(this.chat.chatId));

    this.chatMessages$ = this.store
      .select(getChatMessages(this.chat.chatId));

    const historyEngagementSelected = this._selectedHistoryEngagement$
      .pipe(
        filter((x: SelectedHistoryEngagement) => x?.source === SelectedHistoryEngagementSource.HistoryTab)
      ).subscribe((e: SelectedHistoryEngagement) => {
        this._scrollToEngagement(e);
      });
    this.subscriptions.push(historyEngagementSelected);

    this.isLazyLoadEnabled$ = this.store
      .select(hasFeatureFlag(FeatureFlags.LazyLoadPriorEngagements));

    this.xaSolvesFeatureFlag$ = this.store
      .select(fromSettings.hasFeatureFlag(FeatureFlags.XaSolves));

    this.isTranslatedChat$ = this.store
      .select(fromChat.isTranslatedChat(this.chat.chatId));

    this.allEngagementsLoaded$ = this.store.select(fromChat.allPriorEngagementsLoaded).pipe(distinctUntilChanged());

    this.isLoadingPriorEngagements$ = this.store
      .select(fromChat.getSelectedChatUIIsLoadingPriorEngagements).pipe(distinctUntilChanged());

    this.bannerDetails$ = this.store.select(fromChat.getConvoWindowBannerDetails(this.chat.chatId));

    this.isChatTranslationConfigEnabled$ = this.store.select(fromChat.isChatTranslationConfigEnabled(this.chat.chatId));

    const isLoadingSub = this.store
    .select(fromChat.getSelectedChatIsLoading)
    .pipe(
      skip(1),
      filter(isLoading => !isLoading),
      distinctUntilChanged(),
      delay(200)
    )
    .subscribe(() => {
        this._scrollToBottom();
    });
    this.subscriptions.push(isLoadingSub);

    const asyncEngagementsSub = this.store
      .select(fromChat.getAsyncEngagements(this.chat))
      .pipe(
        skip(1),
        distinctUntilChanged()
      )
      .subscribe(() => {
        this._scrollToBottom();
      });
    this.subscriptions.push(asyncEngagementsSub);

    const priorEngagementSub = this.store
      .select(fromChat.getPriorEngagements(this.chat))
      .pipe(
        skip(1),
        distinctUntilChanged(),
        take(1),
        delay(50)
      )
      .subscribe(() => {
        this._scrollToBottom();
        this.startObservingIntersectElements();
      });
    this.subscriptions.push(priorEngagementSub);

    const contactReasonSub = this.store
      .select(fromChat.getContactReason(this.chat))
      .pipe(
        skip(1),
        distinctUntilChanged(),
        delay(50)
      )
      .subscribe(() => {
        this._scrollToBottom();
      });
    this.subscriptions.push(contactReasonSub);
    const scrollIntersect = this.intersect$.subscribe(({entry}) => {
      const eventArgs: SelectedHistoryEngagement = new SelectedHistoryEngagement();
      eventArgs.chatId = this.chat.chatId;
      eventArgs.isCurrentChat = !entry;
      eventArgs.source = SelectedHistoryEngagementSource.ChatWindowScrolling;
      eventArgs.priorEngagementId = entry ? entry.target.id : null;

      if (this._dispatchScrollIntersectionEvents) {
        this.store.dispatch(ChatActions.History_EngagementSelected(eventArgs));
      }
    });

    this.subscriptions.push(scrollIntersect);
    this._initScrollHighlighting();
  }

  public ngAfterViewInit(): void {
    const scroll$ = fromEvent(
      this.chatWindowElem.nativeElement,
      'scroll'
    )
      .pipe(
        takeUntil(this.destroyed$),
      );

    const showJumpSub = new ReplaySubject<boolean>();
    this.showJumpToLatestButton$ = showJumpSub
      .pipe(
        startWith(false),
        distinctUntilChanged()
      );

    const scrollSub = scroll$
      .pipe(debounceTime(50))
      .subscribe(($event: Event) => {
        const show = this._shouldShowJumpToButton($event);
        if (!show){
          this.intersect$.next({entry: null, observer: null});
        }
        showJumpSub.next(show);
      });
    this.subscriptions.push(scrollSub);

    const msgChangeSub = this.chatMessages$
      .pipe(
        filter((messages) => Boolean(messages?.length)),
        delay(50))
      .subscribe(async () => {
        await this._postMessageRender();
        const show = this._shouldShowJumpToButton();
        showJumpSub.next(show);
      });
    this.subscriptions.push(msgChangeSub);

    const latestXaSuggestion = this.store
      .select(fromChat.getLatestSuggestions)
      .pipe(
        skip(1),
        map((suggestions: Suggestion[]) => {
          // we map for distinctUntilChanged, otherwise this will fire on a 'new' empty array
          return suggestions[0]?.queryId;
        }),
        distinctUntilChanged(),
        delay(50),
        takeUntil(this.destroyed$)
      )
      .subscribe(async () => {
        await this._postMessageRender(true);
        const show = this._shouldShowJumpToButton(null, true);
        showJumpSub.next(show);
      });
    this.subscriptions.push(latestXaSuggestion);

    /*
      Fixes use case where page loads with chat populated
      and the scrollbar is all the way up
      */
    this._scrollToBottom();
  }

  isSystemSenderType(sender: SenderType) {
    return sender === SenderType.System;
  }

  public translateNotificationMessageClicked(){
    this.store.dispatch(UiActions.translateNotificationMessageClicked());
  }

  public isAsyncSummaryLoading(unresolvedContactReason: string, summaryData: ChatSummaryData): boolean{
    if (unresolvedContactReason) { return false; }
    return Boolean(summaryData?.isLoading);
  }

  public getSummaryText(unresolvedContactReason: string, summaryPayload: string, contactReason: string): string{
    if (unresolvedContactReason){
      return unresolvedContactReason;
    }
    if (summaryPayload){
      return summaryPayload;
    }
    return contactReason ?? '';
  }

  public getLastAsyncTimeAgo(chatStartTime: number, lastAsyncMessageTimestamp: number): string{
    const seconds = chatStartTime - lastAsyncMessageTimestamp;
    return TimestampHelper.getRoundedTimeAgo(seconds * 1000);
  }

  public getAsyncSummaryTitle(senderId: string): string{
    const agent = this.isNewAgent(senderId) ? 'another agent' : 'you';
    return `last spoke with ${agent}`;
  }

  private isNewAgent(senderId: string): boolean{
    return senderId !== this.identityService.username;
  }

  public positiveSummaryRating() {
    this.store.dispatch(ChatActions.rateAsyncEngagementsSummary({chatId: this.chat.chatId, isPositiveRating: true }));
  }

  public negativeSummaryRating() {
    this.store.dispatch(ChatActions.rateAsyncEngagementsSummary({chatId: this.chat.chatId, isPositiveRating: false }));
  }

  public jumpToLatest(): void {
    ObservableHelper.getValue<SelectedHistoryEngagement>(this._selectedHistoryEngagement$)
      .then((currEng: SelectedHistoryEngagement) => {
          const logDimensions = {
            jumpFromEngagementId: currEng?.priorEngagementId ?? currEng?.chatId ?? this.chat.chatId
          };
          LogHelper.logChatEvent(this.loggingFactory, ChatOperations.JumpToLatest, this.chat, logDimensions);
      });

    this._scrollToBottom();
  }

  onJumpToMessage(messageId: string) {
    this._scrollToMessage(messageId);
  }

  public trackBySelectedChatCurrentMessage(_index: number, message: ChatInteraction): string {
    return message.timestamp.toString() + message.messageId;
  }

  public trackByTranscriptId(_index: number, transcript: ChatTranscript): string {
    return transcript.id;
  }

  public trackByEngagementId(_index: number, transcript: AsyncEngagementTranscript): string{
    return transcript.engagementId;
  }

  public onBannerDismiss(): void {
    this.store.dispatch(ChatActions.UpdateSecureDataCollectionState({chatId: this.chat.chatId, requestState: DataCollectionState.Inactive, timestamp: this.timeService.unix()}));
  }

  onTranslationToggle(event) {
    const isEnabled =  event.target.checked;
    this.store.dispatch(ChatActions.TranslationToggled({chatId: this.chat.chatId, isEnabled}));
  }

  public asyncEngagementClicked(engagement: AsyncEngagementTranscript, closeDropdown: boolean = true) {
    const args: SelectedHistoryEngagement = new SelectedHistoryEngagement();
    args.chatId = this.chat.chatId;
    args.priorEngagementId = engagement.engagementId;
    args.isCurrentChat = false;
    args.isAsync = true;

    this.store.dispatch(ChatActions.History_EngagementSelected(args));

    this.store.dispatch(ChatActions.AsyncEngagementSelected(args));

    this._scrollToEngagement(args);

    if (closeDropdown) {
      this.asyncJumpDropDown.closeDropdown();
    }
  }

  public _scrollToBottom(): Promise<void> {
    const scrollProm = this._scroll(() => {
      this.chatWindowElem.nativeElement.scrollTop = this.chatWindowElem.nativeElement.scrollHeight;
    });

    const eventArgs: SelectedHistoryEngagement = new SelectedHistoryEngagement();
    eventArgs.chatId = this.chat.chatId;
    this.store.dispatch(ChatActions.History_EngagementSelected(eventArgs));

    return scrollProm;
  }

  public loadNextEngagements(): void {
    const fetchTimestamp = this.timeService.unix();
    this.store.dispatch(ChatActions.GetPriorEngagements({ chat: this.chat, loadNextPage: true, fetchTimestamp: fetchTimestamp }));
  }

  private _scrollToEngagement(selectedHistoryEng: SelectedHistoryEngagement): void {
    if (selectedHistoryEng.isCurrentChat) {
      this._scroll(() => {
        this._scrollIntoView(this.currentChatElem.nativeElement);
      });
    }
    else {
      this._scrollToPriorEngagement(selectedHistoryEng);
    }
  }

  private _scrollToMessage(messageId: string) {
    const c = this.messagesElem?.find((x: ElementRef) => x.nativeElement.getAttribute('id') === messageId);
    if (c) {
      this._scroll(() => {
        this._scrollIntoView(c.nativeElement);
      });
    }
  }

  private _scrollToPriorEngagement(selectedHistoryEng: SelectedHistoryEngagement) {
    // anything from the history tab would be from the priorEngagementComponents
    const target: QueryList<ElementRef> = selectedHistoryEng.source === SelectedHistoryEngagementSource.HistoryTab || !selectedHistoryEng.isAsync
      ? this.priorEngagementComponents
      : this.asyncEngagementComponents;

    const c = target?.find((x: ElementRef) => x.nativeElement.getAttribute('id') === selectedHistoryEng.priorEngagementId);
    if (c) {
      this._scroll(() => {
        this._scrollIntoView(c.nativeElement);
      });
    }
  }

  private _scrollIntoView(nativeElement) {
    const scrollPosY = nativeElement.offsetTop;
    this.chatWindowElem.nativeElement.scrollTo(0, scrollPosY);
  }

  private _initScrollHighlighting() {
    const options = {
      rootMargin: '0px',
      threshold: 1
    };

    const isIntersecting = ( entry: IntersectionObserverEntry) => entry.isIntersecting || entry.intersectionRatio > 0;

    this.observer =  new IntersectionObserver((entries, observer) => {
      const intersectedEntries = entries ? entries.filter( x => isIntersecting(x)) : [];
      if ( intersectedEntries.length > 0 ) {
        intersectedEntries.forEach(entry => {
            this.intersect$.next({entry, observer});
          });
        }
    }, options);
  }

 private _calculateScrollDistanceFromBottom(value: Event | HTMLElement): number {
    let chatWin: HTMLElement;

    if (value instanceof Event) {
      chatWin = (<Event>value).target as HTMLElement;
    }
    else {
      chatWin = <HTMLElement>value;
    }

    return chatWin.scrollHeight - chatWin.offsetHeight - chatWin.scrollTop;
  }

  private _postMessageRender(isSuggestion: boolean = false): Promise<void> {
    const scrollFuzz = isSuggestion ? this.xaSuggestionScrollFuzz : this.scrollFuzz;
    if (this.chatWindowElem?.nativeElement && this.messagesElem?.last
      && this._calculateScrollDistanceFromBottom(this.chatWindowElem.nativeElement) <= this.messagesElem.last.nativeElement.offsetHeight + scrollFuzz) {
      return this._scrollToBottom();
    }
    return Promise.resolve();
  }

  private _shouldShowJumpToButton($event?: Event, isSuggestion: boolean = false): boolean {
    const scrollFuzz = isSuggestion ? this.xaSuggestionScrollFuzz : this.scrollFuzz;
    return this._calculateScrollDistanceFromBottom($event ?? this.chatWindowElem.nativeElement) > scrollFuzz;
  }

  private _scroll(scroll: () => void): Promise<void> {
    this._dispatchScrollIntersectionEvents = false;

    scroll();

    return new Promise<void>((resolve) => {
      fromEvent(this.chatWindowElem.nativeElement, 'scroll')
        .pipe(
          debounceTime(100),
          take(1),
          takeUntil(this.destroyed$)
        )
        .subscribe(() => {
          this._dispatchScrollIntersectionEvents = true;
          resolve();
        });
    });
  }
  private startObservingIntersectElements() {
    this.priorEngagementComponents.forEach(comp => {
      this.observer.observe(comp.nativeElement);
    });
  }
}
