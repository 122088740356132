// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { tap, filter, delayWhen, switchMap } from 'rxjs/operators';
import { HubSettings, HubService, HubConnectionState, ReconnectSettings, AccessToken, InfiniteRetryPolicy, LoggingFactoryService, NiagaraLogAnalyticsApiService, EnvironmentService, QueueService, AuthService, DayTimeService } from '@cxt-cee-chat/merc-ng-core';
import { AgentActions, AgentAuthActions, AppActions, CallActions, ChatActions, ChatUiActions, ConnectAccountActions, HubsActions, UiActions, VoiceUiActions } from './actions';
import { ActionCreator, Store } from '@ngrx/store';
import { Chat } from './models/chat';
import { ChatMessage } from './models/chatMessage';
import { SenderType, ChannelType, SystemMessageType, ChatMessageType, SystemInfoMessageType, ChatState, LoggedOutMethod, AvailabilityType, TypingStatus, InboundEventOperationType, TranslatedMessageType } from './models/enums';
import { ChatTranscript } from './models/chatTranscript';
import { BusinessUnitTransferOption } from './models/businessUnitTransferOption';
import { UpdateTransferOptions } from './models/updateTransferOptions';
import { Channel } from './models/channel';
import { BrowserDeviceInformation } from './models/browserDeviceInformation';
import { SmsDeviceInformation } from './models/smsDeviceInformation';
import { ChannelInformation } from './models/channelInformation';
import { SetPriorEngagements } from './models/setPriorEngagements';
import { SystemChatMessage } from './models/systemChatMessage';
import { HttpTransportType, IHubProtocol, IRetryPolicy } from '@microsoft/signalr';
import { ChatAttachment } from './models/chatAttachment';
import { ChatImageMessage } from './models/chatImageMessage';
import { UpdateAccountInformation } from './models/updateAccountInformation';
import { UpdateUui } from './models/updateUui';
import { TypingStatusChanged } from './models/typingStatusChanged';
import { UpdateAsyncEngagements } from './models/updateAsyncEngagements';
import { IHubChatResponse } from './models/hub-response';
import { SystemMessages } from '../constants/systemMessages.constants';
import { LogHelper } from '../utils/logHelper';
import { AgentOperations, ChatOperations } from '../constants/event-logs.constants';
import { UnauthorizedVerifySession } from './models/unauthorizedVerifySession';
import { NuanceCredentials } from './models/credentials';
import { GetXaSuggestionsResponse } from './models/responses/getXaSuggestionsResponse';
import { AddXaSuggestions } from './models/addXaSuggestions';
import { UnlinkAccountResponse } from './models/responses/unlink-account-response';
import { ChatRequestApiConstants } from '../constants/chat-request-api.constants';
import { Inject, Injectable } from '@angular/core';
import { ChatHelper } from '../utils/chatHelper';
import { NewChatResponse, TransferInResponse } from './models/responses/new-chat-response';
import { Color } from './models/color';
import { CustomerDatapassResponse } from './models/customerDatapassResponse';
import { GetEngagementsResponse, GetEngagementByIdResponse } from './models/responses/getEngagementsResponse';
import { NewChatMessage } from './models/responses/new-chat-message';
import { DispositionResponse } from './models/dispositionResponse';
import { fromAgent, fromAgentAuth, fromAgentAvailability, fromChat, fromHubs, fromSettings } from './selectors';
import { TopicSelection } from './models/responses/topic-selection';
import { AvailabilityChange } from './models';
import { GetActiveEngagementsResponse } from './models/responses/get-active-engagements-response';
import { AgentLostConnectionResponse } from './models/responses/agent-lost-connection-response';
import { AppState } from './state';
import { ChatRequestApiService } from '../services/chat-request-api.service';
import { ColorEnumeratorService } from '../services/color-enumerator.service';
import { ChatPersisterService } from './chat/chat-persister.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { TypedAction } from '@ngrx/store/src/models';
import { HubConnectionUpdate } from './models/hub-connection-data';
import { TransferChatResponse } from './models/responses/transfer-chat-response';
import { CustomerStateChangeResponse } from './models/responses/customerStateChangeResponse';
import { CustomerNavigatedPageResponse } from './models/responses/customer-navigated-page-response';
import { PageMarkerResponse } from './models/responses/page-marker-response';
import { TypingStatusChangedResponse } from './models/responses/typing-status-changed-response';
import { GetUpdatesResponse } from './models/responses/get-updates-response';
import { RecommendationsResponse } from './models/responses/recommendationsResponse';
import { interval, timer } from 'rxjs';
import { InboundChatEventData } from './models/responses/inbound-chat-event-data';
import { InboundChatEventResponse } from './models/responses/inboundChatEventResponse';
import { StopGetUpdatesSources } from '../constants/constants';
import { SmartResponses } from './smart-responses/smart-responses.model';
import { Placeholder } from './placeholders/placeholder.model';
import { XaTranscriptResponse } from './models/responses/xa-transcript-response';
import { CustomerDisconnectLog } from './models/logDimensions/customer-disconnect-log';
import { ItgMetadata } from './models/itgMetadata';
import { PlaceholderHelper } from '../utils/placeholder-helper';
import { UserIdentityService } from '../services/user-identity.service';
import { GetActiveChatTranscriptResponse } from './models/responses/get-active-chat-transcript-response';
import { VoiceHubService } from '../services/voice-hub.service';
import { HomeHeaderErrorState, RecommendedActionResponseType } from './models/voice/enums';
import { EchoResponse } from './models/voice/responses/echo-response';
import { EchoService } from '../services/echo-service';
import { TranscriptMessageResponse } from './models/voice/responses/transcript-message-response';
import { CallSummaryResponse } from './models/voice/responses/call-summary-response';
import { HubNames } from '../constants/hub-names';
import { VoiceHubConstants } from '../constants/voice-hub.constants';
import { TranscriptRecommendedActionResponse } from './models/voice/responses/transcript-recommended-action-response';
import { VoiceHelper } from '../utils/voice-helper';
import { ItgDetails } from './models/itg-details';
import { AgentStatePersisterService } from '../services/agent-state-persister.service';
import { GetChatSummaryDataResponse } from './models/responses/get-chat-summary-data-response';
import { ChatSummaryData } from './models/chatSummaryData';
import { environment } from 'src/environments/environment';
import { ComcastSsoAuthService } from '../services/comcast-auth-sso-service';
import { SaveUnifiedNotesResponse } from './models/responses/saveUnifiedNotesResponse';
import { AgentGroupIdListsByLanguage } from './models/languageTranslationSettings';

@Injectable()
// tslint:disable-next-line: class-name
export class MercEffects_Hubs {
	constructor(
		protected ngEntityStore: Store<AppState>,
		protected actions: Actions,
		private echoService: EchoService,
		protected chatRequestApiService: ChatRequestApiService,
		protected voiceHubService: VoiceHubService,
		protected colorService: ColorEnumeratorService,
		protected chatPersisterService: ChatPersisterService,
		protected loggingFactory: LoggingFactoryService,
		protected analyticsApiService: NiagaraLogAnalyticsApiService,
		protected environmentService: EnvironmentService,
		protected queueService: QueueService,
		@Inject(AuthService) protected authService: AuthService,
		protected config: AppConfigService,
		protected timeService: DayTimeService,
		private userIdentityService: UserIdentityService,
		private agentStatePersisterService: AgentStatePersisterService,	
		private readonly comcastSsoService: ComcastSsoAuthService
	) { }

	// 0, 2, 2, 3, 3, 5 second delays will attempt to reconnect at 0, 2, 4, 7, 10, 15, and every 5 seconds after that...
	private readonly chatHubRetryPolicy: IRetryPolicy = new InfiniteRetryPolicy(0, 2000, 2000, 3000, 3000, 5000);
	// 0, 2, 2, 3, 3, 5 second delays will attempt to reconnect at 0, 2, 4, 7, 10, 15, and every 5 seconds after that...
	private readonly voiceHubRetryPolicy: IRetryPolicy = new InfiniteRetryPolicy(0, 2000, 2000, 3000, 3000, 5000);
	// niagara logs are not as critical and will queue, so we can afford to wait longer to reconnect
	private readonly niagaraHubRetryPolicy: IRetryPolicy = new InfiniteRetryPolicy(500, 3000, 5000);

	initHubs$ = createEffect(() =>
		this.actions.pipe(
			ofType(HubsActions.initializeHubs),
			concatLatestFrom(_ => [
				this.ngEntityStore.select(fromSettings.getFeatureFlags),
				this.ngEntityStore.select(fromHubs.getHubsInitialized)
			]),
			filter(([, , hubsInitialized]) => !hubsInitialized),
			tap(([, featureFlags]) => {
				// i loathe this
				let currentChats: Chat[];
				this.ngEntityStore
					.select(fromChat.getChats)
					.subscribe((chats: Chat[]) => {
						currentChats = chats;
					});

				let completedChatCount: number;
				this.ngEntityStore
					.select(fromAgent.getCompletedChatCount)
					.subscribe((count: number) => {
						completedChatCount = count;
					});

				let agentAvailability: AvailabilityChange;
				this.ngEntityStore
					.select(fromAgentAvailability.getAvailability)
					.subscribe((availability: AvailabilityChange) => {
						agentAvailability = availability;
					});

				let settingAvailability: boolean;
				this.ngEntityStore
					.select(fromAgentAvailability.getSettingAvailability)
					.subscribe((isSettingAvailability: boolean) => {
						settingAvailability = isSettingAvailability;
					});

        let itgList: ItgDetails[];
        this.ngEntityStore
          .select(fromSettings.getItgList)
          .subscribe((settingsItgList: ItgDetails[]) => {
            itgList = settingsItgList;
          });

        let maxConcurrency: number;
        this.ngEntityStore
          .select(fromAgent.getMaxChats)
          .subscribe((maxChats: number) => {
            maxConcurrency = maxChats;
          });

			let agentGroupIdLanguageLists: AgentGroupIdListsByLanguage;
			this.ngEntityStore
				.select(fromSettings.getAgentGroupIdLanguageLists)
				.subscribe((groupIdLanguageLists: AgentGroupIdListsByLanguage) => {
					agentGroupIdLanguageLists = groupIdLanguageLists;
			});
      
      let translateMessagesDefault: boolean;
      this.ngEntityStore
      .select(fromSettings.getTranslateMessagesDefault)
      .subscribe((translateDefault: boolean) => {
        translateMessagesDefault = translateDefault;
      });

				// end loathing

				// #region Analytics hub
				if (this.environmentService.logging.niagaraLog.enabled) {
					this.initHub(
						this.analyticsApiService,
						HubNames.NiagaraLogHub,
						this.config.niagaraLog.endpoint,
						this.onNiagaraLogHubConnectionChange,
						this.niagaraHubRetryPolicy,
						null);
				}
				//end analytics hub

				// #region Voice hub
				// TODO: refactor this file so it is not 1k+ lines
				// TODO: voice feature flag or something other condition?
				if (this.config.voiceHub?.endpoint) {
					this.initHub(
						this.voiceHubService,
						HubNames.VoiceHub,
						this.config.voiceHub.endpoint,
						this.onVoiceHubConnectionChange,
						this.voiceHubRetryPolicy,
						null);

						this.voiceHubService.addListener(
							VoiceHubConstants.Callbacks.Echo,
							(response: EchoResponse) => {
								const { sessionId, startTime } = response;
								const elapsedTime = Date.now() - Date.parse(startTime);
								LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.EchoCallback, { elapsedTime, sessionId, startTime }, true);
							}
						);

						this.voiceHubService.addListener(
							VoiceHubConstants.Callbacks.TranscriptMessage,
							(response: TranscriptMessageResponse) => {
								this.ngEntityStore.dispatch(CallActions.transcriptMessageReceived({ transcriptMessageResponse: response }));
							}
						);

						this.voiceHubService.addListener(
							VoiceHubConstants.Callbacks.CallSummary,
							(response: CallSummaryResponse) => {
								this.ngEntityStore.dispatch(CallActions.updateCallSummary({...response, error: !response.success}));
							}
						);
						this.voiceHubService.addListener(
							VoiceHubConstants.Callbacks.RecommendedAction,
							(response: TranscriptRecommendedActionResponse) => {
								if (response.recommendedActionType === RecommendedActionResponseType.Unknown) {
									// do nothing
								}
								else {
									let recommendedAction;
									switch (response.recommendedActionType) {
										case RecommendedActionResponseType.Itg: {
											recommendedAction = VoiceHelper.createItgRecommendedAction(response, itgList);
										}
									}

									if (recommendedAction) {
										this.ngEntityStore.dispatch(CallActions.transcriptRecommendedActionReceived({ transcriptRecommendedAction: recommendedAction, callId: response.callId }));
									}
								}
							}
						);
				}
				//end Voice hub

				// #region Chat Request
				this.initHub(
					this.chatRequestApiService,
					HubNames.FixAgentChatRequestHub,
					this.config.chatRequest.endpoint,
					this.onChatRequestHubConnectionChange,
					this.chatHubRetryPolicy,
					null);

				this.chatRequestApiService.addListener(
					ChatRequestApiConstants.Callbacks.NewChat,
					(response: NewChatResponse) => {
						const chatColor: Color = this.colorService.next();
						const chat = ChatHelper.deserializeNewChat(response, false, chatColor, this.timeService, this.userIdentityService.username, featureFlags);
						const placeholders = PlaceholderHelper.getNewChatPlaceholders(response.agentAlias, response.chatId);
						chat.defaultGroupTranslationLanguage = ChatHelper.getDefaultGroupTranslationLanguage(chat.agentGroupId, agentGroupIdLanguageLists);
						chat.isTranslationConfigEnabled = translateMessagesDefault;

						this._processAcceptance(
							chat,
							currentChats,
							agentAvailability,
							settingAvailability,
							null,
							null,
							placeholders
						);
					}
				);

				this.chatRequestApiService.addListener(
					ChatRequestApiConstants.Callbacks.RefuseChat,
					(response: IHubChatResponse) => {
						const chat = { chatId: response.chatId } as Chat;
						let logOperation = ChatOperations.RefuseEngagementSuccess;

						if (!response.success) {
							logOperation = ChatOperations.RefuseEngagementFailed;
						}

						LogHelper.logChatEvent(this.loggingFactory, logOperation, chat);
					}
				);

				this.chatRequestApiService.addListener(
					ChatRequestApiConstants.Callbacks.GetDispositions,
					(response: DispositionResponse) => {
						this.ngEntityStore.dispatch(ChatActions.DispositionsReceived(response));
					}
				);


				this.chatRequestApiService.addListener(
					ChatRequestApiConstants.Callbacks.NewTransferChat,
					(response: TransferInResponse) => {
						//make sure chat isnt already registered in data store
						const chatColor: Color = this.colorService.next();
						const chat: Chat = ChatHelper.deserializeNewChat(response, true, chatColor, this.timeService, this.userIdentityService.username, featureFlags);
						chat.uui = response.customerUui && response.customerUui.uui ? response.customerUui.uui : '';
            const placeholders = PlaceholderHelper.getNewChatPlaceholders(response.agentAlias, response.chatId, response?.smartResponsesData?.placeholders);
						chat.defaultGroupTranslationLanguage = ChatHelper.getDefaultGroupTranslationLanguage(chat.agentGroupId, agentGroupIdLanguageLists);
						chat.isTranslationConfigEnabled = translateMessagesDefault;

						this._processAcceptance(
							chat,
							currentChats,
							agentAvailability,
							settingAvailability,
							response?.xaRecommendation,
							response?.smartResponsesData?.smartResponses,
							placeholders,
							response?.itgData?.itgsInProgress
						);
					}
				);

				this.chatRequestApiService.addListener(
					ChatRequestApiConstants.Callbacks.NewChatMessage,
					(data: NewChatMessage) => {
						const chatId: string = data.chatId;

						const msgModel = new ChatMessage();
						msgModel.chatId = chatId;
						msgModel.message = data.message;
						msgModel.messageId = data.messageId;
						msgModel.timestamp = data.timestamp;
						msgModel.translationData = data.chatMessageTranslationData;
						msgModel.sender = SenderType.Requester;
						msgModel.type = ChatMessageType.Message;
						msgModel.status = ChatHelper.getCustomerMessageStatus(data.chatMessageTranslationData?.hasError);
						this.ngEntityStore.dispatch(ChatActions.NewMessage(msgModel));
					}
				);

				this.chatRequestApiService.addListener(
					ChatRequestApiConstants.Callbacks.TopicSelection,
					(data: TopicSelection) => {
						const chatId: string = data.chatId;

						const msgModel = new ChatMessage();
						msgModel.chatId = chatId;
						msgModel.topic = data.topic;
						msgModel.intent = data.intent;
						msgModel.messageId = data.messageId;
						msgModel.timestamp = data.timestamp;
						msgModel.sender = SenderType.Requester;
						msgModel.type = ChatMessageType.TopicSelection;
						this.ngEntityStore.dispatch(ChatActions.NewMessage(msgModel));
					}
				);

				this.chatRequestApiService.addListener(
					ChatRequestApiConstants.Callbacks.XaTranscript,
					(data: XaTranscriptResponse) => {
						if (data?.interactions && data?.participants) {
              const {chatId, participants, interactions, xaTranscriptEndDateTime} = data;
              const xaTranscriptEndTimestamp = ChatHelper.getUnixXaTranscriptEndTimestamp(this.timeService, xaTranscriptEndDateTime);
							const messages = ChatHelper.parseTranscriptInteractions(chatId, participants, interactions);
							this.ngEntityStore.dispatch(ChatActions.AddXaTranscript({chatId, messages, xaTranscriptEndTimestamp}));
						}
					}
				);

				this.chatRequestApiService.addListener(
					ChatRequestApiConstants.Callbacks.Recommendation,
					(recommendation: RecommendationsResponse) => {
						if (recommendation) {
							this.ngEntityStore.dispatch(ChatActions.UpdateRecommendation({recommendation}));
						}
					}
				);

				this.chatRequestApiService.addListener(
					ChatRequestApiConstants.Callbacks.NewChatAttachment,
					(data: any) => {
						const chatId: string = data.chatId;

						if (data.type === ChatMessageType.Image) {
							const msgModel = new ChatImageMessage();
							msgModel.chatId = chatId;
							msgModel.retrievalUrl = data.retrievalUrl;
							msgModel.timestamp = this.timeService.unix();
							msgModel.sender = SenderType.Requester;
							msgModel.type = data.type;
							msgModel.reported = false;
							this.ngEntityStore.dispatch(ChatActions.NewMessage(msgModel));
						}
						else {
							const msgModel = new ChatAttachment();
							msgModel.chatId = chatId;
							msgModel.retrievalUrl = data.retrievalUrl;
							msgModel.timestamp = this.timeService.unix();
							msgModel.sender = SenderType.Requester;
							msgModel.type = data.type;
							this.ngEntityStore.dispatch(ChatActions.NewMessage(msgModel));
						}
					}
				);

				this.chatRequestApiService.addListener(
					ChatRequestApiConstants.Callbacks.CustomerUui,
					(response: any) => {
						const updateUUI: UpdateUui = {
							uui: response.value.uui,
							guid: response.value.guid,
							xaTranscriptSessionId: response.value.xaTranscriptSessionId,
							previousEngagementId: response.value.previousEngagementId,
							chatId: response.chatId,
							firstName: response.value.firstName,
							lastName: response.value.lastName,
							contactMethod: response?.value?.contactMethod,
							language: ChatHelper.getLanguageFromString(response?.value?.language),
							customerPhoneNumber: response.value.customerPhoneNumber
						};
						this.ngEntityStore.dispatch(ChatActions.UpdateUui(updateUUI));
					}
				);

				this.chatRequestApiService.addListener(
					ChatRequestApiConstants.Callbacks.CustomerDatapass,
					(response: CustomerDatapassResponse) => {
						if (response.success) {
							const msgModel: SystemChatMessage = new SystemChatMessage();
							msgModel.chatId = response.chatId;
							msgModel.title = response.value.name;
							msgModel.message = response.value.data;
							msgModel.timestamp = this.timeService.unix();
							msgModel.sender = SenderType.System;
							msgModel.type = ChatMessageType.Message;
							msgModel.systemType = SystemMessageType.Datapass;
							msgModel.messageId = response.messageId;

							this.ngEntityStore.dispatch(ChatActions.NewMessage(msgModel));
						}
					}
				);

				this.chatRequestApiService.addListener(
					ChatRequestApiConstants.Callbacks.ChannelInformation,
					(response: any) => {
						const channelModel = new Channel();
						channelModel.type = response.value.channel;
						switch (channelModel.type) {
							case ChannelType.WebChat:
								const browserDevice = new BrowserDeviceInformation();
								browserDevice.name = response && response.value && response.value.device ? response.value.device.name : '';
								browserDevice.version = response && response.value && response.value.device ? response.value.device.version : '';
								browserDevice.operatingSystem = response && response.value && response.value.device ? response.value.device.operatingSystem : '';
								channelModel.device = browserDevice;
								break;
							case ChannelType.Sms:
								const smsDevice = new SmsDeviceInformation();
								smsDevice.phoneNumber = response && response.value && response.value.device ? response.value.device.phoneNumber : '';
								channelModel.device = smsDevice;
								break;
							default:
								break;
						}
						const channelInfoArgs: ChannelInformation = {
							chatId: response.chatId,
							channel: channelModel
						};
						this.ngEntityStore.dispatch(ChatActions.ChannelInformation(channelInfoArgs));
					}
				);

				this.chatRequestApiService.addListener(
					ChatRequestApiConstants.Callbacks.TypingStateChanged,
					(typingStatusResponse: TypingStatusChangedResponse) => {
						const customerTypingStatus: TypingStatusChanged = {
							chatId: typingStatusResponse.chatId,
							isCustomerTyping: typingStatusResponse.value === TypingStatus.StartsTyping
						};
						this.ngEntityStore.dispatch(ChatUiActions.customerTypingStatusChanged({ customerTypingStatus }));
					}
				);

				this.chatRequestApiService.addListener(
					ChatRequestApiConstants.Callbacks.ChatClosed,
					(cbResponse: any) => {
						this._chatClosed(cbResponse.chatId, currentChats, completedChatCount);
					}
				);

				this.chatRequestApiService.addListener(
					ChatRequestApiConstants.Callbacks.SaveUnifiedNotes,
					(data: SaveUnifiedNotesResponse) => {
						const chat = new Chat({chatId: data.chatId, conversationId: data.conversationId, isAsync: !!data.conversationId});
						if (data.success && data.noteId) {
							const logDimensions = {
								noteId: data.noteId,
							};
							LogHelper.logChatEvent(this.loggingFactory, ChatOperations.ChatSummarySaved, chat, logDimensions);
							this.ngEntityStore.dispatch(UiActions.BackgroundAutoSaveSummarySuccessful({ chatId: data.chatId, summary: data.summary, createDate: this.timeService.unix() }));
						}
						else {
							LogHelper.logChatEvent(this.loggingFactory, ChatOperations.SaveChatSummaryError, chat);
							this.ngEntityStore.dispatch(UiActions.BackgroundAutoSaveSummaryFailed({ chatId: data.chatId }));
						}
					}
				);

				this.chatRequestApiService.addListener(
					ChatRequestApiConstants.Callbacks.CloseConversation,
					(cbResponse: any) => {
						this._chatClosed(cbResponse.chatId, currentChats, completedChatCount);
					}
				);

				this.chatRequestApiService.addListener(
					ChatRequestApiConstants.Callbacks.CustomerClosedChat,
					(stateChangeResponse: CustomerStateChangeResponse) => {
						this.ngEntityStore.dispatch(ChatActions.CustomerExited({ stateChangeResponse } ));
					}
				);
				this.chatRequestApiService.addListener(
					ChatRequestApiConstants.Callbacks.UpdatesComplete,
					(response: GetUpdatesResponse) => {
						const { traceId } = response;
						this.ngEntityStore.dispatch(AppActions.UpdatesComplete({ traceId, timestamp: this.timeService.unix() }));
					}
				);

				this.chatRequestApiService.addListener(
					ChatRequestApiConstants.Callbacks.GetTransferOptions,
					(getTransferOptions: any) => {
						const options: BusinessUnitTransferOption[] = [];
						if (getTransferOptions.businessUnits && getTransferOptions.businessUnits.length > 0) {
							getTransferOptions.businessUnits.forEach(bu => {
								options.push(<BusinessUnitTransferOption>bu);
							});
						}
						const response: UpdateTransferOptions = {
							chatId: getTransferOptions.chatId,
							businessUnits: options
						};
						this.ngEntityStore.dispatch(ChatActions.UpdateTransferOptions(response));
					}
				);

				this.chatRequestApiService.addListener(
					ChatRequestApiConstants.Callbacks.TransferChat,
					(transferResponse: TransferChatResponse) => {
						const chat = currentChats.find(c => c.chatId === transferResponse.chatId);
						if (!chat) { return; }
						const logDimensions = {
							businessUnitId: chat.targetQueueBusinessUnitId,
							agentGroupId: chat.targetQueueId,
							notes: chat.agentTransferNotes
						};

						if (transferResponse.success) {
              LogHelper.logChatEvent(this.loggingFactory, ChatOperations.transferChat, chat, {
                ...logDimensions,
                maxConcurrency,
                ...(ChatHelper.getElapsedUnavailableSeconds(agentAvailability, this.agentStatePersisterService.unavailableTimestamp))
              });
							this.ngEntityStore.dispatch(ChatActions.Transferred(chat));
						} else {
							this.ngEntityStore.dispatch(ChatActions.TransferFailed(chat));
              const transferFailedLog = {
                ...logDimensions,
                errorMessage: transferResponse.errorMessage
              };
							LogHelper.logChatEvent(this.loggingFactory, ChatOperations.transferFailed, chat, transferFailedLog);
						}
					}
				);

				this.chatRequestApiService.addListener(
					ChatRequestApiConstants.Callbacks.BounceChat,
					(bounceResponse: any) => {
						const chat = currentChats.find(c => c.chatId === bounceResponse.chatId);
						if (!chat) { return; }
						const logDimensions = {
							businessUnitId: chat.businessUnitId,
							agentGroupId: chat.agentGroupId,
						};
						if (bounceResponse.success) {
							this.ngEntityStore.dispatch(ChatActions.Bounced(chat));
							LogHelper.logChatEvent(this.loggingFactory, ChatOperations.bounceChat, chat, logDimensions);
						} else {
							LogHelper.logChatEvent(this.loggingFactory, ChatOperations.bounceFailed, chat, logDimensions);
						}
					}
				);

				this.chatRequestApiService.addListener(
					ChatRequestApiConstants.Callbacks.CustomerConnectionLost,
					(connectionLostResponse: CustomerStateChangeResponse) => {
						const {chatId, message, messageId, timestamp } = connectionLostResponse;
						const msgModel = this.createSystemMessage(chatId, SystemMessages.CustomerLeft, messageId, SystemMessageType.CustomerDisconnect, timestamp);
						this.ngEntityStore.dispatch(ChatActions.NewMessage(msgModel));
						this.ngEntityStore.dispatch(ChatActions.CustomerDisconnected(chatId));

            const logDimensions: CustomerDisconnectLog = { message };
						LogHelper.logChatEvent(this.loggingFactory, ChatOperations.customerDisconnect, new Chat({ chatId }), logDimensions);
					}
				);

				this.chatRequestApiService.addListener(
					ChatRequestApiConstants.Callbacks.CustomerReconnected,
					(reconnectedResponse: CustomerStateChangeResponse) => {
						const {chatId, message, messageId, timestamp } = reconnectedResponse;
						const msgModel = this.createSystemMessage(chatId, message, messageId, SystemMessageType.CustomerReconnect, timestamp);
						this.ngEntityStore.dispatch(ChatActions.NewMessage(msgModel));
						this.ngEntityStore.dispatch(ChatActions.CustomerReconnected(reconnectedResponse.chatId));
					}
				);

				this.chatRequestApiService.addListener(
					ChatRequestApiConstants.Callbacks.InformationMessage,
					(response: CustomerStateChangeResponse) => {
						const {chatId, message, messageId, timestamp } = response;
						const msgModel = this.createSystemMessage(chatId, message, messageId, SystemMessageType.Information, timestamp);
						this.ngEntityStore.dispatch(ChatActions.NewMessage(msgModel));
					}
				);

				this.chatRequestApiService.addListener(
					ChatRequestApiConstants.Callbacks.CustomerMinimizedChatWindow,
					(minimizedChatWindowResponse: CustomerStateChangeResponse) => {
						const {chatId, message, messageId, timestamp } = minimizedChatWindowResponse;
						const msgModel = this.createSystemMessage(chatId, message, messageId, SystemMessageType.Navigation, timestamp);
						msgModel.systemInfoMessageType = SystemInfoMessageType.MinimizedWindow;
						this.ngEntityStore.dispatch(ChatActions.NewMessage(msgModel));
					}
				);

				this.chatRequestApiService.addListener(
					ChatRequestApiConstants.Callbacks.CustomerRestoredChatWindow,
					(restoredChatWindowResponse: CustomerStateChangeResponse) => {
						const {chatId, message, messageId, timestamp } = restoredChatWindowResponse;
						const msgModel = this.createSystemMessage(chatId, message, messageId, SystemMessageType.Navigation, timestamp);
						msgModel.systemInfoMessageType = SystemInfoMessageType.MaximizedWindow;
						this.ngEntityStore.dispatch(ChatActions.NewMessage(msgModel));
					}
				);

				this.chatRequestApiService.addListener(
					ChatRequestApiConstants.Callbacks.CustomerNavigatedPage,
					(response: CustomerNavigatedPageResponse) => {
						const {chatId, url, messageId } = response;
						const msgModel = this.createSystemMessage(chatId, url, messageId, SystemMessageType.Navigation, this.timeService.unix());
						msgModel.systemInfoMessageType = SystemInfoMessageType.PageNavigated;
						this.ngEntityStore.dispatch(ChatActions.NewMessage(msgModel));
					}
				);

				this.chatRequestApiService.addListener(
					ChatRequestApiConstants.Callbacks.PageMarker,
					(response: PageMarkerResponse) => {
						const {chatId, messageId } = response;
						const msgModel = this.createSystemMessage(chatId, response.marker, messageId, SystemMessageType.Datapass, this.timeService.unix());
						msgModel.title = SystemMessages.PageMarker;
						msgModel.systemInfoMessageType = SystemInfoMessageType.PageMarker;
						this.ngEntityStore.dispatch(ChatActions.NewMessage(msgModel));
					}
				);

				this.chatRequestApiService.addListener(
					ChatRequestApiConstants.Callbacks.UpdateAccountNumber,
					(updateAccountNumberResponse: any) => {
						if (updateAccountNumberResponse.success) {
							const updateAccountArgs: UpdateAccountInformation = {
								chatId: updateAccountNumberResponse.chatId,
								displayName: updateAccountNumberResponse.displayName,
								accountNumber: updateAccountNumberResponse.accountNumber,
								authenticated: updateAccountNumberResponse.authenticated,
								isAccountConnected: updateAccountNumberResponse.manualConnect,
								updateDisplayName: true
							};
							this.ngEntityStore.dispatch(ChatActions.UpdateAccountInformation(updateAccountArgs));
						}
						// put in an event to show an error state
					}
				);

				this.chatRequestApiService.addListener(
					ChatRequestApiConstants.Callbacks.UnlinkAccount,
					(unlinkAccountResponse: UnlinkAccountResponse) => {
						if (unlinkAccountResponse.success) {
							// unlinked account so remove account values
							const updateAccountArgs: UpdateAccountInformation = {
								chatId: unlinkAccountResponse.chatId,
								displayName: '',
								accountNumber: '',
								authenticated: false,
								isAccountConnected: false,
								updateDisplayName: true
							};

							const engagementArgs: SetPriorEngagements = {
								chatId: unlinkAccountResponse.chatId,
								totalEngagements: 0,
								engagements: [],
								clearEngagements: true
							};

							this.ngEntityStore.dispatch(ChatActions.SetPriorEngagements(engagementArgs));
							this.ngEntityStore.dispatch(ChatActions.UpdateAccountInformation(updateAccountArgs));
						}
						this.ngEntityStore.dispatch(ConnectAccountActions.UpdateUnlinkAccountResponse(unlinkAccountResponse));
					}
				);

				this.chatRequestApiService.addListener(
					ChatRequestApiConstants.Callbacks.GetAsyncEngagements,
					(getAsyncEngagementsResponse: GetEngagementsResponse) => {
						if (getAsyncEngagementsResponse.value) {
							let engagements = [];
							if (getAsyncEngagementsResponse.value.length > 0) {
								engagements = ChatHelper.parseAsyncEngagements(getAsyncEngagementsResponse, currentChats, this.timeService);
							}

							const previousAsyncEngagement = ChatHelper.getPreviousAsyncEngagement(engagements);
							const unresolvedContactReason = previousAsyncEngagement?.unresolvedContactReason ?? '';

							const updateAsyncEngagements: UpdateAsyncEngagements = {
								chatId: getAsyncEngagementsResponse.chatId,
								engagements: engagements,
								unresolvedContactReason
							};
							this.ngEntityStore.dispatch(ChatActions.UpdateAsyncEngagements({updateAsyncEngagements}));
						}
					}
				);

				this.chatRequestApiService.addListener(
					ChatRequestApiConstants.Callbacks.GetActiveEngagements,
					(response: GetActiveEngagementsResponse) => {
						const engagements = response?.engagements ?? [];
						this.ngEntityStore.dispatch(ChatActions.getActiveEngagementsResponse({engagements}));
            }
        );

				this.chatRequestApiService.addListener(
					ChatRequestApiConstants.Callbacks.AgentSessionLost,
					(response: any) => {
						// place holder for 401 response from get updates, blocking on the backend and immediately sends a 200
						// we dont have the model that was used to create the call
						const requestModel = new NuanceCredentials();
						requestModel.agentId = response.agentId;
						const operationData: UnauthorizedVerifySession = {
							operationName: 'GetUpdates',
							data: requestModel
						};
						this.ngEntityStore.dispatch(AgentAuthActions.unauthorizedVerifySession(operationData));
					}
				);

				this.chatRequestApiService.addListener(
					ChatRequestApiConstants.Callbacks.AgentLostConnection,
					(response: AgentLostConnectionResponse) => {
						// event that the server tells us to log the agent out
						// message will give the reason
						LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.SessionDisconnected,
							{ message: response.message });
						this.ngEntityStore.dispatch(AppActions.StopGetUpdates({source: StopGetUpdatesSources.platformForceDisconnect}));
						this.ngEntityStore.dispatch(AgentAuthActions.loggedOut(LoggedOutMethod.PlatformForce));
					}
				);

				this.chatRequestApiService.addListener(
					ChatRequestApiConstants.Callbacks.GetPriorEngagements,
					(response: any) => {
						const engs: ChatTranscript[] = [];
						if (response.value && response.value.length > 0) {
							const chat = currentChats.find(c => c.chatId === response.chatId);
							const customer = ChatHelper.getCustomerUser(chat);
							response.value.forEach(eng => {
								// in prior engagements, we get the transcripts in an array
								// when it is async, we have a collection of engagements that are part of that conversation
								// the identifier for that collection would be the conversationId
								// when it is not async, it does not have a conversationId and its identifier is the engagementId
								eng.id = eng.isAsync ? eng.conversationId : eng.engagementId;
								const prior = Object.assign(new ChatTranscript(), eng);
								eng.transcripts.forEach(transcript => {
									transcript.interactions = ChatHelper.parseTranscriptInteractions(transcript.engagementId, transcript.participants, transcript.interactions, true);
									transcript.participants.forEach(p => {
										if (p.type === SenderType.Requester && customer) {
											p.displayName = customer.name;
										}
									});
                  transcript.xaTranscriptEndTimestamp = ChatHelper.getUnixXaTranscriptEndTimestamp(this.timeService, transcript.xaTranscriptEndDateTime);
								});
								prior.parentId = response.chatId;
								prior.expanded = false;
								engs.push(prior);
							});
						}

						const data: SetPriorEngagements = {
							chatId: response.chatId,
							totalEngagements: response.totalEngagements,
							engagements: engs
						};
						this.ngEntityStore.dispatch(ChatActions.SetPriorEngagements(data));
					}
				);

				this.chatRequestApiService.addListener(
					ChatRequestApiConstants.Callbacks.GetCustomerInformation,
					(response: any) => {
						if (response && response.value && response.value.accountNumber !== null) {
							const updateAccountArgs: UpdateAccountInformation = {
								chatId: response.chatId,
								updateDisplayName: response.value.authenticated && response.value.displayName,
								displayName: response.value.authenticated ? response.value.displayName : '',
								accountNumber: response.value.accountNumber,
								authenticated: response.value.authenticated
							};
							this.ngEntityStore.dispatch(ChatActions.UpdateAccountInformation(updateAccountArgs));
						}
						this.ngEntityStore.dispatch(ChatActions.ChatLoadComplete(response.chatId));
						this.ngEntityStore.dispatch(ChatActions.CheckCustomerHeartbeat({chatId: response.chatId, timeFromLastHeartbeat: response?.value?.timeFromLastHeartBeat}));
					}
				);

				this.chatRequestApiService.addListener(
					ChatRequestApiConstants.Callbacks.GetActiveChatTranscript,
					(response: GetActiveChatTranscriptResponse) => {
						if (response?.transcript) {
              this.ngEntityStore.dispatch(ChatActions.GetActiveChatTranscriptResponse(response));
						}

					}
				);

				this.chatRequestApiService.addListener(
					ChatRequestApiConstants.Callbacks.GetXaSuggestions,
					(response: GetXaSuggestionsResponse) => {
						const addXaSuggestions: AddXaSuggestions = {
							...response
						};
						this.ngEntityStore.dispatch(ChatActions.AddXaSuggestions(addXaSuggestions));
					}
				);

				this.chatRequestApiService.addListener(
					ChatRequestApiConstants.Callbacks.GetEngagementById,
					(getEngagementByIdResponse: GetEngagementByIdResponse) => {
						if (getEngagementByIdResponse.value) {
							const engagementsResponse = new GetEngagementsResponse();
							engagementsResponse.chatId = getEngagementByIdResponse.chatId;
							engagementsResponse.value = [getEngagementByIdResponse.value];

							const engagements = ChatHelper.parseAsyncEngagements(engagementsResponse, currentChats, this.timeService);

							const updateAsyncEngagements: UpdateAsyncEngagements = {
								chatId: getEngagementByIdResponse.chatId,
								engagements: engagements,
								unresolvedContactReason: ''
							};
							this.ngEntityStore.dispatch(ChatActions.UpdateAsyncEngagements({updateAsyncEngagements}));
						}
					}
				);

				this.chatRequestApiService.addListener(
					ChatRequestApiConstants.Callbacks.ChatInboundEvent,
					(inboundEventResponse: InboundChatEventResponse<InboundChatEventData>) => {
						const { source } = inboundEventResponse;
						switch (inboundEventResponse.data.eventName) {
							case (InboundEventOperationType.CloseChat):
								const { chatId, messageId, timestamp } = inboundEventResponse.data;
								const message = SystemMessages.InboundCloseChat;
								const stateChangeResponse: CustomerStateChangeResponse = { chatId, message, messageId, timestamp };
								this.ngEntityStore.dispatch(ChatActions.CloseEngagementInstruction({ stateChangeResponse, source }));
								break;
							default:
								return;
						}
					}
				);

				this.chatRequestApiService.addListener(
					ChatRequestApiConstants.Callbacks.GetConversationSummary,
					(response: GetChatSummaryDataResponse) => {
						const chatSummaryData: ChatSummaryData = {
							recap: response?.recap,
							traceId: response?.traceId
						};

						this.ngEntityStore.dispatch(ChatActions.UpdateChatSummary({ chatId: response?.chatId, chatSummaryData, error: !response?.success }));
					}
				);

				this.chatRequestApiService.addListener(
					ChatRequestApiConstants.Callbacks.LogTranslationRequest,
					(response: string) => {
						this.ngEntityStore.dispatch(ChatActions.LogTranslationRequest({ chatId: response, messageType: TranslatedMessageType.Inbound, isRetry: false}));
					}
				);

				// #endregion

				this.ngEntityStore.dispatch(HubsActions.hubsInitialized());
			})
		),
		{ dispatch: false }
	);

	connectChatRequestHub$ = createEffect(() =>
		this.actions.pipe(
			ofType(HubsActions.connectChatRequestHub),
			tap(() => {
				this.connectHub(
					this.chatRequestApiService,
					this.onChatRequestHubConnectionChange);
			})
		),
		{ dispatch: false }
	);

	voiceRequestHub$ = createEffect(() =>
		this.actions.pipe(
			ofType(HubsActions.connectVoiceHub),
			tap(() => {
				if (this.config.voiceHub?.endpoint) {
					this.connectHub(
						this.voiceHubService,
						this.onVoiceHubConnectionChange);
				}
			})
		),
		{ dispatch: false }
	);

	echoVoiceHub$ = createEffect(() =>
		this.actions.pipe(
			ofType(HubsActions.hubsInitialized),
			switchMap(() => timer((15 * 1000), (10 * 60 * 1000))), // start after 15 seconds and then every 10 minutes
			concatLatestFrom(() => [
				this.ngEntityStore.select(fromHubs.getIsVoiceHubConnected),
				this.ngEntityStore.select(fromAgentAuth.getVoiceSessionId),
			]),
			filter(([, isHubConnected]) => isHubConnected),
			tap(async ([, , sessionId]) => {
				await this.echoService.sendEcho(this.voiceHubService, this.loggingFactory, sessionId);
				await this.echoService.sendEventHubEcho(this.voiceHubService, this.loggingFactory, sessionId);
			})
		),
		{ dispatch: false }
	);

	echoChatHub$ = createEffect(() =>
		this.actions.pipe(
			ofType(HubsActions.hubsInitialized),
			switchMap(() => timer((15 * 1000), (7 * 60 * 1000))),  // start after 15 seconds and then every 7 minutes
			concatLatestFrom(() => [
				this.ngEntityStore.select(fromHubs.getIsChatRequestHubConnected)
			]),
			filter(([, isHubConnected]) => isHubConnected),
			tap(async () => {
				await this.echoService.sendEcho(this.chatRequestApiService, this.loggingFactory);
			})
		),
		{ dispatch: false }
	);

	oneCtiRegistered$ = createEffect(() =>
		this.actions.pipe(
			ofType(AgentAuthActions.oneCtiRegistered),
			concatLatestFrom(() => [
				this.ngEntityStore.select(fromHubs.getIsVoiceHubConnected),
				this.ngEntityStore.select(fromAgentAuth.getVoiceSessionId)
			]),
			filter(([, isVoiceHubConnected]) => isVoiceHubConnected),
			tap(([{avayaAgentId, phoneExtension}, , sessionId]) => {
				this.loggingFactory.addLoggingProperty('avayaAgentId', avayaAgentId);
				this.loggingFactory.addLoggingProperty('extension', phoneExtension);

				if (sessionId) {
					this.voiceHubService.getSessionStatus({phoneExtension, sessionId}).then((response) => {
						if (response?.success) {
							this.ngEntityStore.dispatch(AgentAuthActions.createVoiceSession({ sessionId, errorState: HomeHeaderErrorState.None }));
						}
						else {
							this.createVoiceSession(phoneExtension);
						}
					});
				}
				else {
					this.createVoiceSession(phoneExtension);
				}
			})),
		{ dispatch: false }
	);

	connectNiagaraLogHub$ = createEffect(() =>
		this.actions.pipe(
			ofType(HubsActions.connectNiagaraLogHub),
			tap(() => {
				this.connectHub(
					this.analyticsApiService,
					this.onNiagaraLogHubConnectionChange);
			})
		),
		{ dispatch: false }
	);

	disconnectChatRequestHub$ = createEffect(() =>
		this.actions.pipe(
			ofType(
				HubsActions.disconnectChatRequestHub,
				AppActions.AppStopped
			),
			tap(() => {
				this.disconnectHub(
					this.chatRequestApiService,
					this.onChatRequestHubConnectionChange);
			})
		),
		{ dispatch: false }
	);

	disconnectNiagaraLogHub$ = createEffect(() =>
		this.actions.pipe(
			ofType(HubsActions.disconnectNiagaraLogHub),
			tap(() => {
				this.disconnectHub(
					this.analyticsApiService,
					this.onNiagaraLogHubConnectionChange);
			})
		),
		{ dispatch: false }
	);

	niagaraLogHubConnected$ = createEffect(() =>
		this.actions.pipe(
			ofType(HubsActions.niagaraLogHubStateUpdated),
			filter(({ connectionState }) => {
				return connectionState === HubConnectionState.Connected || connectionState === HubConnectionState.Reconnected;
			}),
			tap(() => {
				this.queueService.processQueue();
			})),
		{ dispatch: false }
	);

	niagaraLogHubDisConnected$ = createEffect(() =>
		this.actions.pipe(
			ofType(HubsActions.niagaraLogHubStateUpdated),
			filter(({ connectionState }) => {
				return connectionState === HubConnectionState.Disconnected;
			}),
			tap(() => {
				this.queueService.stopQueueProcessing();
			})),
		{ dispatch: false }
	);

  redirectAfterDisconnection$ = createEffect(() =>
    this.actions.pipe(
      ofType(HubsActions.chatRequestHubStateUpdated),
      filter(({ connectionState }) => connectionState === HubConnectionState.Reconnecting),
      concatLatestFrom(() => this.ngEntityStore.select(fromSettings.getDisconnectTimeout)),
      delayWhen(([_, disconnectTimeout]) => interval(disconnectTimeout * 1000)),
      concatLatestFrom(() => [this.ngEntityStore.select(fromSettings.getDisconnectTimeout), this.ngEntityStore.select(fromHubs.getChatHubLastReconnecting), this.ngEntityStore.select(fromHubs.getChatRequestHubConnectionState)]),
      tap(([_, disconnectTimeout, lastReconnectingTimestamp, chatHubConnectionState]) => {
        if (
          this.timeService.unix() - lastReconnectingTimestamp.timestamp >= disconnectTimeout && chatHubConnectionState === HubConnectionState.Reconnecting) {
          this.ngEntityStore.dispatch(AgentAuthActions.loggedOut(LoggedOutMethod.Disconnect));
          LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.DisconnectTimeout);
        }
      })),
    { dispatch: false }
  );

	chatHubConnectionIsPoor$ = createEffect(() =>
		this.actions.pipe(
			ofType(HubsActions.echoCompleted),
			filter(({ echo }) => echo.hubName === HubNames.FixAgentChatRequestHub),
			concatLatestFrom(() => [this.ngEntityStore.select(fromHubs.selectIsChatConnectionPoor), this.ngEntityStore.select(fromHubs.selectChatConnectionLogData)]),
			filter(([, isPoor ]) => isPoor === true),
			tap(([, , logData]) => {
				LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.ConnectionPoor, logData, true);
			})),
		{ dispatch: false }
	);

	private readonly accessTokenFactory = (): Promise<string> => {
		const service = environment.auth.useComcastSso ? this.comcastSsoService : this.authService; 		
		return new Promise<string>((resolve, reject) => {
			service.getToken().then(token => {
				const accessToken: AccessToken = <AccessToken>token;
				resolve(accessToken.accessToken);
			}, err => {
				reject(err);
			});
		});
	}

	private readonly dispatchHubStateUpdated = (connectionState: HubConnectionState, error: Error, action: ActionCreator<string, (props: HubConnectionUpdate) => HubConnectionUpdate & TypedAction<string>>) => {
		const timestamp = this.timeService.unix();
		this.ngEntityStore.dispatch(action({ connectionState, timestamp, error }));
	}

	private readonly onChatRequestHubConnectionChange = (connectionState: HubConnectionState, error: Error): void =>
		this.dispatchHubStateUpdated(connectionState, error, HubsActions.chatRequestHubStateUpdated)

	private readonly onVoiceHubConnectionChange = (connectionState: HubConnectionState, error: Error): void =>
		this.dispatchHubStateUpdated(connectionState, error, HubsActions.voiceHubStateUpdated)

	private readonly onNiagaraLogHubConnectionChange = (connectionState: HubConnectionState, error: Error): void =>
		this.dispatchHubStateUpdated(connectionState, error, HubsActions.niagaraLogHubStateUpdated)

	private initHub
		(
			hub: HubService,
			hubName: string,
			endpoint: string,
			connectionStateCallback: (state: HubConnectionState, error?: Error) => void,
			reconnectRetryPolicy: IRetryPolicy,
			accessTokenFactory: () => Promise<string> = null,
			protocol: IHubProtocol = null
		): void {
		const reconnectSettings: ReconnectSettings =
		{
			retryPolicy: reconnectRetryPolicy
		};
		const hubSettings = new HubSettings
			(
				hubName,
				endpoint,
				accessTokenFactory || this.accessTokenFactory,
				(error: Error) => {
					connectionStateCallback(HubConnectionState.Disconnected, error);
				},
				(error: Error) => {
					connectionStateCallback(HubConnectionState.Reconnecting, error);
				},
				() => {
					connectionStateCallback(HubConnectionState.Reconnected);
				},
				reconnectSettings,
				HttpTransportType.WebSockets
			);

		hub.init(hubSettings, protocol);
	}

	private async connectHub(hub: HubService, connectionStateCallback: (state: HubConnectionState, error?: Error) => void) {
		if (!hub.isConnected) {
			try {
				await hub.start();
				connectionStateCallback(HubConnectionState.Connected);
			}
			catch (error) {
				connectionStateCallback(HubConnectionState.Errored, error);
			}
		}
	}

	private async disconnectHub(hub: HubService, connectionStateCallback: (state: HubConnectionState, error?: Error) => void) {
		if (hub.isConnected) {
			try {
				await hub.stop();
			}
			catch (error) {
				connectionStateCallback(HubConnectionState.Errored, error);
			}
		}
	}

	private async _processAcceptance(
		chat: Chat,
		currentChats: Chat[],
		agentAvailability: AvailabilityChange,
		settingAvailability: boolean,
		recommendation: RecommendationsResponse,
		smartResponses?: SmartResponses[],
		placeHolders?: Placeholder[],
		itgsInProgress?: ItgMetadata[]
	): Promise<void> {

		if (chat.state !== ChatState.PendingAcceptance) {
			//TODO log?
			return;
		}

		//make sure chat isnt already registered in data store
		const chatFound = typeof (currentChats.find(c => c.chatId === chat.chatId)) !== 'undefined';
		if (!chatFound) {
			if (agentAvailability.available !== AvailabilityType.Available || settingAvailability) {
				this.ngEntityStore.dispatch(ChatActions.RefuseChat({ chat }));
			}
			else {
				this.ngEntityStore.dispatch(ChatActions.AcceptNewChat({ chat, recommendation, smartResponses, placeHolders, itgsInProgress }));
			}
		}
	}

	private _chatClosed(chatId: string, chats: Chat[], completedChatCount: number): void {
		const chat = chats.find(c => c.chatId === chatId);

		if (!chat) { return; }

		this.colorService.releaseColor(chat.color);

		this.ngEntityStore.dispatch(ChatActions.Closed(chat));

		this.ngEntityStore.dispatch(AgentActions.UpdateCompletedChatCount(completedChatCount + 1));
	}

  private createSystemMessage(chatId: string, message: string, messageId: string, systemType: SystemMessageType, timestamp: number){
    const systemMessage: SystemChatMessage = {
      chatId,
      message,
      messageId,
      systemType,
      timestamp,
      sender: SenderType.System,
      type: ChatMessageType.Message,
    };
    return systemMessage;
  }

  private createVoiceSession(phoneExtension: string) {
	this.voiceHubService.createSession({phoneExtension}).then((response) => {
		if (response && response.body && response.body.success) {
			this.ngEntityStore.dispatch(AgentAuthActions.createVoiceSession({ sessionId: response.body.sessionId, errorState: HomeHeaderErrorState.None }));
		}
		else {
			this.ngEntityStore.dispatch(VoiceUiActions.UpdateHeaderErrorState({ errorState: HomeHeaderErrorState.Critical }));
		}
	});
  }
}
