// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { AgentActions, AgentAvailabilityActions, AppActions, ChatActions, ChatUiActions, ConnectAccountActions, HubsActions, LogActions, OneCtiActions, toPayload, UiActions } from './actions';
import { tap, map, debounceTime, concatMap, withLatestFrom, filter, delay, mapTo, takeUntil, mergeMap, distinctUntilChanged } from 'rxjs/operators';
import { of, timer } from 'rxjs';
import { Chat } from './models/chat';
import { ChatMessage } from './models/chatMessage';
import { SenderType, SystemMessageType, ChatMessageType, CustomerActivityStatus, SendMessageType, ChatState, TypingStatus, XaSuggestionActionType, SendSuggestionSource, ChannelType, ChatMessageStatus, SuggestionType, UpdateMessageType, ItgActionType, DataCollectionState, SecureDataRequestCancelReason, StartSchedulerMethod, CloseSuggestionFeedbackMethod, LoadingState, SystemInfoMessageType, SendTaskType, Language, TranslatedMessageType } from './models/enums';
import { TransferChatRequest } from './models/requests/transferChat';
import { BounceChat } from './models/requests/bounceChat';
import { SystemChatMessage } from './models/systemChatMessage';
import { GetChatAccountLocality } from './models/requests/getChatAccountLocality';
import { ChatGetUpdateSettings, GetActiveEngagementsSettings } from '../constants/application-health.constants';
import { ReportImage } from './models/requests/reportImage';
import { ChatImageMessage } from './models/chatImageMessage';
import { GetChatAccountUsers } from './models/requests/getChatAccountUsers';
import { UpdateAccountNumberRequest } from './models/requests/updateAccountNumberRequest';
import { UpdateAccountInformation } from './models/updateAccountInformation';
import { SendChatMessage } from './models/sendChatMessage';
import { AvailabilityType } from './models/enums';
import { SendAgentTypingStatus } from './models/sendAgentTypingStatus';
import { LogCustomerActivityStatus } from './models/log-customer-activity-status';
import { GetAsyncEngagements } from './models/getAsyncEngagements';
import { CloseChatInteraction } from './models/closeChatInteraction';
import { CloseChatRequest } from './models/closeChatRequest';
import { CloseAsyncChat } from './models/closeAsyncChat';
import { CloseConversation } from './models/closeConversation';
import { AbandonedChatAutomationEvent } from './models/events/abandon-chat-automation-event';
import { AbandonedChatOperations, AgentOperations, ChatOperations, DebugEvents, autoCloseReasons } from '../constants/event-logs.constants';
import { AcceptChat } from './models/acceptChat';
import { PrevAgents } from './models/prevAgents';
import { GetPriorEngagements } from './models/getPriorEngagements';
import { PriorEngagement } from './models/priorEngagement';
import { LogHelper } from '../utils/logHelper';
import { CloseChat } from './models/closeChat';
import { JoinChats } from './models/joinChats';
import { GetActiveChatTranscript } from './models/getActiveChatTranscript';
import { HttpResponse } from '@angular/common/http';
import { GetChatAccountLocalityResponse } from './models/responses/getChatAccountLocalityResponse';
import { ChatAccountLocality } from './models/chatAccountLocality';
import { AccountUsersResponse } from './models/responses/accountUsersResponse';
import { PriorEngagementAccordionChange } from './models/priorEngagementAccordionChange';
import { SetPriorEngagements } from './models/setPriorEngagements';
import { MaskTextRequest } from './models/requests/maskTextRequest';
import { UnlinkAccount } from './models/unlinkAccount';
import { GetXaSuggestionsRequest } from './models/requests/getXaSuggestionsRequest';
import { FeatureFlags } from '../constants/featureFlags.constants';
import { UpdateAccountNumber } from './models/updateAccountNumber';
import { SelectedHistoryEngagement } from './models/selected-history-engagement';
import { ChatHelper } from '../utils/chatHelper';
import { MarkAsMercuryChatRequest } from './models/markAsMercuryChatRequest';
import { SendSuggestion } from './models/requests/send-suggestion';
import { AddXaSuggestions } from './models/addXaSuggestions';
import { getChatCustomerActivityStatus, getChatMessage, getChatMessages, getPaginationData, getPriorEngagementsLoadingData} from './chat.selectors';
import { GetEngagementById } from './models/getEngagementById';
import { getChat } from './chat.selectors';
import { getIsLockedOut } from './agent.selectors';
import { LoadAgentAnalytics } from './models/loadAgentAnalytics';
import { fromAgent, fromAgentAuth, fromAgentAvailability, fromApp, fromChat, fromCxGptResponses, fromHubs, fromLanguageTranslation, fromPlaceholders, fromSettings } from './selectors';
import { ChatInteraction } from './models/chatInteraction';
import { GetDispositions } from './models/getDispositions';
import { CustomerButtonResponseLog } from './models/logDimensions/customer-button-response-log';
import { SendSuggestionLog } from './models/logDimensions/send-suggestion-log';
import { NewSuggestionLog } from './models/logDimensions/new-suggestion-log';
import { Customer, ExternalSuggestion, SendMessageResponse, UpdateMessages, UpdateUui } from './models';
import { DispositionLogInfo, DispositionRadioSelection } from './models/dispositionSelection';
import { GetPriorEngagementsActionData } from './models/getPriorEngagementsActionData';
import { ChatNotFoundLog } from './models/logDimensions/chat-not-found-log';
import { Guid } from 'guid-typescript';
import { getPriorEngagementsPageSize, hasFeatureFlag } from './settings/settings.selectors';
import { GetActiveEngagements } from './models/authentication/ActiveEngagements';
import { DayTimeService, HubConnectionState, HubResponse, LoggingFactoryService, MaskingService } from '@cxt-cee-chat/merc-ng-core';
import { CustomerTypingStatusTimeoutInSeconds, DataCollectionSuggestionTitles, GetUpdatesPreventedSources, PriorEngagementConstants } from '../constants/constants';
import { RefuseChat } from './models/requests/refuse-chat';
import { GetXaSuggestionsMessage } from './models/getXaSuggestionsMessage';
import { ItgConfiguration, SchedulerIntents } from '../constants/itg-configuration.constants';
import { Store } from '@ngrx/store';
import { AppState } from './state';
import { ChatRequestApiService } from '../services/chat-request-api.service';
import { UserIdentityService } from '../services/user-identity.service';
import { CustomerInfoApiService } from '../services/customer-info-api.service';
import { ChatPersisterService } from './chat/chat-persister.service';
import { NlpTranscriptService } from '../services/nlp-transcript.service';
import { GetTransferOptionsRequest } from './models/requests/get-transfer-options-request';
import { RetrySendMessageLog } from './models/logDimensions/retry-send-message-log';
import { AbortSendMessageLog } from './models/logDimensions/abort-send-message-log';
import { NewExternalSuggestionLog } from './models/logDimensions/new-external-suggestion-log';
import { SendExternalSuggestionLog } from './models/logDimensions/send-external-suggestion-log';
import { AbandonedChatAutomationHelper } from '../utils/abandoned-chat-automation-helper';
import { AbandonedChatAutomationDetails } from './models/abandoned-chat-automation-details';
import { ChatTranscriptResponse } from './models/responses/chat-transcript-response';
import { ColorEnumeratorService } from '../services/color-enumerator.service';
import { ServerSyncResultLog } from './models/logDimensions/server-sync-result-log';
import { Suggestion, SuggestionBase } from './models/suggestion';
import { ScriptType } from './models';
import { AddRecommendation } from './models/addRecommendation';
import { SecureDataCollectionHelper } from '../utils/secure-data-collection-helper';
import { NewGreetingSuggestionLog, SendGreetingSuggestionLog } from './models/logDimensions/greeting-suggestion-log';
import * as fromSmartResponses from './smart-responses/smart-responses.selectors';
import { SmartResponses } from './smart-responses/smart-responses.model';
import { LogInfo, LogType } from './models/LogTypeInterfaces';
import { SelectMessageLog, SelectMessageType } from './models/logDimensions/select-message-log';
import { Placeholder } from './placeholders/placeholder.model';
import { SendItgPrimerLog } from './models/logDimensions/send-itg-primer-log';
import { ItgHelper } from '../utils/itg-helper';
import { TransferSummaryConstants } from '../constants/transfer-summary.constants';
import { JoinChatsResponse } from './models/responses/join-chats-response';
import { ItgErrorMessages } from '../constants/error-messages';
import { QueueChatRequestService } from '../services/queue-chat-request.service';
import { ChatStartGreetingHelper } from '../utils/chat-start-greeting-helper';
import { HtmlHelperService } from '../services/html-helper.service';
import { MercuryCtiChatService } from 'src/app/services/mercury-cti-chat.service';
import * as fromOneCti from './one-cti/one-cti.selectors';
import { AppConfigService } from 'src/app/services/app-config.service';
import { UuiHelper } from '../utils/uui-helper';
import { AgentStatePersisterService } from '../services/agent-state-persister.service';
import { ChatMessageForSummary } from './models/chatMessageForSummary';
import { GetChatSummaryDataRequest } from './models/requests/get-chat-summary-data-request';
import { CtiResumeChatRequest } from 'src/app/models/cti-resume-chat-request';
import { SendCloseChatInstructionsRequest } from './models/requests/send-close-chat-instructions-request';
import { PreviousChatSummary } from './models/previousChatSummary';
import { UnusedSlotTimes } from './models/unusedSlotTimes';
import { AgentHelper } from '../utils/agentHelper';
import { MercuryNotificationService } from 'src/app/services/mercury-notification.service';
import { CxGptResponseVm } from './models/cx-gpt-response';
import { SendUnifiedNotesRequest } from './models/requests/send-unified-notes-request';
import { AsyncEngagementTranscript } from './models/chatTranscript';
import { PostChatSummaryData } from './models/postChatSummaryData';
import { AgentGroup } from './models/agentGroup';
import { BusinessUnit } from './models/business-unit';
import { MarkAbandonedInAsyncQueueRequest } from './models/mark-abandoned-in-async-queue-request';
import { TranslatableChatInfo } from './models/translatableChatInfo';
import { TextTranslationRequest, TranslationRequest } from './models/requests/translate-request';
import * as LanguageTranslationActions from './language-translation/language-translation.actions';
import { GetEnhancedChatMessageRequest } from './models/requests/get-enhanced-chat-message-request';
import { CustomerStateChangeResponse } from './models/responses/customerStateChangeResponse';
import { SystemMessages } from '../constants/systemMessages.constants';

@Injectable()
// tslint:disable-next-line: class-name
export class MercEffects_Chat {

	constructor(
		private ngEntityStore: Store<AppState>,
		private actions: Actions,
		private chatRequestApiService: ChatRequestApiService,
		private userIdentityService: UserIdentityService,
		private customerInfoService: CustomerInfoApiService,
		private chatPersisterService: ChatPersisterService,
		private loggingFactory: LoggingFactoryService,
		private nlpTranscriptService: NlpTranscriptService,
		private timeService: DayTimeService,
		private colorService: ColorEnumeratorService,
		private queueChatRequestService: QueueChatRequestService,
    private htmlHelper: HtmlHelperService,
    private agentStatePersisterService: AgentStatePersisterService,
    protected ctiService: MercuryCtiChatService,
    protected maskingService: MaskingService,
    protected appConfigService: AppConfigService,
    private mercuryNotificationService: MercuryNotificationService
	) { }

  private _callingGetUpdates: boolean = false;

  sendMessage$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.SendMessage, ChatActions.SendSmartResponse, ChatActions.SendCxGptResponse),
      concatLatestFrom(({ chatMessage }) => [
        this.ngEntityStore.select(getChatCustomerActivityStatus(chatMessage.chatId)),
        this.ngEntityStore.select(fromChat.getChat(chatMessage.chatId)),
        this.ngEntityStore.select(fromChat.shouldTranslateMessages(chatMessage.chatId)),
        this.ngEntityStore.select(fromChat.getTranslationLanguage(chatMessage.chatId)),
        this.ngEntityStore.select(hasFeatureFlag(FeatureFlags.PreventAsyncInactiveReset))
      ]),
      tap(([{chatMessage}, lastStatus, chat, isTranslatedChat, translationLanguage, asyncInactiveFeatureFlagEnabled]) => {
        if (isTranslatedChat) {
          LogHelper.logTranslationRequest(this.loggingFactory, chat, TranslatedMessageType.Outbound, false);
          chatMessage = {
            ...chatMessage,
            translateTo: translationLanguage
          };
        }
        
        this._logAgentFirstResponseSendMessage(chatMessage, lastStatus, chat, null, asyncInactiveFeatureFlagEnabled);
      })
    ),
    { dispatch: false }
  );

  retrySendMessage$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.retrySendMessage),
      concatLatestFrom(({ chatMessage }) => [
        this.ngEntityStore.select(getChatCustomerActivityStatus(chatMessage.chatId)),
        this.ngEntityStore.select(getChatMessages(chatMessage.chatId)),
        this.ngEntityStore.select(fromChat.shouldTranslateMessages(chatMessage.chatId)),
        this.ngEntityStore.select(fromChat.getTranslationLanguage(chatMessage.chatId)),
        this.ngEntityStore.select(fromChat.getChat(chatMessage.chatId)),
        this.ngEntityStore.select(hasFeatureFlag(FeatureFlags.PreventAsyncInactiveReset))
      ]),
      tap(([{ chatMessage, newTraceId }, lastStatus, messages, isTranslatedChat, translationLanguage, chat, asyncInactiveFeatureFlagEnabled]) => {
        let retryMessage: SendChatMessage = {
          ...chatMessage,
          traceId: newTraceId
        };

        if (isTranslatedChat) {
          LogHelper.logTranslationRequest(this.loggingFactory, chat, TranslatedMessageType.Outbound, true);
          retryMessage = {
            ...retryMessage,
            translateTo: translationLanguage
          };
        }
        
        this.sendMessage(retryMessage, lastStatus, messages, null, asyncInactiveFeatureFlagEnabled, chat);
      })
    ),
    { dispatch: false }
  );

  retryTranslateMessage$ = createEffect(() =>
  this.actions.pipe(
    ofType(ChatActions.retryTranslateMessage),
    concatLatestFrom(({chatMessage}) => this.ngEntityStore.select(getChat(chatMessage.chatId))),
    tap(([{ chatMessage }, chat]) => {
      const messageToTranslate: TextTranslationRequest = {
        textToTranslate: chatMessage.message
      };
      const translateRequest: TranslationRequest = {
        chatId: chatMessage.chatId,
        textsToTranslate: [ messageToTranslate ],
        translateTo: 'en'
      };
      LogHelper.logTranslationRequest(this.loggingFactory, chat, TranslatedMessageType.Inbound, true);
      this.chatRequestApiService.translateTexts(translateRequest)
      .then(translationResponse => {
        this.ngEntityStore.dispatch(ChatActions.retryTranslateMessageUpdated({ chatMessage, isError: !translationResponse.success, translationResponse: translationResponse.body }));
        if (!translationResponse.success){
          const errorMsg = translationResponse?.body?.translatedMessages?.length ? translationResponse?.body?.translatedMessages[0].errorMessage : undefined;
          LogHelper.logTranslationError(this.loggingFactory, chatMessage, chat, errorMsg);
        }
      })
      .catch((error) => {
        LogHelper.logTranslationError(this.loggingFactory, chatMessage, chat, error.message);
        this.ngEntityStore.dispatch(ChatActions.retryTranslateMessageUpdated({ chatMessage, isError: true, translationResponse: undefined }));
      });
    })
  ),
  { dispatch: false }
);

  updatesComplete$ = createEffect(() =>
    this.actions.pipe(
      ofType(AppActions.UpdatesComplete),
      concatLatestFrom(_ => this.ngEntityStore.select(fromApp.getUpdatesData)),
      tap(([ { traceId: completedTraceId }, getUpdatesData ]) => {
        const { updatesInProgressTraceId } = getUpdatesData;
        // continue the get updates polling loop if the in progress trace id matches what is returned in the callback
        // or if progress trace id is not set
        if (!updatesInProgressTraceId || updatesInProgressTraceId === completedTraceId) {
          const traceId = Guid.raw();
          this.ngEntityStore.dispatch(AppActions.GetUpdates({ traceId }));
        }
        else {
          const logPayload: LogInfo = {
            logType: LogType.debug,
            operationName: DebugEvents.GetUpdatesPrevented,
            data: {
              ...getUpdatesData,
              traceId: completedTraceId,
              source: GetUpdatesPreventedSources.updatesComplete
            }
          };
          this.ngEntityStore.dispatch(LogActions.logDebug({logPayload}));
        }
      })
    ),
    { dispatch: false }
  );

  startGetUpdates$ = createEffect(() =>
    this.actions.pipe(
      ofType(AppActions.StartGetUpdates),
      concatLatestFrom(_action => [
        this.ngEntityStore.select(fromHubs.getChatRequestHubConnectionState)
      ]),
      tap(([, chatHubConnectionState]) => {
        const traceId = Guid.raw();
        this.ngEntityStore.dispatch(AppActions.GetUpdates({ traceId }));

        if (chatHubConnectionState === HubConnectionState.Connected) {
          this.ngEntityStore.dispatch(ChatActions.getActiveEngagements());
        }
        else if (chatHubConnectionState === HubConnectionState.Reconnected){
          this.ngEntityStore.dispatch(AppActions.ChatStateDirty());
        }
      })
    ),
    { dispatch: false }
  );

  chatStateDirty$ = createEffect(() =>
    this.actions.pipe(
      ofType(AppActions.ChatStateDirty,
        AppActions.ServerSyncFailed),
      debounceTime(GetActiveEngagementsSettings.WaitTimeInSeconds * 1000),
      tap(() => {
        this.ngEntityStore.dispatch(ChatActions.getActiveEngagements());
      })
    ),
    { dispatch: false }
  );

  // when disconnected or already have a call out, don't try to make the call
  getActiveEngagements$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.getActiveEngagements),
      concatLatestFrom(_action => [
        this.ngEntityStore.select(fromHubs.getChatRequestHubConnectionState),
        this.ngEntityStore.select(fromApp.getPendingServerSync)
      ]),
      filter(([, chatHubConnectionState, pendingServerSync]) =>
        !pendingServerSync && this.isHubStateConnected(chatHubConnectionState)
      ),
      tap(async () => {
        this.ngEntityStore.dispatch(AppActions.PendingServerSync({timestamp: this.timeService.unix()}));

        const engagementIds = await this.chatPersisterService.getIds();

        const model: GetActiveEngagements = {
          engagementIds,
          agentId: this.userIdentityService.username
        };
        this.chatRequestApiService.getActiveEngagements(model)
          .catch(() => {
            this.ngEntityStore.dispatch(AppActions.ServerSyncFailed());
          });

        LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.ServerSync);
      })
    ),
    { dispatch: false }
  );

  getActiveEngagementsResponse$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.getActiveEngagementsResponse),
      concatLatestFrom(() => [
        this.ngEntityStore.select(fromChat.getChats),
        this.ngEntityStore.select(fromApp.getServerSyncTimestamp),
        this.ngEntityStore.select(fromChat.getItgRunningChatIds)
      ]),
      tap(async ([{engagements}, chats, serverSyncTimestamp, itgRunningChatIds]) => {
        const storageChats: Chat[] = await this.chatPersisterService.getEntities();

        this.ngEntityStore.dispatch(ChatActions.updateActiveEngagements({engagements, storageChats, chats, serverSyncTimestamp}));

        const chatIdsToJoin = [];
        engagements.forEach(engagement => {
          const chatInStorage = storageChats.find(c => c.chatId === engagement.engagementId);
          const chatInState = chats.find(c => c.chatId === engagement.engagementId);
          if (!chatInState && chatInStorage?.color) {
            // need to update color service on refresh
            this.colorService.setColorInUse(chatInStorage.color);
          }

          const itgRunning = itgRunningChatIds.some(chatId => chatId === engagement.engagementId);
          const stateChat = chatInState ?? chatInStorage;

          this.syncItg(
            itgRunning,
            () => ChatHelper.parseTranscriptInteractions(engagement.engagementId, engagement.participants, engagement.interactions),
            stateChat?.messages,
            engagement.engagementId
          );

          // edge case where the agent refreshes the page while disconnected
          // if the agent disconnects from the chat the agents array from the nuance realtime api will be empty
          // that means the agent must rejoin this chat
          // when the agent refreshes, we are unable to go through the re-login/join process
          if (stateChat && !engagement.agentIds?.length){
            chatIdsToJoin.push(engagement.engagementId);
          }
        });

        this.ngEntityStore.dispatch(ChatActions.JoinChats({chatIds: chatIdsToJoin}));
      })
    ),
    { dispatch: false }
  );

  // retry when not connected
  retryServerSync$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.getActiveEngagements),
      concatLatestFrom(_action => this.ngEntityStore.select(fromHubs.getChatRequestHubConnectionState)),
      filter(([, chatHubConnectionState]) => !this.isHubStateConnected(chatHubConnectionState)),
      debounceTime(GetActiveEngagementsSettings.TimeBetweenRetryInSeconds * 1000),
      tap(() => {
        this.ngEntityStore.dispatch(ChatActions.getActiveEngagements());
      })
    ),
    { dispatch: false }
  );

  getUpdates$ = createEffect(() =>
    this.actions.pipe(
      ofType(AppActions.GetUpdates),
      concatLatestFrom(_action => [
        this.ngEntityStore.select(fromApp.getUpdatesData),
        this.ngEntityStore.select(fromChat.getChats),
        this.ngEntityStore.select(hasFeatureFlag(FeatureFlags.LanguageTranslator))
]),
      filter(([, {retrievingUpdates}]) => retrievingUpdates),
      tap(async ([{ traceId }, getUpdatesData, chats, hasLanguageTranslatorFeatureFlag]) => {
        const callingGetUpdates = this._callingGetUpdates;
        this._callingGetUpdates = true;

        if ( callingGetUpdates ) {
          this.ngEntityStore.dispatch(AppActions.GetUpdatesPrevented({ traceId, getUpdatesData, source: GetUpdatesPreventedSources.client }));
          this._callingGetUpdates = false;
          return;
        }

        try {
          const translationList: TranslatableChatInfo[] = [];
          chats.forEach(chat => {
            const isTranslatedChat = ChatHelper.shouldTranslateMessages(ChatHelper.getCustomerQueueLanguage(chat.customerLanguage, chat.defaultGroupTranslationLanguage), hasLanguageTranslatorFeatureFlag, chat.isTranslationConfigEnabled);
            if (isTranslatedChat){
              const translationData: TranslatableChatInfo = {
                chatId: chat.chatId,
                translateTo: 'en'
              };
              translationList.push(translationData);
            }
          });
          const response = await this.chatRequestApiService.getUpdates({ traceId, translationList });
          const { shortCircuited } = response?.body ?? {};

          if (shortCircuited) {
            // according to the server, there is already a polling request running
            this.ngEntityStore.dispatch(AppActions.GetUpdatesPrevented({ traceId, getUpdatesData, source: GetUpdatesPreventedSources.server }));
          }
        }
        // TODO: something if the call fails (auth failure needs more than a retry)?
        finally {
          this._callingGetUpdates = false;
        }
      })
    ),
    { dispatch: false }
  );

  updatesPrevented$ = createEffect(() =>
    this.actions.pipe(
      ofType(AppActions.GetUpdatesPrevented),
      tap(({traceId, getUpdatesData, source}) => {
        const logPayload: LogInfo = {
          logType: LogType.debug,
          operationName: DebugEvents.GetUpdatesPrevented,
          data: {
            ...getUpdatesData,
            traceId,
            source
          }
        };
        this.ngEntityStore.dispatch(LogActions.logDebug({logPayload}));
      })
    ),
    { dispatch: false }
  );

  /**
   * This effect starts the same time as getUpdates$.
   * The callback to updatesComplete$ should complete in 30 seconds,
   * so we will wait ChatGetUpdateSettings.WaitTimeInSeconds (currently 45 seconds).
   * After the debounce time we check to make sure the LastUpdatesCompleteTimestamp has been updated
   * within the expected time frame and if it hasn't we dispatch AppActions.GetUpdates again.
   */
  ensureGetUpdatesIsRunning$ = createEffect(() =>
    this.actions.pipe(
      ofType(
        AppActions.GetUpdates,
        AppActions.StartGetUpdates
      ),
      debounceTime(ChatGetUpdateSettings.WaitTimeInSeconds * 1000),
      concatLatestFrom(_action => this.ngEntityStore.select(fromApp.getLastUpdatesCompleteTimestamp)),
      tap(([, lastUpdatesCompleteTimestamp]) => {
        if (this.isLastUpdateOutdated(lastUpdatesCompleteTimestamp)) {
          const traceId = Guid.raw();
          this.ngEntityStore.dispatch(AppActions.GetUpdates({ traceId }));
        }
      })
    ),
    { dispatch: false }
  );

  acceptNewChat$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.AcceptNewChat),
      tap(({ chat }) => {
        const acceptChatRequest: AcceptChat = {
          chatId: chat.chatId,
          queueName: chat.queueName
        };
        LogHelper.logChatEvent(this.loggingFactory, ChatOperations.NewEngagementAcceptChatAPICall, chat);

        if (chat.transferred){
          const latestTransferCard = chat.messages?.filter(f => f.type === ChatMessageType.TransferCard)?.sort((a, b) => a.timestamp < b.timestamp ? 1 : -1)[0];
          if (latestTransferCard?.title === TransferSummaryConstants.AgentDisconnectTitle){
            LogHelper.logChatEvent(this.loggingFactory, ChatOperations.ReceiveInvoluntaryTransfer, chat);
          }
        }

        this.chatRequestApiService.acceptChat(acceptChatRequest).then((response) => {
          LogHelper.logChatEvent(this.loggingFactory, ChatOperations.NewEngagementAcceptChatAPICallResponse, chat);
          this.ngEntityStore.dispatch(ChatActions.AcceptChatResponse(response.body));
        }).catch(() => {
            this.ngEntityStore.dispatch(ChatActions.ChatAcceptanceUnknown(chat));
        });
      })
    ),
    { dispatch: false }
  );

  //Remove when XA Translates messages
  translateTransferredChat$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.ChatAccepted),
      concatLatestFrom(({chat}) => [
        this.ngEntityStore.select(fromChat.getUntranslatedChatMessages(chat.chatId)),
        this.ngEntityStore.select(fromChat.isTranslatedChat(chat.chatId))
      ]),
      filter(([{chat}, untranslatedMessages, isTranslatedChat]) => chat.transferred && Boolean(untranslatedMessages?.length && isTranslatedChat)),
      tap(([{chat}, untranslatedMessages]) => {
        const textsToTranslate = untranslatedMessages.map(m => {
          const textTranslationRequest: TextTranslationRequest = {
            textToTranslate: m.message,
            traceId: m.messageId
          };
          return textTranslationRequest;
        });

        this.sendTranslateTextsToEnglishRequest(chat, textsToTranslate, false);
      })
    ),
    { dispatch: false }
  );

  //Remove when XA Translates messages
  translateChatsOnReload$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.retryTranslateMessageOnReload),
      concatLatestFrom(({chat}) => [
        this.ngEntityStore.select(fromChat.getUntranslatedChatMessages(chat.chatId)),
        this.ngEntityStore.select(fromChat.isTranslatedChat(chat.chatId))
      ]),
      filter(([{chat}, untranslatedMessages, isTranslatedChat]) => chat && Boolean(untranslatedMessages?.length && isTranslatedChat)),
      tap(([{chat}, untranslatedMessages]) => {
        const textsToTranslate = untranslatedMessages.map(m => {
          const textTranslationRequest: TextTranslationRequest = {
            textToTranslate: m.message,
            traceId: m.messageId
          };
          return textTranslationRequest;
        });

        this.sendTranslateTextsToEnglishRequest(chat, textsToTranslate, false);
      })
    ),
    { dispatch: false }
  );

  //Remove when XA Translates messages
  translateAsyncEngagement$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.UpdateAsyncEngagements),
      concatLatestFrom(({updateAsyncEngagements}) => [
        this.ngEntityStore.select(fromLanguageTranslation.getIsAlreadyAsyncTranslated(updateAsyncEngagements.chatId)),
        this.ngEntityStore.select(fromChat.shouldTranslateMessages(updateAsyncEngagements.chatId)),
        this.ngEntityStore.select(fromChat.getChat(updateAsyncEngagements.chatId))
      ]),
      filter(([, isAlreadyAsyncTranslated, isTranslatedChat]) => isTranslatedChat && !isAlreadyAsyncTranslated),
      tap(([{updateAsyncEngagements}, , , chat]) => {
        const {engagements} = updateAsyncEngagements;
        const textsToTranslate = this.mapAsyncEngagementsToTranslationRequest(engagements);

        if (textsToTranslate.length) {
          this.sendTranslateTextsToEnglishRequest(chat, textsToTranslate, true);
        }
      })
    ),
    { dispatch: false }
  );

  //In the case if customer language comes in after translateAsyncEngagement$ checks for language
  translateAsyncEngagementOnUuiUpdate$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.UpdateUui),
      map(action => toPayload<UpdateUui>(action)),
      concatLatestFrom(({chatId}) => [
        this.ngEntityStore.select(fromChat.getAsyncEngagementsByChatId(chatId)),
        this.ngEntityStore.select(fromLanguageTranslation.getIsAlreadyAsyncTranslated(chatId)),
        this.ngEntityStore.select(fromChat.shouldTranslateMessages(chatId)),
        this.ngEntityStore.select(fromChat.getChat(chatId))
      ]),
      filter(([, asyncEngagements, isAlreadyAsyncTranslated, isTranslatedChat]) => Boolean(isTranslatedChat && !isAlreadyAsyncTranslated && asyncEngagements?.length)),
      tap(([{}, asyncEngagements, , , chat]) => {
        const textsToTranslate = this.mapAsyncEngagementsToTranslationRequest(asyncEngagements);

        if (textsToTranslate.length) {
          this.sendTranslateTextsToEnglishRequest(chat, textsToTranslate, true);
        }
      })
    ),
    { dispatch: false }
  );
  
  acceptChatResponse$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.AcceptChatResponse),
      concatLatestFrom(() => [
        this.ngEntityStore.select(fromChat.getPendingChats),
        this.ngEntityStore.select(fromSettings.getAllChatAutomationSettings)
      ]),
      tap(([{ success, chatId }, pendingChats, chatAutomationSettings]) => {
        const chat = pendingChats.find(c => c.chatId === chatId);

        if (!chat) {
          const event: ChatNotFoundLog = {
            action: ChatActions.AcceptNewChat.type,
            engagementId: chatId
          };
          LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.ChatNotFound, event);
        }
        else if (success) {
          this.ngEntityStore.dispatch(ChatActions.ChatAccepted({chat, chatAutomationSettings}));
          this.ngEntityStore.dispatch(ChatActions.GetSystemScripts(chat));
        }
        else {
          this.ngEntityStore.dispatch(ChatActions.ChatAcceptanceFailed(chat));
        }
      })
    ),
    { dispatch: false }
  );

  refuseChat$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.RefuseChat),
      tap(({ chat }) => {
        LogHelper.logChatEvent(this.loggingFactory, ChatOperations.RefuseEngagement, chat);
        const refuseChatRequest: RefuseChat = {
          chatId: chat.chatId,
        };
        this.chatRequestApiService.refuseChat(refuseChatRequest);
      })
    ),
    { dispatch: false }
  );

  checkCustomerHeartbeat$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.CheckCustomerHeartbeat),
      concatLatestFrom(({chatId}) => [
        this.ngEntityStore.select(fromChat.getChat(chatId)),
        this.ngEntityStore.select(fromSettings.getAllCustomerHeartbeatThresholds)
      ]),
      tap(([{chatId, timeFromLastHeartbeat}, chat, heartbeatThresholds]) => {
        const globalHeartbeatThreshold = heartbeatThresholds.find(settings => settings.agentGroupId === 'default');
        const agentGroupHeartbeatThreshold = heartbeatThresholds.find(settings => settings.agentGroupId === chat.agentGroupId);
  
        const heartbeatThreshold = agentGroupHeartbeatThreshold?.heartbeatThreshold ?? globalHeartbeatThreshold?.heartbeatThreshold;

        if (chat.isAsync && heartbeatThreshold && timeFromLastHeartbeat > heartbeatThreshold && !chat.transferred){
          const stateChangeResponse: CustomerStateChangeResponse = { 
            chatId: chatId, 
            message: SystemMessages.CustomerLeft, 
            messageId: Guid.raw(), 
            timestamp: this.timeService.unix() };
          this.ngEntityStore.dispatch(ChatActions.CustomerHeartbeatExpired({stateChangeResponse}));
        }
      })
    ),
    { dispatch: false }
  );

  endChat$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.CustomerExited, ChatActions.CloseEngagementInstruction, ChatActions.CustomerHeartbeatExpired),
      concatLatestFrom(_ => this.ngEntityStore.select(fromChat.getChats)),
      tap(([chatData, allChats]) => {
        const targetChat = allChats.find(c => c.chatId === chatData.stateChangeResponse.chatId);
        if (targetChat) {
          const {chatId, message, timestamp, messageId } = chatData.stateChangeResponse;

          const msgModel: SystemChatMessage = {
            chatId,
            message,
            timestamp,
            sender: SenderType.System,
            systemType: SystemMessageType.CustomerExit,
            type: ChatMessageType.Message,
            messageId
          };

          this.ngEntityStore.dispatch(ChatActions.NewMessage(msgModel));
        }

      })
    ),
    { dispatch: false }
  );

  customerDisconnected$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.CustomerDisconnected),
      concatLatestFrom(chatData => [
        this.ngEntityStore.select(fromChat.getChat(chatData?.payload)),
        this.ngEntityStore.select(fromChat.getHasMessageSent(chatData?.payload))
      ]),
      tap(([, chat, hasMessageSent]) => {
          LogHelper.logChatEvent(this.loggingFactory, ChatOperations.CustomerLeftChat, chat, {method: 'disconnect'});
          const isAsyncPauseAbandoned = !hasMessageSent;
          this.handlePauseAbandonedAsyncChat(chat, isAsyncPauseAbandoned, autoCloseReasons.CustomerClosedChat);
      })
    ),
    { dispatch: false }
  );

  customerExited$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.CustomerExited),
      concatLatestFrom(chatData => [
        this.ngEntityStore.select(fromChat.getChat(chatData?.stateChangeResponse?.chatId)),
        this.ngEntityStore.select(fromChat.getHasMessageSent(chatData?.stateChangeResponse?.chatId))
      ]),
      tap(([, chat, hasMessageSent]) => {
          LogHelper.logChatEvent(this.loggingFactory, ChatOperations.CustomerLeftChat, chat, {method: 'close'});
          const isAsyncPauseAbandoned = !hasMessageSent;
          this.handlePauseAbandonedAsyncChat(chat, isAsyncPauseAbandoned, autoCloseReasons.CustomerClosedChat);
      })
    ),
    { dispatch: false }
  );

  customerHeatbeatExpired$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.CustomerHeartbeatExpired),
      concatLatestFrom(chatData => [
        this.ngEntityStore.select(fromChat.getChat(chatData?.stateChangeResponse?.chatId)),
        this.ngEntityStore.select(fromChat.getHasMessageSent(chatData?.stateChangeResponse?.chatId))
      ]),
      tap(([, chat, hasMessageSent]) => {
          const isAsyncPauseAbandoned = !hasMessageSent;
          this.handlePauseAbandonedAsyncChat(chat, isAsyncPauseAbandoned, autoCloseReasons.HeartbeatExpired);
      })
    ),
    { dispatch: false }
  );

  chatAccepted$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.ChatAccepted),
      tap(({chat}) => {
          this.handlePauseAbandonedAsyncChat(chat, chat.isAbandonedAsyncChat, autoCloseReasons.CustomerClosedChat);
      })
    ),
    { dispatch: false }
  );

  closeEngagementInstruction$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.CloseEngagementInstruction),
      concatLatestFrom(action => this.ngEntityStore.select(fromChat.getChat(action.stateChangeResponse.chatId))),
      tap(([{stateChangeResponse, source}, chat]) => {
        const model: SendCloseChatInstructionsRequest = {
          reason: stateChangeResponse.message,
          chatId: stateChangeResponse.chatId
        };
        this.chatRequestApiService.sendCloseChatInstruction(model);
        LogHelper.logChatEvent(this.loggingFactory, ChatOperations.CloseEngagementInstruction, chat, { source });
      })
    ),
    { dispatch: false }
  );

  wrapChat$ = createEffect(() =>
    this.actions.pipe(
      ofType(UiActions.WrapChat),
      concatLatestFrom(_action => this.ngEntityStore.select(fromChat.getSelectedChat)),
      tap(([, chat]) => {
        LogHelper.logChatEvent(this.loggingFactory, ChatOperations.PromptCloseEngagement, chat);
      })
    ),
    { dispatch: false }
  );

  getDispositions$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.GetDispositions),
      map(action => <string>toPayload(action)),
      concatLatestFrom(payload => this.ngEntityStore.select(getChat(payload))),
      tap(([, chat]) => {
        const { chatId, scriptTreeId, siteId } = chat;
        const requestModel: GetDispositions = {
          chatId,
          scriptTreeId,
          siteId
        };
        this.chatRequestApiService.getChatDispositions(requestModel);
      })
    ),
    { dispatch: false }
  );

  selectedDisposition$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.SelectedDispositions),
      map(action => toPayload<DispositionRadioSelection>(action)),
      concatLatestFrom(_action => [this.ngEntityStore.select(fromChat.getSelectedDisposition),
        this.ngEntityStore.select(fromChat.getSelectedChat)]),
      tap(([chatData, selection, chat]) => {
        const dispositionLog: DispositionLogInfo = {
          category: {
            id: selection.collectionId,
            name: selection.collectionDisplayText
          },
          reason: {
            id: selection.disposition.id,
            name: selection.disposition.displayText
          },
          notes: selection.notes
        };

        const logPayload = {
          data: {
            source: chatData.parentContainer,
            disposition: dispositionLog
          }
        };

        LogHelper.logChatEvent(this.loggingFactory, ChatOperations.DispositionSelect, chat, logPayload);
      })
    ),
    { dispatch: false }
  );

  autoCloseChat$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.AutoClose),
      concatLatestFrom(payload => this.ngEntityStore.select(getChat(payload.chatId))),
      filter(([, chat]) => Boolean(chat)),
      map(([{chatId}, chat]) => {
        if (chat.isAsync){
          // async customer closed chat scenario should close as resolved
          const closeAsyncChatArgs: CloseAsyncChat = {
            chatId,
            resolved: chat.state === ChatState.Closed,
            contactReason: null,
            isAsyncAutoClose: true
          };
          return ChatActions.CloseAsyncChat(closeAsyncChatArgs);
        }
        const closeChatArgs: CloseChat = {
          chatId,
          isAsyncAutoClose: true
        };
        return ChatActions.Close(closeChatArgs);
      })
    )
  );

  closeChat$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.Close),
      map(action => <CloseChat>toPayload(action)),
      concatLatestFrom(payload => [
        this.ngEntityStore.select(getChat(payload.chatId)),
        this.ngEntityStore.select(fromSmartResponses.getSmartResponsesByChatId(payload.chatId)),
        this.ngEntityStore.select(fromChat.selectChatIds),
        this.ngEntityStore.select(fromAgent.getMaxChats),
        this.ngEntityStore.select(fromAgentAvailability.getAvailability),
        this.ngEntityStore.select(fromCxGptResponses.getCxGptResponsesByChatId(payload.chatId)),
        this.ngEntityStore.select(hasFeatureFlag(FeatureFlags.UnifiedNotes)),
        this.ngEntityStore.select(fromAgent.getAgentNetworkId),
        this.ngEntityStore.select(fromAgent.getAgentGroups),
        this.ngEntityStore.select(fromAgent.getBusinessUnits),
        this.ngEntityStore.select(fromChat.getSummaryPayload(payload.chatId)),
        this.ngEntityStore.select(hasFeatureFlag(FeatureFlags.SendTimelineTranscript))
      ]),
      tap(([
        closeChatArgs, 
        chat, 
        smartResponses, 
        chatIds, 
        maxConcurrency, 
        availabilityChange, 
        cxGptResponses, 
        isUnifiedNotesFlagOn, 
        ntId, 
        agentGroups, 
        businessUnits,
        summaryPayload,
        sendTimelineTranscript       
      ]) => {
        const { unresolvedContactReason, dispositions, isAsyncAutoClose: isAutoClose, isAsyncPauseAbandoned } = closeChatArgs;
        if (!chat) {
          return;
        }

        if (SecureDataCollectionHelper.isDataCollectionActive(chat.secureDataCollection?.dataCollectionState)) {
          LogHelper.logChatEvent(this.loggingFactory, ChatOperations.SecureDataRequestCancel, chat, { reason: SecureDataRequestCancelReason.CloseChat });
        }

        const closeChatRequest: CloseChatRequest = {
          ...this.getCloseChatInteraction(chat, smartResponses, cxGptResponses?.cxGptResponses, agentGroups, businessUnits, ntId, sendTimelineTranscript),
          unresolvedContactReason,
          dispositions
        };

        this.handleAutoSaveSummary(closeChatRequest, chat, isUnifiedNotesFlagOn, ntId, false, isAsyncPauseAbandoned, summaryPayload);

        if (!unresolvedContactReason) {
          delete closeChatRequest.unresolvedContactReason;
        }

        let dispositionLog: DispositionLogInfo;

        if (!dispositions) {
          delete closeChatRequest.dispositions;
        }
        else{
          dispositionLog = {
            category: {
              id: dispositions.collectionId,
              name: dispositions.collectionDisplayText
            },
            reason: {
              id: dispositions.disposition?.id,
              name: dispositions.disposition?.displayText
            },
            notes: dispositions.notes ?? ''
          };
        }

        if (!chat.isAsync) {
          const logPayload = {
            disposition: dispositionLog ? dispositionLog : null,
            isResolved: true,
            isAutoClose: isAutoClose ?? false,
            engagementIds: chatIds.filter(id => chat.chatId !== id),
            maxConcurrency,
            ...(ChatHelper.getElapsedUnavailableSeconds(availabilityChange, this.agentStatePersisterService.unavailableTimestamp))
          };
          LogHelper.logChatEvent(this.loggingFactory, ChatOperations.CloseEngagement, chat, logPayload);
        }
        this.updateChatSummaryData(chat, summaryPayload);
        const hasTask = this.queueChatRequestService.hasTaskForChat(closeChatArgs.chatId, (taskType) => ChatHelper.isCloseSendTask(taskType));
        if (!hasTask){
          this.queueChatRequestService.addTask(
            () => this.chatRequestApiService.closeRequest(closeChatRequest),
            () => { },
            () => { },
            SendTaskType.CloseRequest,
            closeChatArgs.chatId
          );
        }
      })
    ),
    { dispatch: false }
  );

  exitPromptCloseEngagement$ = createEffect(() =>
    this.actions.pipe(
      ofType(UiActions.ExitPromptCloseEngagement),
      concatLatestFrom(_action => this.ngEntityStore.select(fromChat.getSelectedChat)),
      tap(([{ method }, chat]) => {
        LogHelper.logChatEvent(this.loggingFactory, ChatOperations.PromptCloseEngagementExit, chat, { method });
      })

    ),
    { dispatch: false }
  );

  closeChatAsync$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.CloseAsyncChat),
      map(action => <CloseAsyncChat>toPayload(action)),
      concatLatestFrom(payload => [
        this.ngEntityStore.select(getChat(payload.chatId)),
        this.ngEntityStore.select(fromSmartResponses.getSmartResponsesByChatId(payload.chatId)),
        this.ngEntityStore.select(fromChat.selectChatIds),
        this.ngEntityStore.select(fromAgent.getMaxChats),
        this.ngEntityStore.select(fromAgentAvailability.getAvailability),
        this.ngEntityStore.select(fromCxGptResponses.getCxGptResponsesByChatId(payload.chatId)),
        this.ngEntityStore.select(fromAgent.getAgentNetworkId),
        this.ngEntityStore.select(hasFeatureFlag(FeatureFlags.UnifiedNotes)),
        this.ngEntityStore.select(fromAgent.getAgentGroups),
        this.ngEntityStore.select(fromAgent.getBusinessUnits),
        this.ngEntityStore.select(fromChat.getSummaryPayload(payload.chatId)),
        this.ngEntityStore.select(hasFeatureFlag(FeatureFlags.SendTimelineTranscript)),
      ]),
      tap(([
        closeModel, 
        chat, 
        smartResponses, 
        chatIds,
        maxConcurrency, 
        availabilityChange, 
        cxGptResponses, 
        ntId, 
        isUnifiedNotesFlagOn, 
        agentGroups, 
        businessUnits,
        summaryPayload,
        sendTimelineTranscript       
      ]) => {
        if (!chat) {
          return;
        }
        const { dispositions, isAsyncAutoClose } = closeModel;

        if (closeModel.resolved) {
          const closeConversation: CloseConversation = {
            ...this.getCloseChatInteraction(chat, smartResponses, cxGptResponses?.cxGptResponses, agentGroups, businessUnits, ntId, sendTimelineTranscript),
            dispositions
          };
          if (!dispositions){
            delete closeConversation.dispositions;
          }

          this.handleAutoSaveSummary(closeConversation, chat, isUnifiedNotesFlagOn, ntId, false, closeModel.isAsyncPauseAbandoned, summaryPayload);
          const hasTask = this.queueChatRequestService.hasTaskForChat(closeModel.chatId, (taskType) => ChatHelper.isCloseSendTask(taskType));
          if (!hasTask){
            this.queueChatRequestService.addTask(
              () => this.chatRequestApiService.closeConversation(closeConversation),
              () => { },
              () => { },
              SendTaskType.CloseConversation,
              closeModel.chatId
            );
          }
          this.updateChatSummaryData(chat, summaryPayload);
        }
        else {
          const closeChat: CloseChat = {
            chatId: closeModel.chatId,
            unresolvedContactReason: closeModel.contactReason,
            dispositions: dispositions,
            isAsyncAutoClose
          };
          if (!dispositions){
            delete closeChat.dispositions;
          }
          this.ngEntityStore.dispatch(ChatActions.Close(closeChat));
        }
        const logDimensions = {
          isResolved: closeModel.resolved,
          summary: closeModel.contactReason,
          isAutoClose: isAsyncAutoClose ?? false,
          engagementIds: chatIds.filter(id => chat.chatId !== id),
          maxConcurrency,
          ...(ChatHelper.getElapsedUnavailableSeconds(availabilityChange, this.agentStatePersisterService.unavailableTimestamp))
        };
        LogHelper.logChatEvent(this.loggingFactory, ChatOperations.CloseEngagement, chat, logDimensions);
      })
    ),
    { dispatch: false }
  );

  chatClosed$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.Closed),
      map(action => <Chat>toPayload(action)),
      concatMap(action =>
        of(action).pipe(
          withLatestFrom(this.ngEntityStore.select(fromChat.getChats))
        )
      ),
      tap(() => {
        const loadAgentAnalyticsPayload: LoadAgentAnalytics = {
          source: 'automatic'
        };

        this.ngEntityStore.dispatch(AgentActions.LoadAgentAnalytics(loadAgentAnalyticsPayload));
      })

    ),
    { dispatch: false }
  );

  logAgentUnusedSlotTimes$ = createEffect(() =>
  this.actions.pipe(
    ofType(ChatActions.Transferred, ChatActions.Closed),
    concatLatestFrom(() => [
    this.ngEntityStore.select(fromChat.selectChatIds),
    this.ngEntityStore.select(fromAgent.getMaxChats),
    this.ngEntityStore.select(fromAgent.getunusedSlotTimes),
    this.ngEntityStore.select(fromAgentAvailability.getAvailability)]),
    tap(([payload, chatIds, maxChats, unusedSlotTimes, agentAvailability]) => {    
      chatIds = chatIds.filter(c => c !== payload?.payload?.chatId);
      this.updateAgentUnusedSlotTimes(chatIds, maxChats, agentAvailability?.available === AvailabilityType.Available, unusedSlotTimes);
      })
    ),
    { dispatch: false }
  );

  reconnectOneCti$ = createEffect(() =>
  this.actions.pipe(
    ofType(ChatActions.Transferred, ChatActions.Closed),
    concatLatestFrom(() => [
      this.ngEntityStore.select(fromOneCti.isOneCtiConnected)]),
    filter(([, oneCtiConnected]) => !oneCtiConnected),
    tap(() => {
        this.ngEntityStore.dispatch(OneCtiActions.ReconnectOneCti());
      })
    ),
    { dispatch: false }
  );

  getTransferOptions$ = createEffect(() =>
    this.actions.pipe(
      ofType(UiActions.GetTransferOptions, UiActions.RefreshTransferOptions),
      concatLatestFrom(_action => this.ngEntityStore.select(fromChat.getSelectedChat)),
      tap(([{type}, chat]) => {
        if (type === UiActions.RefreshTransferOptions.type){
          LogHelper.logChatEvent(this.loggingFactory, ChatOperations.RefreshTransferOptions, chat);
        }
        const model: GetTransferOptionsRequest = {
          chatId: chat.chatId,
          isAsync: Boolean(chat.isAsync)
        };
        this.chatRequestApiService.getTransferOptions(model);
      })
    ),
    { dispatch: false }
  );

  transferChat$ = createEffect(() =>
  this.actions.pipe(
    ofType(ChatActions.Transfer),
    concatLatestFrom((transferData) => [this.ngEntityStore.select(fromChat.getChat(transferData.payload.chatId)),
      this.ngEntityStore.select(fromSmartResponses.getSmartResponsesByChatId(transferData.payload.chatId)),
      this.ngEntityStore.select(fromPlaceholders.getAllPlaceholdersForChatId(transferData.payload.chatId)),
      this.ngEntityStore.select(fromChat.getItgsInProgress(transferData.payload.chatId)),
      this.ngEntityStore.select(hasFeatureFlag(FeatureFlags.UnifiedNotes)),
      this.ngEntityStore.select(fromAgent.getAgentNetworkId),
      this.ngEntityStore.select(fromChat.getSummaryPayload(transferData.payload.chatId))
    ]),
    tap(([transfer, chat, smartResponses, allPlaceHolders, itgsInProgress, isUnifiedNotesFlagOn, ntId, summaryPayload]) => {
        const transferChatRequest: TransferChatRequest = {
          ...transfer.payload,
          conversationId: chat.conversationId,
          isAsync: Boolean(chat.isAsync),
          secureDataRequestsData: {
            secureDataRequests: chat.secureDataRequests
          },
          xaSuggestionsData: {
            xaSuggestions: chat.suggestions,
            xaSuggestionsSessionId: chat.xaSuggestionsSessionId
          },
          smartResponsesData: {
            smartResponses: smartResponses,
            smartResponsesSent: chat.smartResponsesSent,
            placeholders : this.getSmartResponsePlaceholders(smartResponses, allPlaceHolders)
          },
          itgData: {
            itgsInProgress: itgsInProgress ?? []
          },
          agentsFeatureFlagsData: {
            agentsFeatureFlags: chat.agentsFeatureFlags
          },
          transcriptEventsData: {
            events: chat.events
          },
          previousQueueName: chat.queueName
        };

        this.handleAutoSaveSummary(transferChatRequest, chat, isUnifiedNotesFlagOn, ntId, true, false, summaryPayload);

        this.updateChatSummaryData(chat, summaryPayload);
        this.chatRequestApiService.transferChat(transferChatRequest);

        if (SecureDataCollectionHelper.isDataCollectionActive(chat.secureDataCollection?.dataCollectionState)) {
          LogHelper.logChatEvent(this.loggingFactory, ChatOperations.SecureDataRequestCancel, chat, { reason: SecureDataRequestCancelReason.TransferChat });
        }
      })
    ),
    { dispatch: false }
  );

  transferFailed$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.TransferFailed),
      map(action => toPayload<Chat>(action)),
      concatLatestFrom(_action => this.ngEntityStore.select(getIsLockedOut)),
      tap(([chat, isLockedOut]) => {
        if (isLockedOut) {
          // if agent is being locked out and chat transfer fails, close chat
            const closeChat: CloseChat = {
              chatId: chat.chatId,
              unresolvedContactReason: ''
            };

            this.ngEntityStore.dispatch(ChatActions.Close(closeChat));
            LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.SessionLockoutClose, { engagementId: chat.chatId });
          } else {
            this.ngEntityStore.dispatch(UiActions.GetTransferOptions());
          }
      })
    ),
    { dispatch: false }
  );

  bounceChat$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.Bounce),
      map(action => toPayload<string>(action)),
      concatMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.ngEntityStore.select(fromChat.getChats)
          )
        )
      ),
      tap(([chatId, allChats]) => {
        const toBounce = allChats.find(x => x.chatId === chatId);
        if (toBounce) {
          const bounceParams: BounceChat = {
            chatId: chatId,
            businessUnitId: toBounce.businessUnitId,
            agentGroupId: toBounce.agentGroupId
          };

          this.chatRequestApiService.bounceChat(bounceParams);
          this.ngEntityStore.dispatch(AgentActions.UpdateBounceChatId({ chatId }));
          this.ngEntityStore.dispatch(
            AgentAvailabilityActions.updateAvailability(
              { available: AvailabilityType.Unavailable, reason: 'Busy', timestamp: Date.now() }
            )
          );
        }
      })
    ),
    { dispatch: false }
  );

  getPriorEngagements$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.GetPriorEngagements),
      map(action => toPayload<GetPriorEngagementsActionData>(action)),
      concatLatestFrom(_action => [this.ngEntityStore.select(getPaginationData),
        this.ngEntityStore.select(hasFeatureFlag(FeatureFlags.LazyLoadPriorEngagements))]
      ),
      tap(([engagementData, paginationData, isLazyLoadEnabled]) => {
        const { chat, loadNextPage } = engagementData;
        const { pageNumber, pageSize } = paginationData;

        let requestPageIndex = null;
        let requestPageSize = null;

        if (isLazyLoadEnabled){
          requestPageIndex =  loadNextPage ? pageNumber : 1;
          requestPageSize = loadNextPage ? pageSize : Math.min(pageNumber, PriorEngagementConstants.initialMaxPagesLoaded) * pageSize;
        }

        if (chat.accountNumber) {
          const getPriorEngagements: GetPriorEngagements = {
            chatId: chat.chatId,
            accountNumber: chat.accountNumber,
            conversationId: chat.isAsync ? chat.conversationId : '',
            pageIndex: requestPageIndex,
            pageSize: requestPageSize
          };

          this.chatRequestApiService.getPriorEngagements(getPriorEngagements).catch(() => {
            this.ngEntityStore.dispatch(ChatActions.ChatLoadComplete(chat.chatId));
          });
        }
        else {
        }
      })
    ),
    { dispatch: false }
  );

  setPriorEngagements$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.SetPriorEngagements),
      map(action => toPayload<SetPriorEngagements>(action)),
      concatMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.ngEntityStore.select(fromChat.getChats),
            this.ngEntityStore.select(hasFeatureFlag(FeatureFlags.LazyLoadPriorEngagements)),
            this.ngEntityStore.select(getPriorEngagementsLoadingData(action.chatId)),
            this.ngEntityStore.select(getPriorEngagementsPageSize)
          )
        )
      ),
      tap(([setPriorEngagements, allChats, isLazyLoadEnabled, priorEngagementsLoadingData, pageSize]) => {
        const chat = allChats.find(c => c.chatId === setPriorEngagements.chatId);
        if (chat?.accountNumber) {
          const logDimensions = {
            isFirstLoad: chat.isFirstLoad
          };
          LogHelper.logChatEvent(this.loggingFactory, ChatOperations.ChatLoadSuccess, chat, logDimensions);

          if (chat.isFirstLoad) {
            this.ngEntityStore.dispatch(ChatActions.ChatFirstLoadSuccess(setPriorEngagements.chatId));
          }

          if (isLazyLoadEnabled && priorEngagementsLoadingData?.fetchTimestamp) {
            const lazyLoadLogDimensions = {
              pageNumber: priorEngagementsLoadingData.pageNumber,
              pageSize: pageSize,
              totalRecordsLoaded: chat.engagementHistory.length,
              elapsedTime: this.timeService.unix() - priorEngagementsLoadingData.fetchTimestamp
            };

            LogHelper.logChatEvent(this.loggingFactory, ChatOperations.LoadPriorEngagements, chat, lazyLoadLogDimensions);
          }
        }
      })
    ),
    { dispatch: false }
  );

  priorEngagementAccordionChange$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.PriorEngagementAccordionChange),
      map(action => toPayload<PriorEngagementAccordionChange>(action)),
      concatLatestFrom(_action => this.ngEntityStore.select(fromChat.getSelectedChat)),
      tap(([priorEngagementAccordionChange, selectedActiveChat]) => {
        if (priorEngagementAccordionChange.isActive) {
          // Log event
          const logDimensions: any = { isAsync: selectedActiveChat.isAsync };
          if (!priorEngagementAccordionChange.priorEngagement.isAsync) {
            logDimensions.NextEngagementId = priorEngagementAccordionChange.priorEngagement.id;
          } else {
            logDimensions.NextConversationId = priorEngagementAccordionChange.priorEngagement.id;
          }

          LogHelper.logChatEvent(this.loggingFactory, ChatOperations.ViewHistoricTranscript, selectedActiveChat, logDimensions);
        }
      })
    ),
    { dispatch: false }
  );

  asyncEngagementSelected = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.AsyncEngagementSelected),
      map(action => toPayload<SelectedHistoryEngagement>(action)),
      concatMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.ngEntityStore.select(fromChat.getSelectedChat)
          )
        )
      ),
      tap(([priorEngagementSelected, selectedActiveChat]) => {
        const logDimensions = {
          isAsync: selectedActiveChat.isAsync,
          NextEngagementId: priorEngagementSelected.priorEngagementId
        };

        LogHelper.logChatEvent(this.loggingFactory, ChatOperations.JumpToEngagement, selectedActiveChat, logDimensions);
      })
    ),
    { dispatch: false }
  );

  getCustomerInformation$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.GetCustomerInformation),
      map(action => toPayload<string>(action)),
      tap((chatId) => {
        this.chatRequestApiService.getCustomerInformation({ chatId: chatId });
      })
    ),
    { dispatch: false }
  );

  getEngagementById$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.GetEngagementById),
      map(action => toPayload<GetEngagementById>(action)),
      tap((model: GetEngagementById) => {
        this.chatRequestApiService.GetEngagementById(model);
      })
    ),
    { dispatch: false }
  );

  getChatAccountLocality$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.GetChatAccountLocality),
      map(action => toPayload<GetChatAccountLocality>(action)),
      tap((model: GetChatAccountLocality) => {
        this.customerInfoService.getChatAccountLocality(model)
          .then((response: HttpResponse<GetChatAccountLocalityResponse>) => {
            if (response && response.ok && response.body && response.body.localityInfo) {
              const locality = new ChatAccountLocality();
              locality.chatId = model.chatId;
              locality.timeZone = {
                name: response.body.localityInfo.name,
                offset: response.body.localityInfo.timeZoneOffset
              };
              this.ngEntityStore.dispatch(ChatActions.SetChatAccountLocality(locality));
            }
          });
      })
    ),
    { dispatch: false }
  );

  reportImage$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.ReportImage),
      map(action => toPayload<ChatImageMessage>(action)),
      tap((model: ChatImageMessage) => {
        const reportImageModel: ReportImage = {
          chatId: model.chatId,
          retrievalUrl: model.retrievalUrl
        };
        this.chatRequestApiService.reportImage(reportImageModel);
      })
    ),
    { dispatch: false }
  );

  getAccountUsers$ = createEffect(() =>
    this.actions.pipe(
      ofType(ConnectAccountActions.GetChatAccountUsers),
      map(action => toPayload<GetChatAccountUsers>(action)),
      tap((model: GetChatAccountUsers) => {
        this.customerInfoService.getChatAccountUsers(model)
          .then((response: HttpResponse<AccountUsersResponse>) => {
            if (response?.body) {
              const accountUsersResponse = new AccountUsersResponse();
              accountUsersResponse.accountNumber = model.accountNumber;
              accountUsersResponse.chatId = response.body.chatId;
              if (response.ok) {
                accountUsersResponse.accountUsers = response.body.accountUsers;
                accountUsersResponse.isValidAccount = response.body.isValidAccount;
              }
              else {
                accountUsersResponse.isValidAccount = false;
              }
              this.ngEntityStore.dispatch(ConnectAccountActions.UpdateAccountUsersResponse(accountUsersResponse));
            }
          });
      })
    ),
    { dispatch: false }
  );

  updateAccountNumber$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.UpdateAccountNumber),
      map(action => toPayload<UpdateAccountNumber>(action)),
      concatMap(action =>
        of(action).pipe(
          withLatestFrom(this.ngEntityStore.select(fromChat.getChats))
        )
      ),
      tap(([updateAccountNumber, chats]) => {
        const chat = chats.find(c => c.chatId === updateAccountNumber.chatId);
        if (chat) {
          const engagementArgs: SetPriorEngagements = {
            chatId: updateAccountNumber.chatId,
            totalEngagements: 0,
            engagements: [],
            clearEngagements: true
          };
          this.ngEntityStore.dispatch(ChatActions.SetPriorEngagements(engagementArgs));

          const updateAccountNumberRequest: UpdateAccountNumberRequest = {
            ...updateAccountNumber,
            nuanceCustomerId: chat.nuanceCustomerId,
            siteId: chat.siteId,
            customerGuid: chat.customerGuid,
            conversationId: chat.conversationId
          };
          this.chatRequestApiService.updateAccountNumber(updateAccountNumberRequest);
        }
      })
    ),
    { dispatch: false }
  );

  updateAccountInformation$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.UpdateAccountInformation),
      map(action => toPayload<UpdateAccountInformation>(action)),
      concatMap(action =>
        of(action).pipe(
          withLatestFrom(this.ngEntityStore.select(fromChat.getChats))
        )
      ),
      tap(([updateAccountArgs, allChats]) => {
        if (updateAccountArgs.isAccountConnected || updateAccountArgs.authenticated) {
          const targetChat = allChats.find(c => c.chatId === updateAccountArgs.chatId);
          if (targetChat) {
            const logDimensions = {
              accountNumber: updateAccountArgs.accountNumber,
              isManual: !updateAccountArgs.authenticated
            };
            this.loadLocalityAndPriorEngagements(targetChat);
            LogHelper.logChatEvent(this.loggingFactory, ChatOperations.LinkAccount, targetChat, logDimensions);
          }
        }
      })
    ),
    { dispatch: false }
  );

  chatLoadComplete$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.ChatLoadComplete),
      map(action => toPayload<string>(action)),
      concatMap(action =>
        of(action).pipe(
          withLatestFrom(this.ngEntityStore.select(fromChat.getChats))
        )
      ),
      tap(([chatLoadCompleteArgs, allChats]) => {
        const targetChat = allChats.find(c => c.chatId === chatLoadCompleteArgs);
        if (targetChat) {
          this.chatPersisterService.storeEntity(targetChat);
        }
      })
    ),
    { dispatch: false }
  );

  sendAgentTyping$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatUiActions.agentTypingStarted, ChatUiActions.agentTypingStopped),
      tap(({ type, chatId }) => {
        const state = type === ChatUiActions.agentTypingStarted.type ?
          TypingStatus.StartsTyping :
          TypingStatus.StopsTyping;
        const request: SendAgentTypingStatus = {chatId, state};
        this.chatRequestApiService.sendAgentTypingStatus(request);
      })
    ),
    { dispatch: false }
  );

  newChatMessage$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.NewMessage),
      map(action => toPayload<ChatInteraction>(action)),
      concatLatestFrom(action => [
        this.ngEntityStore.select(getChatCustomerActivityStatus(action.chatId)),
        this.ngEntityStore.select(getChatMessages(action.chatId))]),
      filter(([chatMessage]) => {
        return chatMessage.sender === SenderType.Requester || 
        (<SystemChatMessage>chatMessage).systemInfoMessageType === SystemInfoMessageType.PageNavigated ||
        (<SystemChatMessage>chatMessage).systemInfoMessageType === SystemInfoMessageType.PageMarker;
      }),
      tap(([action, lastStatus, messages]) => {
        this.updateCustomerActivityStatusActive(action.chatId, lastStatus, messages);
      })
    ),
    { dispatch: false }
  );

  newChatMessageForSelectedChat$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.NewMessage),
      map(action => toPayload<ChatInteraction>(action)),
      concatLatestFrom(_ => this.ngEntityStore.select(fromChat.getSelectedChatUi)),
      filter(([chatMessage, chatUi]) => chatUi?.chatId && chatMessage.chatId === chatUi.chatId),
      map(([chatMessage]) => {
        // new messages received on the currently selected chat should be considered read so updating lastViewedTimestamp
        const { chatId, timestamp: lastViewedTimestamp } = chatMessage;
        return ChatUiActions.chatViewed({ chatId, lastViewedTimestamp });
      })
    )
  );

  typingStatusChanged$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatUiActions.customerTypingStatusChanged),
      concatLatestFrom(({ customerTypingStatus: { chatId } }) => [
        this.ngEntityStore.select(fromChat.getChat(chatId)),
        this.ngEntityStore.select(fromChat.getChatCustomerActivityStatus(chatId))
      ]),
      tap(([{ customerTypingStatus }, chat, customerActivityStatus]) => {
        if (!chat) {
          const event: ChatNotFoundLog = {
            action: ChatUiActions.customerTypingStatusChanged.type,
            engagementId: customerTypingStatus.chatId
          };
          LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.ChatNotFound, event);
          return;
        }

        if (customerTypingStatus.isCustomerTyping){
          this.updateCustomerActivityStatusActive(customerTypingStatus.chatId, customerActivityStatus, chat.messages);
        }
      })
    ),
    { dispatch: false }
  );

  // if we don't get a customerTypingStatusChanged action w/in CustomerTypingStatusTimeoutInSeconds
  // then clear the customer type status because we might not know about a customer disconnect for 3 minutes
  customerTypingStatusTimeout$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatUiActions.customerTypingStatusChanged),
      filter(({ customerTypingStatus }) => customerTypingStatus.isCustomerTyping),
      mergeMap((action) => {
        return timer(CustomerTypingStatusTimeoutInSeconds * 1000).pipe(
          mapTo(action),
          concatLatestFrom(({ customerTypingStatus: { chatId } }) => this.ngEntityStore.select(fromChat.getChat(chatId))),
          filter(([, chat]) => Boolean(chat)),
          tap(([{ customerTypingStatus }, _]) => {
            const { chatId } = customerTypingStatus;
            this.ngEntityStore.dispatch(ChatUiActions.customerTypingStatusExpired({ chatId }));
          }),
          // stop the timeout to when another action for a given chatId occurs
          takeUntil(this.actions.pipe(
            ofType(ChatUiActions.customerTypingStatusChanged),
            filter(a => a.customerTypingStatus.chatId === action.customerTypingStatus.chatId)
          ))
        );
      }),
    ),
    { dispatch: false }
  );

  // if the chat hub is not connected, do not move to the next status or perform any actions
  nextCustomerActivityStatus$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatUiActions.nextCustomerActivityStatus),
      concatLatestFrom(({chatId}) => [
        this.ngEntityStore.select(fromChat.getChat(chatId)),
        this.ngEntityStore.select(fromHubs.getChatRequestHubConnectionState),
        this.ngEntityStore.select(fromChat.getChatAbandonedChatAutomationDetails(chatId)),
        this.ngEntityStore.select(fromChat.isAbandonedChatAutomationDisabled(chatId))
      ]),
      filter(([, chat, chatHubConnection, , isAcaDisabled]) =>
        this.isHubStateConnected(chatHubConnection) && chat && !isAcaDisabled
      ),
      tap(([{chatId}, chat, , chatAutomationDetails]) => {
        const { automationSettings, customerActivityStatus, warningIndex } = chatAutomationDetails;
        if (!automationSettings || chat.state === ChatState.Disconnected || chat.state === ChatState.Closed) { return; }
        const nextStatus: CustomerActivityStatus = this.getNextCustomerActivityStatus(chatAutomationDetails);
        this.chatAutomationAction(chatAutomationDetails, chat);
        if (nextStatus !== customerActivityStatus) {
          const stopTimer = nextStatus === CustomerActivityStatus.Active ?
            AbandonedChatAutomationHelper.getLastMessageSender(chat.messages) === SenderType.Requester : false;

          this.ngEntityStore.dispatch(ChatUiActions.updateCustomerActivityStatus({
            chatId,
            status: nextStatus,
            lastStatus: customerActivityStatus,
            stopTimer,
            currentTime: this.timeService.unix()
          }));
        }
        else if (nextStatus === CustomerActivityStatus.InactiveWarning && customerActivityStatus === CustomerActivityStatus.InactiveWarning) {
          // staying at inactive warning need to increase warning index
          this.ngEntityStore.dispatch(ChatUiActions.updateAutomationWarningIndex({
            chatId: chatId,
            index: warningIndex + 1,
            currentTime: this.timeService.unix()
          }));
        }
      })
    ),
    { dispatch: false }
  );

  updateCustomerActivityStatus$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatUiActions.updateCustomerActivityStatus),
      concatLatestFrom((action) => [
        this.ngEntityStore.select(fromChat.getChat(action.chatId)),
        this.ngEntityStore.select(fromChat.getChatAbandonedChatAutomationDetails(action.chatId))
      ]),
      tap(([updateCustomerActivity, chat, chatAutomationDetails]) => {
        if (chat) {
          this.chatPersisterService.storeEntity(chat);
          // UpdateCustomerActivityStatus can be fired at active to go back to active with a new expiry value for the timer when it needs to be reset or stopped
          // don't log if we go from active to active
          if (updateCustomerActivity.status === CustomerActivityStatus.Active && updateCustomerActivity.lastStatus === CustomerActivityStatus.Active) { return; }
          this.logAutomationAction(chat, updateCustomerActivity, chatAutomationDetails);
        }
      })),
    { dispatch: false }
  );


  chatAutomationPinClicked$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatUiActions.chatAutomationPinClicked),
      concatLatestFrom((action) => [
        this.ngEntityStore.select(fromChat.getChat(action.chatId)),
        this.ngEntityStore.select(fromChat.getChatAbandonedChatAutomationDetails(action.chatId))
      ]),
      tap(([{chatId, isPinned, pinnedElapsed}, chat, chatAutomationDetails]) => {
        const status = isPinned ? CustomerActivityStatus.Pinned : CustomerActivityStatus.Active;
        const stopTimer = isPinned ? false : AbandonedChatAutomationHelper.getLastMessageSender(chat.messages) === SenderType.Requester;
        this.ngEntityStore.dispatch(ChatUiActions.updateCustomerActivityStatus({
          chatId,
          status,
          lastStatus: chatAutomationDetails.customerActivityStatus,
          stopTimer,
          currentTime: this.timeService.unix()
        }));

        if (!isPinned){
          const logUnpinned: LogCustomerActivityStatus = {
            chatId: chatId,
            status: CustomerActivityStatus.Unpinned
          };
          this.logAutomationAction(chat, logUnpinned, chatAutomationDetails, {manualUnpin: true, pinnedElapsed});
        }
      })),
    { dispatch: false }
  );

  getAsyncEngagements$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.GetAsyncEngagements),
      map(action => toPayload<GetAsyncEngagements>(action)),
      tap((getAsyncEngagements: GetAsyncEngagements) => {
        this.chatRequestApiService.getAsyncEngagements(getAsyncEngagements);
      })
    ),
    { dispatch: false }
  );

  updateAsyncEngagements$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.UpdateAsyncEngagements),
      concatLatestFrom(() => this.ngEntityStore.select(fromChat.getChats)),
      tap(([{updateAsyncEngagements}, allChats]) => {
        const {chatId, unresolvedContactReason, engagements} = updateAsyncEngagements;
        const targetChat = allChats.find(c => c.chatId === chatId);

        const logDimensions = Object.assign({},
          { numberOfEngagements: targetChat.asyncEngagements.length + 1 },
          this.getAgentIds(targetChat.asyncEngagements)
        );

        // Log NewEngagement
        LogHelper.logChatEvent(this.loggingFactory, ChatOperations.LoadAsyncPreviousEngagements, targetChat, logDimensions);
        
        // when there is no unresolvedContactReason, get the summary to show on the card
        if (!unresolvedContactReason && engagements.length > 0){
          this.ngEntityStore.dispatch(UiActions.GetChatSummaryData({chatId, isAsyncEngagementsRequest: true, traceId: Guid.raw()}));
        }
      })
    ),
    { dispatch: false }
  );

  joinChats$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.JoinChats),
      filter(({chatIds}) => chatIds && chatIds.length > 0),
      tap(({chatIds}) => {
        const joinChats: JoinChats = {
          chatIds
        };
        this.chatRequestApiService.joinChats(joinChats).then((response: HubResponse<JoinChatsResponse>) => {
          const successChatIds = response.body?.successChatIds;
          const failedChatIds = response.body?.failedChatIds;
          this.ngEntityStore.dispatch(ChatActions.JoinChatsResponse({successChatIds, failedChatIds}));
        });
      })
    ),
    { dispatch: false }
  );

  joinChatsResponse$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.JoinChatsResponse),
      tap(({successChatIds, failedChatIds}) => {
        successChatIds?.forEach(chatId => {
          this.ngEntityStore.dispatch(ChatActions.GetActiveChatTranscript(chatId));
        });
        failedChatIds?.forEach(chatId => {
          this.ngEntityStore.dispatch(ChatActions.JoinChatFailed({chatId}));
        });
      })
    ),
    {dispatch: false}
  );

  joinChatFailed$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.JoinChatFailed),
      concatLatestFrom(({chatId}) => this.ngEntityStore.select(getChat(chatId))),
      tap(([, chat]) => {
        LogHelper.logChatEvent(this.loggingFactory, ChatOperations.JoinChatFailed, chat);
        if (chat){
          this.ngEntityStore.dispatch(ChatActions.Closed(chat));
        }
      })
    ),
    { dispatch: false }
  );

  getActiveChatTranscript$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.GetActiveChatTranscript),
      map(action => toPayload<string>(action)),
      tap((chatId: string) => {
        const getActiveChatTranscript: GetActiveChatTranscript = {
          chatId: chatId
        };
        this.chatRequestApiService.getActiveChatTranscript(getActiveChatTranscript);
      })
    ),
    { dispatch: false }
  );

  maskText$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.MaskText),
      concatLatestFrom(_ => this.ngEntityStore.select(fromChat.getSelectedChat)),
      tap(([{maskText}, selectedChat]) => {
        const {chatId, maskedChatLine, chatMessage, traceId } = maskText;
        const {timestamp, message} = chatMessage;
        // previousMaskedChatLine is the original message that was sent, doesn't matter how many times the message has been masked
        // the message must be unescaped -- the message is unescaped from Nuance, but is escaped in our middleware for security purposes
        const maskTextRequest: MaskTextRequest = {
          chatId,
          maskedChatLine: maskedChatLine,
          previousMaskedChatLine: this.htmlHelper.UnescapeHtml(message),
          timestamp,
          traceId
        };

        this.chatRequestApiService.maskText(maskTextRequest).then((response) => {
          if (response.success){
            this.ngEntityStore.dispatch(ChatActions.TextMasked({maskText, messageId: response.body.messageId}));
          }
          else{
            this.ngEntityStore.dispatch(ChatActions.MaskTextFailed({maskText}));
          }
        }).catch(() => {
          this.ngEntityStore.dispatch(ChatActions.MaskTextFailed({maskText}));
        });

        if (selectedChat) {
          this.chatPersisterService.storeEntity(selectedChat);
        }
        LogHelper.logChatEvent(this.loggingFactory, ChatOperations.MaskText, selectedChat);
      })
    ),
    { dispatch: false }
  );

  unlinkAccount$ = createEffect(() =>
    this.actions.pipe(
      ofType(ConnectAccountActions.Unlink),
      concatMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.ngEntityStore.select(fromChat.getSelectedChat)
          )
        )
      ),
      tap(([, chat]) => {
        const customer = ChatHelper.getCustomerUser(chat);
        if (customer?.displayName && chat.isAccountConnected && chat.accountNumber) {
          const unlinkAccount: UnlinkAccount = {
            siteId: chat.siteId,
            nuanceCustomerId: chat.nuanceCustomerId,
            chatId: chat.chatId,
            displayName: customer.displayName,
            accountNumber: chat.accountNumber
          };
          this.chatRequestApiService.unlinkAccount(unlinkAccount);
        }
      })
    ),
    { dispatch: false }
  );

  markAsMercuryChat$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.MarkAsMercuryChat),
      map(action => toPayload<MarkAsMercuryChatRequest>(action)),
      tap((mercuryChat) => {
          this.chatRequestApiService.MarkAsMercuryChat(mercuryChat);
      })
    ),
    { dispatch: false }
  );

  copyText$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.CopyText),
      map(action => toPayload<string>(action)),
      concatMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.ngEntityStore.select(fromChat.getSelectedChat)
          )
        )
      ),
      tap(([copyText, selectedChat]) => {
        const logDimensions = {
          copiedText: copyText,
        };

        LogHelper.logChatEvent(this.loggingFactory, ChatOperations.CopyText, selectedChat, logDimensions);
      })
    ),
    { dispatch: false }
  );

  sendSuggestion$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.SendSuggestion),
      concatLatestFrom(({payload}) => [
        this.ngEntityStore.select(fromChat.getChats),
        this.ngEntityStore.select(getChatCustomerActivityStatus(payload.chatId)),
        this.ngEntityStore.select(getChatMessage(payload.chatId, payload.traceId)),
        this.ngEntityStore.select(hasFeatureFlag(FeatureFlags.LanguageTranslator)),
        this.ngEntityStore.select(fromChat.getTranslationLanguage(payload.chatId)),
        this.ngEntityStore.select(hasFeatureFlag(FeatureFlags.PreventAsyncInactiveReset))
      ]),
      tap(([sendSuggestion, chats, lastActivityStatus, chatMessage, isTranslationFlagOn, translationLanguage, asyncInactiveFeatureFlagEnabled]) => {
        const suggestion = sendSuggestion.payload;
        const chat = chats.find(c => c.chatId === suggestion.chatId);

        // Logging
        switch (suggestion.suggestionType) {
          case SuggestionType.External: {
            const logDimensions: SendExternalSuggestionLog = {
              requestId: suggestion.queryId,
              message: suggestion.body.message,
              isModified: suggestion.isModified
            };
            LogHelper.logChatEvent(this.loggingFactory, ChatOperations.SendExternalSuggestion, chat, logDimensions);

            if (suggestion.title === DataCollectionSuggestionTitles.linkAvailable) {
              LogHelper.logChatEvent(this.loggingFactory, ChatOperations.SecureDataRequestSendLink, chat, { isModified: suggestion.isModified });
            }
            break;
          }
          case SuggestionType.Greeting: {
            const logDimensions: SendGreetingSuggestionLog = {
              isModified: suggestion.isModified,
              messageText: suggestion.body.message
            };
            LogHelper.logChatEvent(this.loggingFactory, ChatOperations.SendChatStartGreeting, chat, logDimensions);
            break;
          }
          case SuggestionType.CustomerAwaitingReply: {
            const logDimensions = {
              isModified: suggestion.isModified,
              message: suggestion.body.message
            };
            LogHelper.logChatEvent(this.loggingFactory, ChatOperations.SendCustomerAwaitingReplySuggestion, chat, logDimensions);
            break;
          }
          case SuggestionType.ItgIntroduction: {
            const logDimensions: SendItgPrimerLog = {
              intent: suggestion.intent
            };
            LogHelper.logChatEvent(this.loggingFactory, ChatOperations.SendItgPrimer, chat, logDimensions);
            break;
          }
          default: {
            const logDimensions: SendSuggestionLog = {
            queryId: suggestion.queryId,
            intent: suggestion.intent,
            message: suggestion.body.message,
            source: suggestion.source,
            isModified: suggestion.isModified,
            sendCustomerButtons: Boolean(suggestion.body?.buttons?.length && !suggestion.removeCustomerButtons)
          };

          LogHelper.logChatEvent(this.loggingFactory, ChatOperations.SendSuggestion, chat, logDimensions);
          }
        }

        // Send Suggestion
        switch (suggestion.suggestionType) {
          case SuggestionType.External:
          case SuggestionType.Greeting:
          case SuggestionType.ItgIntroduction:
          case SuggestionType.CustomerAwaitingReply: {
            const isTranslatedChat = ChatHelper.shouldTranslateMessages(translationLanguage, isTranslationFlagOn, chat.isTranslationConfigEnabled);

            const msg: SendChatMessage = {
              chatId: chat.chatId,
              message: suggestion.body.message,
              timestamp: suggestion.timestamp,
              sender: SenderType.FixAgent,
              type: ChatMessageType.Message,
              sendMessageType: SendMessageType.Script,
              scriptTreeId: chat.scriptTreeId,
              traceId: suggestion.traceId,
              status: ChatMessageStatus.Pending,
              translateTo: isTranslatedChat ? translationLanguage : null
            };
            
            if (isTranslatedChat) {
              LogHelper.logTranslationRequest(this.loggingFactory, chat, TranslatedMessageType.Outbound, false);
            }
            this._logAgentFirstResponseSendMessage(msg, lastActivityStatus, chat, suggestion.suggestionType, asyncInactiveFeatureFlagEnabled);
            break;
          }
          default: {
            this.sendXaSuggestion(suggestion, chatMessage);
          }
        }
      })
    ),
    { dispatch: false }
  );

  retrySendXaSuggestion$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.retrySendXaSuggestion),
      concatLatestFrom(({ chatMessage, newTraceId }) => [
        this.ngEntityStore.select(getChatMessage(chatMessage.chatId, newTraceId)),
      ]
        ),
      tap(([{ chatMessage, newTraceId }, chatInteraction]) => {
        const sendXaSuggestion: SendSuggestion = {
          ...chatMessage,
          traceId: newTraceId
        };
        this.sendXaSuggestion(sendXaSuggestion, chatInteraction);
      })
    ),
    { dispatch: false }
  );

  dismissSuggestion$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.DismissLatestSuggestions),
      concatLatestFrom(_action => this.ngEntityStore.select(fromChat.getChats)),
      tap(([suggestion, chats]) => {
        const chat = chats.find(c => c.chatId === suggestion.sendSuggestion.chatId);

        if (suggestion.sendSuggestion.title === DataCollectionSuggestionTitles.linkAvailable) {
          this.ngEntityStore.dispatch(ChatActions.CancelDataCollectionRequest({ chatId: chat.chatId }));
        }

        LogHelper.logDismissedSuggestion(this.loggingFactory, chat, suggestion.sendSuggestion, suggestion.isIgnored);
      })
    ),
    { dispatch: false }
  );

  getXaSuggestionsFromMessage$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.NewMessage),
      map(action => toPayload<ChatInteraction>(action)),
      concatLatestFrom((chatMessage) => [
        this.ngEntityStore.select(fromChat.getChat(chatMessage.chatId))
      ]),
      filter(([message, chat]) => {
        return message.sender === SenderType.Requester &&
          (message.type === ChatMessageType.Message || message.type === ChatMessageType.TopicSelection) &&
          chat?.channel.type !== ChannelType.Sms ;
      }),
      tap(([interaction]) => {
        const getXaSuggestionsMessage = this.createGetXaSuggestionsMessageModel(interaction as ChatMessage);

        this.ngEntityStore.dispatch(ChatActions.GetXaSuggestions({ chatId: interaction.chatId, message: getXaSuggestionsMessage }));
      })
    ),
    { dispatch: false }
  );

  // two ITGs cannot be running or paused simultaneously
  // when one is started, if another itg is running or paused it must be canceled
  // so we only need to check if there isn't an itg paused to send getXaSuggestions
  getXaSuggestions$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.GetXaSuggestions),
      concatLatestFrom(({chatId}) => [
        this.ngEntityStore.select(fromChat.getChat(chatId)),
        this.ngEntityStore.select(fromSettings.hasFeatureFlag(FeatureFlags.XaResponse)),
        this.ngEntityStore.select(fromChat.getIsItgPaused(chatId)),
        this.ngEntityStore.select(fromChat.getIsItgRunning(chatId)),
        this.ngEntityStore.select(fromChat.getItgsInProgress(chatId)),
        this.ngEntityStore.select(fromChat.getActiveItgLatestMessageId(chatId)),
        this.ngEntityStore.select(fromSettings.getSchedulerIntentConfiguration),
        this.ngEntityStore.select(fromChat.getCurrentItg(chatId))
      ]),
      filter(([, , hasXaResponseFeatureFlag, isItgPaused]) => hasXaResponseFeatureFlag && !isItgPaused),
      tap(([{message, chatId}, chat, , , isItgRunning, itgsInProgress, latestActiveItgMessageId, schedulerIntentConfig, currentItg]) => {
        if (chat?.authenticated) {
          // Mercury should call aiQ for suggestion only if the customer is authenticated.
          const getXaSuggestionsRequest: GetXaSuggestionsRequest = {
            chatId,
            type: message.actionType,
            message: this.maskingService.maskPersonalInfo(message.message),
            messageId: message.messageId,
            customerGuid: chat.customerGuid,
            xaTranscriptSessionId: chat.xaTranscriptSessionId,
            sessionId: chat.xaSuggestionsSessionId
          };
          this.chatRequestApiService.getXaSuggestions(getXaSuggestionsRequest).then((suggestionResponse) => {
            if (suggestionResponse?.success && suggestionResponse?.body){
              const response = suggestionResponse.body;
              let isSuggestionAdded: boolean;
              let isNewItgSuggested: boolean = false;
              response.suggestions.forEach((suggestion: SuggestionBase) => {
                //suggestion suppressed if it it already recommended so the suggestion is not added
                if (!chat.suggestions.some(x => x.intent === suggestion.intent && x.visible) &&
                  !ChatHelper.isRecommendedIntent(chat, suggestion)) {
                  isSuggestionAdded = true;
                }
                if (suggestion.suggestionType === SuggestionType.Itg){
                  isNewItgSuggested = true;
                }
                if (ItgHelper.isSchedulerStartIntent(schedulerIntentConfig, suggestion)){
                  const itgTitle = currentItg?.title;
                  const itgIntent = itgTitle ? chat.itgSuggestions.find(i => i.itgIntent?.title === itgTitle)?.intent : undefined;
                  LogHelper.logChatEvent(this.loggingFactory, ChatOperations.NewSchedulerSuggestion, chat, {intent: itgIntent});
                }
              });
              const latestSuggestion = chat.suggestions.find(suggestion => suggestion.isLatest);
              if (isSuggestionAdded && latestSuggestion){
                const sendSuggestion: SendSuggestion = {
                  ...latestSuggestion,
                  chatId: chat.chatId
                };

                LogHelper.logDismissedSuggestion(this.loggingFactory, chat, sendSuggestion, true);
              }

              let addXaSuggestions: AddXaSuggestions = {
                ...response,
                latestActiveItgMessageId,
                isNewItgSuggested,
                isSuggestionAdded
              };

              addXaSuggestions = ChatHelper.removeDisabledItgStartSuggestions(addXaSuggestions, itgsInProgress);
              addXaSuggestions.itgDriftIntents = isItgRunning ? ChatHelper.getItgDriftIntents(addXaSuggestions.suggestions, response) : [];
              this.ngEntityStore.dispatch(ChatActions.AddXaSuggestions(addXaSuggestions));
            }
            else{
              this.validateItgError(isItgRunning, chatId, currentItg?.title, ItgErrorMessages.retrieveNextStepFailed);
            }
          }).catch(() => {
            this.validateItgError(isItgRunning, chatId, currentItg?.title, ItgErrorMessages.retrieveNextStepFailed);
          });
        }
      })
    ),
    { dispatch: false }
  );

  updateRecommendation$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.UpdateRecommendation, ChatActions.AcceptNewChat),
      concatLatestFrom(({recommendation}) => [
        this.ngEntityStore.select(fromChat.getChat(recommendation?.chatId)),
        this.ngEntityStore.select(fromSettings.hasFeatureFlag(FeatureFlags.XaSolves)),
        this.ngEntityStore.select(fromSettings.hasFeatureFlag(FeatureFlags.XaResponse))
      ]),
      filter(([recommendation, chat]) => (recommendation != null && chat != null)),
      tap(([{recommendation}, chat, hasXaSolvesFeatureFlag, hasXaResponseFeatureFlag]) => {
        let isNewItgSuggested: boolean;
        let isNewItgAdded: boolean;
        //if XaSolves FeatureFlag is not enabled make suggestion null
        if (!hasXaSolvesFeatureFlag){
          recommendation = {
          ...recommendation,
          suggestion: null
          };
        }
        //if XaResponse flag is not enabled make intents null
        if (!hasXaResponseFeatureFlag){
          recommendation = {
            ...recommendation,
            intents: null
            };
         }

        if (recommendation?.suggestion){
          isNewItgSuggested = true;
          isNewItgAdded = !recommendation?.suggestion.isItgStarted;
          const logDimensions = {
            queryId: recommendation.queryId,
            intentCode: recommendation.suggestion.intent,
            wasStarted: recommendation?.suggestion.isItgStarted
          };
          LogHelper.logChatEvent(this.loggingFactory, ChatOperations.DetectItg, chat, logDimensions);
        }

        if (recommendation?.intents?.length > 0){
          const logDimensions = {
            intents: recommendation?.intents
          };
          LogHelper.logChatEvent(this.loggingFactory, ChatOperations.XaCardOffers, chat, logDimensions);
        }

        const addRecommendation: AddRecommendation = {
          ...recommendation,
          isNewItgSuggested,
          isNewItgAdded
        };
        this.ngEntityStore.dispatch(ChatActions.AddRecommendation({addRecommendation}));
    })),
    { dispatch: false }
  );

  logCustomerButtonResponse$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.NewMessage),
      map(action => toPayload<ChatInteraction>(action)),
      concatLatestFrom(_action => this.ngEntityStore.select(fromChat.getChats)),
      filter(([chatMessage]) => {
        return chatMessage.sender === SenderType.Requester;
      }),
      tap(([interaction, chats]) => {
        const message = interaction as ChatMessage;
        if (message.intent){
          const chat = chats.find(c => c.chatId === interaction.chatId);

          const logDimensions: CustomerButtonResponseLog = {
            buttonIntentCode: message.intent
          };

          LogHelper.logChatEvent(this.loggingFactory, ChatOperations.CustomerButtonResponse, chat, logDimensions);
        }
      })
    ),
    { dispatch: false }
  );

  addXaSuggestion$ = createEffect(() =>
  this.actions.pipe(
    ofType(ChatActions.AddXaSuggestions),
    map(action => toPayload<AddXaSuggestions>(action)),
    filter(getXaSuggestionResponse => Boolean(getXaSuggestionResponse.suggestions?.length)),
    concatLatestFrom(_action => [this.ngEntityStore.select(fromChat.getChats),
      this.ngEntityStore.select(fromChat.getVisibleSuggestions)],
    ),
    tap(([getXaSuggestionResponse, chats, suggestions]) => {
      const chat = chats.find(c => c.chatId === getXaSuggestionResponse.chatId);
      const customerUtterance = getXaSuggestionResponse.messageId ? <ChatMessage> chat.messages.find(m => (m as ChatMessage).messageId === getXaSuggestionResponse.messageId) : undefined;
      const unusedSuggestions = suggestions.filter(s => s.timesSent === 0);
      const suggestion = getXaSuggestionResponse.suggestions[0];
      const operation = suggestion.suggestionType === SuggestionType.Itg ? ChatOperations.NewItgSuggestion : ChatOperations.NewSuggestion;

      const logDimensions: NewSuggestionLog = {
        utterance: customerUtterance?.message,
        queryId: getXaSuggestionResponse.queryId,
        intent: suggestion.intent,
        unusedSuggestionCount: unusedSuggestions.length,
        hasCustomerButtons: Boolean(suggestion.body?.buttons?.length)
      };

      if (operation === ChatOperations.NewItgSuggestion) {
        delete logDimensions.unusedSuggestionCount;
        delete logDimensions.hasCustomerButtons;
      }

      LogHelper.logChatEvent(this.loggingFactory, operation, chat, logDimensions);
    })
  ),
  { dispatch: false }
  );

  addExternalSuggestion$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.AddExternalSuggestions),
      concatLatestFrom(_action => [this.ngEntityStore.select(fromChat.getChat(_action.chatId))]
      ),
      tap(([externalSuggestion, chat]) => {
        const logDimensions: NewExternalSuggestionLog = {
          message: externalSuggestion?.suggestion?.body?.message,
          requestId: externalSuggestion?.suggestion?.queryId
        };

        LogHelper.logChatEvent(this.loggingFactory, ChatOperations.NewExternalSuggestion, chat, logDimensions);
      })
    ),
    { dispatch: false }
  );

  addGreetingSuggestion$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.AddGreetingSuggestion),
      concatLatestFrom(({chatId}) => [
        this.ngEntityStore.select(fromChat.getChat(chatId)),
        this.ngEntityStore.select(fromChat.getChatStartGreetingType(chatId))
      ]
      ),
      tap(([, chat, greetingType]) => {
        const logDimensions: NewGreetingSuggestionLog = {
          type: ChatStartGreetingHelper.GetChatGreetingEvents(greetingType)
        };

        LogHelper.logChatEvent(this.loggingFactory, ChatOperations.ChatStartGreeting, chat, logDimensions);
      })
    ),
    {dispatch: false}
  );

  pinMessage$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatUiActions.pinMessage),
      concatLatestFrom(_action => this.ngEntityStore.select(fromChat.getSelectedChat)),
      tap(([{ source, intent }, chat]) => {
        const dimensions: any = {
          source
        };

        if (intent) {
          dimensions.intentCode = intent;
        }

        LogHelper.logChatEvent(this.loggingFactory, ChatOperations.PinMessage, chat, dimensions);
      }
      )
    ),
  {dispatch: false}
  );

  unpinMessage$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatUiActions.unpinMessage),
      concatLatestFrom(_action => this.ngEntityStore.select(fromChat.getSelectedChat)),
      tap(([{ source }, chat]) => {
        LogHelper.logChatEvent(this.loggingFactory, ChatOperations.UnpinMessage, chat, { source });
      }
      )
    ),
  {dispatch: false}
  );

  clearPinnedMessages$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatUiActions.clearPinnedMessages),
      concatLatestFrom(_action => this.ngEntityStore.select(fromChat.getSelectedChat)),
      tap(([_, chat]) => {
        LogHelper.logChatEvent(this.loggingFactory, ChatOperations.ClearMessagePins, chat);
      }
      )
    ),
  {dispatch: false}
  );

  // TODO: update so there aren't multiple dispatches
  // requires refactor of dispatches/ChatAccepted effect
  updateActiveEngagements$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.updateActiveEngagements),
      concatLatestFrom(() => [
        this.ngEntityStore.select(fromSettings.getAllChatAutomationSettings),
        this.ngEntityStore.select(fromChat.getChats),
        this.ngEntityStore.select(fromApp.getShouldOneCtiHydrateChatStart),
        this.ngEntityStore.select(hasFeatureFlag(FeatureFlags.LanguageTranslator))
      ]),
      tap(([{engagements, storageChats, chats: ngrxStateChats}, chatAutomationSettings, chats, shouldOneCtiHydrateChatStart, hasLanguageTranslatorFeatureFlag]) => {
        const acceptedChats = chats.filter(chat => this.updateActiveEngagements_isChatAccepted(engagements, chat));

        if (shouldOneCtiHydrateChatStart) {
          this.ngEntityStore.dispatch(OneCtiActions.RefreshChatsToOneCti());
        }

        if (chats.length) {
          this.chatPersisterService.storeEntities(chats);
          chats.forEach(chat => {
            const isTranslatedChat = ChatHelper.shouldTranslateMessages(ChatHelper.getCustomerQueueLanguage(chat.customerLanguage, chat.defaultGroupTranslationLanguage), hasLanguageTranslatorFeatureFlag, chat.isTranslationConfigEnabled);
            if (isTranslatedChat) {
              this.ngEntityStore.dispatch(ChatActions.retryTranslateMessageOnReload({chat}));
            }
            // don't call getPreviousEngagements on chats that are accepted, priorEngagements retrieved in ChatAccepted
            const isChatAccepted = acceptedChats.find(ac => ac.chatId === chat.chatId);
            if (!isChatAccepted) {
              this._getPreviousEngagements(chat);
            }
            else{
              this.ngEntityStore.dispatch(ChatActions.ChatAccepted({chat, chatAutomationSettings}));
            }
            this.ngEntityStore.dispatch(ChatActions.GetSystemScripts(chat));
          });
        }
        else {
          this.chatPersisterService.clear();
        }

        const logDimensions: ServerSyncResultLog = {
          ngrxStateChatIds: ngrxStateChats.map((chat: Chat) => chat.chatId),
          storageChatIds: storageChats.map((chat: Chat) => chat.chatId),
          serverChatIds: engagements.map((engagement: ChatTranscriptResponse) => engagement.engagementId),
          acceptedChatIds: acceptedChats.map((chat: Chat) => chat.chatId),
          updateChatIds: chats.map((chat: Chat) => chat.chatId)
        };

        LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.ServerSyncResult, logDimensions);
      })
    ),
    {dispatch: false}
  );

  restartAbandonedChatAutomation$ = createEffect(() =>
    this.actions.pipe(
      ofType(HubsActions.chatRequestHubStateUpdated),
      filter(({ connectionState }) => connectionState === HubConnectionState.Reconnected),
      tap(() => {
        this.ngEntityStore.dispatch(ChatUiActions.restartAbandonedChatAutomation({ currentTime: this.timeService.unix() }));
      })
    ),
    {dispatch: false}
  );

  startItg$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatUiActions.startItg),
      concatLatestFrom(_ => this.ngEntityStore.select(fromChat.getSelectedChat) ),
      tap(([{ chatId, itgSuggestion, timestamp, source }, chat]) => {
        // send suggestion to customer, remove button options since we start the itg by sending the button intent, startItgSendIntent$
        const suggestion: SendSuggestion = {
          ...itgSuggestion,
          chatId,
          source: SendSuggestionSource.AutoPilot,
          traceId: Guid.raw(),
          timestamp,
          removeCustomerButtons: true
        };
        this.ngEntityStore.dispatch(ChatActions.SendSuggestion(suggestion));
        LogHelper.logChatEvent(this.loggingFactory, ChatOperations.StartItg, chat, {intent: itgSuggestion.intent, source});
      })
    ),
    {dispatch: false}
  );

  startItgSendIntent$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatUiActions.startItg),
      delay(ItgConfiguration.SendStartWaitTimeInSeconds * 1000),
      tap(({ chatId, itgSuggestion }) => {
        if (itgSuggestion?.itgIntent?.buttonTarget){
          const message: GetXaSuggestionsMessage = {
            actionType: XaSuggestionActionType.Intent,
            message: itgSuggestion.itgIntent.buttonTarget,
            messageId: itgSuggestion.messageId
          };
          this.ngEntityStore.dispatch(ChatActions.GetXaSuggestions({chatId, message}));
        }
      })
    ),
    {dispatch: false}
  );

  itgAutoPilot$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.AddXaSuggestions),
      map(action => toPayload<AddXaSuggestions>(action)),
      concatLatestFrom(({chatId}) => [
        this.ngEntityStore.select(fromChat.getIsItgRunning(chatId)),
        this.ngEntityStore.select(fromChat.getLatestItgSuggestionsByChatId(chatId)),
        this.ngEntityStore.select(fromChat.getLatestSchedulerSuggestionsByChatId(chatId)),
        this.ngEntityStore.select(fromChat.getCurrentItg(chatId)),
        this.ngEntityStore.select(fromSettings.getSchedulerIntentConfiguration)
      ]),
      filter(([, isItgRunning]) => isItgRunning),
      tap(([{ chatId, errorMessages, itgDriftIntents, isUnSupportedContentError }, , latestItgSuggestions, latestSchedulerSuggestions, currentItg, schedulerIntentConfig]) => {
        const {title} = currentItg;
        const latestItgSuggestion = latestItgSuggestions.length ? latestItgSuggestions[latestItgSuggestions.length - 1] : null;
        const latestSchedulerSuggestion = latestSchedulerSuggestions.length ? latestSchedulerSuggestions[latestSchedulerSuggestions.length - 1] : null;
        const timestamp = this.timeService.unix();
        if (errorMessages?.length) {
          const errorMessage = errorMessages.map(m => m.message).join();
          if (isUnSupportedContentError){
            // if we get an unsupported content error it should behave like a drift
            this.ngEntityStore.dispatch(ChatUiActions.pauseItg({chatId, title, timestamp, driftIntents: itgDriftIntents, errorMessage}));
          }
          else if (latestSchedulerSuggestion?.intent?.endsWith(SchedulerIntents.Failure)){
            this.ngEntityStore.dispatch(ChatUiActions.itgSchedulerError({chatId, title, timestamp, contentCode: latestSchedulerSuggestion.intent}));
          }
          else{
            this.ngEntityStore.dispatch(ChatUiActions.itgError({chatId, title, timestamp, message: errorMessage}));
          }
          return;
        }

        if (itgDriftIntents?.length) {
          this.ngEntityStore.dispatch(ChatUiActions.pauseItg({chatId, title, timestamp, driftIntents: itgDriftIntents}));
          return;
        }

        if (latestSchedulerSuggestion){
          if (ItgHelper.isSchedulerEndIntent(schedulerIntentConfig, latestSchedulerSuggestion)){
            this.ngEntityStore.dispatch(ChatUiActions.itgSchedulerCompleted({chatId, title, timestamp, contentCode: latestSchedulerSuggestion.intent}));
          }
          this.sendAutoPilotSuggestion(latestSchedulerSuggestion, chatId);
          return;
        }

        if (latestItgSuggestion) {
          if (ItgHelper.isSchedulerStartIntent(schedulerIntentConfig, latestItgSuggestion)){
            this.ngEntityStore.dispatch(ChatUiActions.itgContinueWithScheduler({chatId, title, timestamp}));
          }
          else if (latestItgSuggestion.isItgEnd){
            this.ngEntityStore.dispatch(ChatUiActions.itgCompleted({chatId, title, timestamp}));
          }
          this.sendAutoPilotSuggestion(latestItgSuggestion, chatId);
        }
      })
    ),
    {dispatch: false}
  );

  resumeItg$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatUiActions.resumeItg),
      concatLatestFrom(({chatId, title}) => this.ngEntityStore.select(fromChat.getItgMetadata(chatId, title))),
      tap(([{ chatId }, currentItg]) => {
        const message = currentItg?.lastCustomerMessageDuringAutoPilot;
        if (message){
          this.ngEntityStore.dispatch(ChatActions.GetXaSuggestions({chatId, message}));
        }
      })
    ),
    {dispatch: false}
  );


  pauseItg$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatUiActions.pauseItg),
      concatLatestFrom(({chatId, title}) => [
        this.ngEntityStore.select(fromChat.getChat(chatId)),
        this.ngEntityStore.select(fromChat.getAllItgSuggestionsByChatId(chatId)),
        this.ngEntityStore.select(fromChat.getItgMetadata(chatId, title))
      ]),
      tap(([{title, driftIntents, errorMessage}, chat, itgs, itgMetadata]) => {
        const itg = itgs.find(i => i.itgIntent && i.itgIntent.title === title);
        const logDimensions = {
          intent: itg.intent,
          xaSuggestionsSessionId: chat?.xaSuggestionsSessionId,
          ...(driftIntents && { driftIntents }),
          ...(errorMessage && { errorMessage })
        };
        const operationName = itgMetadata.isScheduling ? ChatOperations.PauseScheduler : ChatOperations.PauseItg;
        LogHelper.logChatEvent(this.loggingFactory, operationName, chat, logDimensions);
      })
    ),
    { dispatch: false }
  );

  logResumeItg$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatUiActions.resumeItg),
      concatLatestFrom(({chatId, title}) => [
        this.ngEntityStore.select(fromChat.getSelectedChat),
        this.ngEntityStore.select(fromChat.getSelectedChatAllItgSuggestions),
        this.ngEntityStore.select(fromChat.getSelectedChatItgsInProgress),
        this.ngEntityStore.select(fromChat.getItgMetadata(chatId, title))
      ]),
      tap(([{title, timestamp}, chat, itgs, itgsInProgress, itgMetadata]) => {
        const itg = itgs.find(i => i.itgIntent && i.itgIntent.title === title);

        const selectedItgInProgress = itgsInProgress.find(i => i.title === title);
        const lastPause = [...selectedItgInProgress.actions].reverse().find(action => action.type === ItgActionType.Pause).timestamp;

        const elapsedTime = timestamp - lastPause;
        const operationName = itgMetadata.isScheduling ? ChatOperations.ResumeScheduler : ChatOperations.ResumeItg;
        LogHelper.logChatEvent(this.loggingFactory, operationName, chat, {intent: itg.intent, elapsedTime});
      })
    ),
    { dispatch: false }
  );

  completeItg$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatUiActions.itgCompleted),
      concatLatestFrom(({chatId}) => [
        this.ngEntityStore.select(fromChat.getChat(chatId)),
        this.ngEntityStore.select(fromChat.getAllItgSuggestionsByChatId(chatId))]),
      tap(([{title}, chat, itgs]) => {
        const itg = itgs.find(i => i.itgIntent && i.itgIntent.title === title);

        LogHelper.logChatEvent(this.loggingFactory, ChatOperations.CompleteItg, chat, {intent: itg.intent});
      })
    ),
    { dispatch: false }
  );

  completeScheduler$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatUiActions.itgSchedulerCompleted),
      concatLatestFrom(({chatId}) => [
        this.ngEntityStore.select(fromChat.getChat(chatId)),
        this.ngEntityStore.select(fromChat.getAllItgSuggestionsByChatId(chatId))
      ]),
      tap(([{contentCode, title}, chat, itgs]) => {
        const itg = itgs.find(i => i.itgIntent && i.itgIntent.title === title);
        LogHelper.logChatEvent(this.loggingFactory, ChatOperations.CompleteScheduler, chat, {intent: itg.intent, contentCode});
      })
    ),
    { dispatch: false }
  );

  cancelItg$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatUiActions.cancelItg),
      concatLatestFrom(_ => [
        this.ngEntityStore.select(fromChat.getSelectedChat),
        this.ngEntityStore.select(fromChat.getSelectedChatAllItgSuggestions)]),
      tap(([{title}, chat, itgs]) => {
        const itg = itgs.find(i => i.itgIntent && i.itgIntent.title === title);
        LogHelper.logChatEvent(this.loggingFactory, ChatOperations.CancelItg, chat, {intent: itg.intent});
      })
    ),
    { dispatch: false }
  );

  itgError$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatUiActions.itgError),
      concatLatestFrom(({chatId}) => [
        this.ngEntityStore.select(fromChat.getChat(chatId)),
        this.ngEntityStore.select(fromChat.getAllItgSuggestionsByChatId(chatId))]),
      tap(([{message, title}, chat, itgs]) => {
        const itg = itgs.find(i => i.itgIntent && i.itgIntent.title === title);
        LogHelper.logChatEvent(this.loggingFactory, ChatOperations.ItgError, chat, {intent: itg.intent, message, xaSuggestionsSessionId: chat?.xaSuggestionsSessionId});
      })
    ),
    { dispatch: false }
  );

  continueWithScheduler$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatUiActions.itgContinueWithScheduler),
      concatLatestFrom(({chatId}) => [
        this.ngEntityStore.select(fromChat.getChat(chatId)),
        this.ngEntityStore.select(fromChat.getAllItgSuggestionsByChatId(chatId))]),
      tap(([{title}, chat, itgs]) => {
        const itg = itgs.find(i => i.itgIntent && i.itgIntent.title === title);
        LogHelper.logChatEvent(this.loggingFactory, ChatOperations.CompleteItg, chat, {intent: itg.intent});
        LogHelper.logChatEvent(this.loggingFactory, ChatOperations.StartScheduler, chat, {intent: itg.intent, method: StartSchedulerMethod[StartSchedulerMethod.AutoPilot]});
      })
    ),
    { dispatch: false }
  );

  schedulerError$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatUiActions.itgSchedulerError),
      concatLatestFrom(({chatId}) => [
        this.ngEntityStore.select(fromChat.getChat(chatId)),
        this.ngEntityStore.select(fromChat.getAllItgSuggestionsByChatId(chatId))]),
      tap(([{title, contentCode}, chat, itgs]) => {
        const itg = itgs.find(i => i.itgIntent && i.itgIntent.title === title);
        LogHelper.logChatEvent(this.loggingFactory, ChatOperations.SchedulerError, chat, {intent: itg.intent, contentCode, xaSuggestionsSessionId: chat?.xaSuggestionsSessionId});
      })
    ),
    { dispatch: false }
  );

  logRetrySendMessage$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.retrySendMessage, ChatActions.retrySendXaSuggestion),
      concatLatestFrom(({chatMessage}) => this.ngEntityStore.select(fromChat.getChat(chatMessage.chatId))),
      tap(([{ chatMessage }, chat]) => {
        const logDimensions: RetrySendMessageLog = { retryCount: (chatMessage.retryAttempts ?? 0) + 1 };
        LogHelper.logChatEvent(this.loggingFactory, ChatOperations.RetrySendMessage, chat, logDimensions);
      })
    ),
    {dispatch: false}
  );

  logDeleteMessage$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.deleteChatMessage),
      concatLatestFrom(({chatMessage}) => this.ngEntityStore.select(fromChat.getChat(chatMessage.chatId))),
      tap(([{ chatMessage }, chat]) => {
        const logDimensions: AbortSendMessageLog = { retryCount: (<SendChatMessage>chatMessage).retryAttempts ?? 0 };
        LogHelper.logChatEvent(this.loggingFactory, ChatOperations.AbortSendMessage, chat, logDimensions);
      })
    ),
    {dispatch: false}
  );

  logAutocomplete$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.SetScriptInUse),
      concatLatestFrom(({scriptInUse}) => this.ngEntityStore.select(fromChat.getChat(scriptInUse.chatId))),
      tap(([{scriptInUse}, chat]) => {
        if (scriptInUse.isAutocomplete) {
          const logDimensions = {scriptType: ScriptType[scriptInUse.scriptType]};
          LogHelper.logChatEvent(this.loggingFactory, ChatOperations.ScriptAutocomplete, chat, logDimensions);
        }
      })
    ),
    {dispatch: false}
  );

  mergeMessages$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.UpdateMessages),
      map(action => toPayload<UpdateMessages>(action)),
      concatLatestFrom((action) => [
        this.ngEntityStore.select(fromChat.getChatMessages(action.chatId)),
        this.ngEntityStore.select(fromChat.getChatAbandonedChatAutomationDetails(action.chatId))
      ]),
      filter(([updateMessages, messages]) => {
        return updateMessages.update === UpdateMessageType.Merge &&
          AbandonedChatAutomationHelper.getLastMessageSender(messages) === SenderType.Requester;
      }),
      tap(([updateMessages, messages, chatAutomationDetails]) => {
        this.updateCustomerActivityStatusActive(updateMessages.chatId, chatAutomationDetails.customerActivityStatus, messages);
      })
    ),
    {dispatch: false}
  );

  logCustomerReconnected$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.CustomerReconnected),
      map(action => toPayload<string>(action)),
      concatLatestFrom((chatId) => this.ngEntityStore.select(fromChat.getChat(chatId))),
      tap(([, chat]) => {
        LogHelper.logChatEvent(this.loggingFactory, ChatOperations.customerReconnect, chat);
      })
    ),
    {dispatch: false}
  );

  persistChats$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.persistChats),
      concatLatestFrom(() => this.ngEntityStore.select(fromChat.getChats)),
      tap(([, chats]) => {
        this.chatPersisterService.storeEntities(chats);
      })
    ),
    {dispatch: false}
  );

  handleSecureCollectionStatusChange$ = createEffect(() =>
  this.actions.pipe(
    ofType(ChatActions.UpdateSecureDataCollectionState),
    concatLatestFrom(() => this.ngEntityStore.select(fromSettings.getSecureDataCollectionCompletedMessage)),
    tap(([{chatId, requestState}, customerCompletedMessage]) => {
      if (requestState === DataCollectionState.CustomerApplied) {
        const suggestion: ExternalSuggestion = {
          body: {
            message: customerCompletedMessage,
            buttons: null,
            image: null
          },
          queryId: null,
          title: DataCollectionSuggestionTitles.customerApplied,
          isLatest: true,
          json: null,
          messageId: null,
          timesSent: 0,
          suggestionType: SuggestionType.External
        };

        this.ngEntityStore.dispatch(ChatActions.NewExternalSuggestion({ chatId, suggestion }));
      }
    })
  ),
  {dispatch: false}
  );

  createDataCollectionSuggestion$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.InitiateSecureDataCollection),
      concatLatestFrom((action) => [
        this.ngEntityStore.select(fromSettings.getSecureDataCollectionLinkReceivedMessage),
        this.ngEntityStore.select(fromChat.getChat(action.requestData.chatId))
      ]),
      tap(([{requestData}, linkReceivedTemplate, chat]) => {
        const collectionUrl = chat.channel?.type === ChannelType.Sms
          ? requestData.collectionUrl
          : `<a href="${requestData.collectionUrl}" target="_blank">${requestData.collectionUrl}</a>`;
        const linkMessage = linkReceivedTemplate.replace('<REPLACE LINK>', collectionUrl);
        const suggestion: ExternalSuggestion = {
          body: {
            message: linkMessage,
            buttons: null,
            image: null
          },
          queryId: requestData.requestId,
          title: DataCollectionSuggestionTitles.linkAvailable,
          isLatest: true,
          json: null,
          messageId: null,
          timesSent: 0,
          suggestionType: SuggestionType.External
        };

        this.ngEntityStore.dispatch(ChatActions.NewExternalSuggestion({ chatId: requestData.chatId, suggestion }));

        LogHelper.logChatEvent(this.loggingFactory, ChatOperations.SecureDataRequestReceivedLink, chat, { requestId: requestData.requestId });
      })
    ),
    {dispatch: false}
  );

  selectMessage$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatUiActions.messageSelected),
      concatLatestFrom(({chatId}) =>
        this.ngEntityStore.select(fromChat.getChat(chatId))
      ),
      tap(([{chatMessage, selectionMethod}, chat]) => {
        const messageType = this.getMessageTypeSelectMessageLog(chatMessage, chatMessage.isHistoric);
        const logDimensions: SelectMessageLog = {
          messageType,
          method: selectionMethod
        };
        LogHelper.logChatEvent(this.loggingFactory, ChatOperations.SelectMessage, chat, logDimensions);
      })
    ),
    {dispatch: false}
  );

  noTransferOptions$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.UpdateTransferOptions),
      filter(({payload}) => payload.businessUnits.length === 0),
      concatLatestFrom(({payload}) =>
        this.ngEntityStore.select(fromChat.getChat(payload.chatId))
      ),
      tap(([, chat]) => {
        LogHelper.logChatEvent(this.loggingFactory, ChatOperations.TransferOptionsEmpty, chat);
      })
    ),
    {dispatch: false}
  );

  logAgentlessQueues$ = createEffect(() =>
  this.actions.pipe(
    ofType(ChatActions.UpdateTransferOptions),
    concatLatestFrom(({payload}) => [
      this.ngEntityStore.select(hasFeatureFlag(FeatureFlags.TransferToAgent)),
      this.ngEntityStore.select(fromChat.getTransferOptions),
      this.ngEntityStore.select(fromChat.getChat(payload.chatId))
    ]),
    filter(([, isTransferToAgentFlagEnabled]) => isTransferToAgentFlagEnabled),
    tap(([, , businessUnits, chat]) => {
      const shouldLog = businessUnits?.some(
        unit => unit.queues?.some(
          queue => !queue.availableAgents?.length
      ));

      if (shouldLog) {
        LogHelper.logChatEvent(this.loggingFactory, ChatOperations.TransferAgentOptionsEmpty, chat);
      }
    })
  ),
  {dispatch: false}
);

  pauseAcaAtActive$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.StartDataCollectionRequest, ChatUiActions.startItg),
      concatLatestFrom(({chatId}) => this.ngEntityStore.select(fromChat.getChatAbandonedChatAutomationDetails(chatId))),
      filter(([, abandonedChatAutomationDetails]) => abandonedChatAutomationDetails.customerActivityStatus !== CustomerActivityStatus.Pinned),
      map(([{chatId}, abandonedChatAutomationDetails]) => {
        return ChatUiActions.updateCustomerActivityStatus({
          chatId: chatId,
          status: CustomerActivityStatus.Active,
          lastStatus: abandonedChatAutomationDetails.customerActivityStatus,
          stopTimer: true,
          currentTime: this.timeService.unix()
        });
      })
    )
  );

  sendItgStepError$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.sendSuggestionFailed),
      concatLatestFrom(({chatId}) => [
        this.ngEntityStore.select(fromChat.getIsItgRunning(chatId)),
        this.ngEntityStore.select(fromChat.getCurrentItg(chatId))
      ]),
      tap(([{chatId}, isItgRunning, currentItg]) => {
        this.validateItgError(isItgRunning, chatId, currentItg?.title, ItgErrorMessages.sendStepFailed);
      })
    ),
    {dispatch: false}
  );

  chatRequestProcessable$ = createEffect(() =>
    this.ngEntityStore.select(fromAgentAuth.chatRequestProcessable).pipe(
      distinctUntilChanged(),
      tap((processable: boolean) => {
        LogHelper.logAgentEvents(this.loggingFactory, AgentOperations.ChatRequestProcessable, { processable });
      })
    ),
    {dispatch: false}
  );

  autoRetryMessageFailedLog$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.sendMessageFailed, ChatActions.sendSuggestionFailed),
      concatLatestFrom(({chatId, traceId}) => [
        this.ngEntityStore.select(fromChat.getChat(chatId)),
        this.ngEntityStore.select(fromChat.getChatMessage(chatId, traceId)),
      ]),
      tap(([{numAutoRetries, isTranslationError}, chat, chatMessage]) => {
        if (isTranslationError){
          LogHelper.logTranslationError(this.loggingFactory, (<ChatMessage>chatMessage), chat);
        }
        LogHelper.logChatEvent(this.loggingFactory, ChatOperations.AutoRetryMessageFailed, chat, {numAutoRetries});
      })
    ),
    {dispatch: false}
  );

  autoRetryMessageSuccessLog$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.messageSent),
      concatLatestFrom(({chatId}) =>
        this.ngEntityStore.select(fromChat.getChat(chatId))
      ),
      filter(([{numAutoRetries}]) => Boolean(numAutoRetries)),
      tap(([{numAutoRetries}, chat]) => {
        LogHelper.logChatEvent(this.loggingFactory, ChatOperations.AutoRetryMessageSuccess, chat, {numAutoRetries});
      })
    ),
    {dispatch: false}
  );

  messageSent$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.messageSent),
      concatLatestFrom(() =>
        this.ngEntityStore.select(fromChat.getSelectedChat)
      ),
      filter(([{chatId}, selectedChat]) => chatId === selectedChat?.chatId),
      tap(([, selectedChat]) => {
        const { chatId, uui } = selectedChat;
        this.ctiService.sendCtiChatMessage(selectedChat, new CtiResumeChatRequest(uui, chatId));
      })
    ),
    {dispatch: false}
  );

  logCloseDismissSuggestionFeedback$ = createEffect(() =>
    this.actions.pipe(
      ofType(UiActions.CloseDismissSuggestionFeedback),
      filter(({ closeMethod }) => closeMethod !== CloseSuggestionFeedbackMethod.CloseButtonFeedbackSubmitted),
      concatLatestFrom(() => this.ngEntityStore.select(fromAgent.getLastDismissedSuggestion)),
      tap(([{ closeMethod, chat }, dismissedSuggestion]) => {
        const logDimensions = {
          method: closeMethod,
          intent: dismissedSuggestion?.intent
        };
        LogHelper.logChatEvent(this.loggingFactory, ChatOperations.DismissSuggestionFeedbackDismiss, chat, logDimensions);
      })
    ),
    { dispatch: false }
  );

  logDismissSuggestionFeedback$ = createEffect(() =>
    this.actions.pipe(
      ofType(AgentActions.SubmitDismissSuggestionFeedback),
      concatLatestFrom(_ => this.ngEntityStore.select(fromChat.getDismissSuggestionFeedbackChat)),
      tap(([{ reason, dismissedSuggestion }, chat]) => {
        const logDimensions = {
          reason,
          intent: dismissedSuggestion?.intent
        };
        LogHelper.logChatEvent(this.loggingFactory, ChatOperations.DismissSuggestionFeedback, chat, logDimensions);
      })
    ),
    { dispatch: false }
  );

  logRemoveSuggestion$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.hideSuggestion),
      concatLatestFrom(({chatId}) => this.ngEntityStore.select(fromChat.getChat(chatId))),
      tap(([{ suggestion }, chat]) => {
        const logDimensions = {
          intent: suggestion.intent,
          queryId: suggestion.queryId,
          title: suggestion.title
        };
        LogHelper.logChatEvent(this.loggingFactory, ChatOperations.RemoveSuggestion, chat, logDimensions);
      })
    ),
    { dispatch: false }
  );

  getActiveChatTranscriptResponse$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.GetActiveChatTranscriptResponse),
      concatLatestFrom(({chatId}) => [
        this.ngEntityStore.select(fromChat.getChat(chatId)),
        this.ngEntityStore.select(fromChat.getIsItgRunning(chatId))
      ]),
      tap(([{ chatId, transcript }, chat, isItgRunning]) => {
        const result = ChatHelper.parseTranscriptInteractions(chatId, transcript.participants, transcript.interactions);
        const updateMessages: UpdateMessages = {
          chatId,
          messages: result,
          update: UpdateMessageType.Merge,
          currentTime: this.timeService.unix()
        };
        this.ngEntityStore.dispatch(ChatActions.UpdateMessages(updateMessages));

        this.syncItg(isItgRunning, () => result, chat.messages, chatId);
      })
    ),
    { dispatch: false }
  );

  sendChatStartMessages$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.SendChatStartMessages),
      concatLatestFrom(({chatId}) => [
        this.ngEntityStore.select(fromChat.getChat(chatId)),
        this.ngEntityStore.select(hasFeatureFlag(FeatureFlags.DisconnectPreventionMessage)),
        this.ngEntityStore.select(hasFeatureFlag(FeatureFlags.AutoGreeting)),
        this.ngEntityStore.select(fromChat.getHasMessageSent(chatId))
      ]),
      filter(([, , , , hasMessageSent]) => !hasMessageSent),
      tap(([sendAutoGreetingMessage, chat, hasDisconnectPreventionFeatureFlag, hasAutoGreetingFeatureFlag]) => {
        if (hasDisconnectPreventionFeatureFlag) { this.sendDisconnectPreventionMessage(chat); }
        if (hasAutoGreetingFeatureFlag) { this.sendAutoGreetMessage(chat, sendAutoGreetingMessage.autoGreetingMessage.uui, sendAutoGreetingMessage.autoGreetingMessage.contextText); }
      })
    ),
    { dispatch: false }
  );

  chatStartMessagesLoadContext$ = createEffect(() => 
    this.actions.pipe(
      ofType(ChatActions.LoadContext),
      concatLatestFrom(({payload}) => [
        this.ngEntityStore.select(fromChat.getChat(payload.chatId)),
        this.ngEntityStore.select(hasFeatureFlag(FeatureFlags.DisconnectPreventionMessage)),
        this.ngEntityStore.select(hasFeatureFlag(FeatureFlags.AutoGreeting)),
        this.ngEntityStore.select(fromChat.getHasMessageSent(payload.chatId))
      ]),
      filter(([, , , , hasMessageSent])  => !hasMessageSent),
      tap(([loadContext, chat, hasDisconnectPreventionFeatureFlag, hasAutoGreetingFeatureFlag]) => {
        if (hasDisconnectPreventionFeatureFlag) { this.sendDisconnectPreventionMessage(chat); }
        if (hasAutoGreetingFeatureFlag) { this.sendAutoGreetMessage(chat, loadContext.payload.uui, loadContext.payload.contactReason); }
       })
    ),
    { dispatch: false }
  );

  chatStartMessagesChatLoadComplete$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.GetCustomerInformation),
      concatLatestFrom(({payload}) => [
        this.ngEntityStore.select(fromChat.getChat(payload)),
        this.ngEntityStore.select(hasFeatureFlag(FeatureFlags.DisconnectPreventionMessage)),
        this.ngEntityStore.select(hasFeatureFlag(FeatureFlags.AutoGreeting)),
        this.ngEntityStore.select(fromChat.getHasMessageSent(payload))
      ]),
      filter(([, chat, , , hasMessageSent]) => !chat.uui && !hasMessageSent),
      tap(([, chat, hasDisconnectPreventionFeatureFlag, hasAutoGreetingFeatureFlag]) => {
        if (hasDisconnectPreventionFeatureFlag) { this.sendDisconnectPreventionMessage(chat); }
        if (hasAutoGreetingFeatureFlag) { this.sendAutoGreetMessage(chat, null, null); }
       })
    ),
    { dispatch: false }
  );

  einsteinChatButtonClicked$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.EinsteinChatButtonClicked),
      tap(() => {

      window.open(this.appConfigService.einsteinChatUrl);
      })
    ),
    { dispatch: false }
  );

  eCMButtonClicked$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.ECMButtonClicked),
      tap(() => {

      window.open(this.appConfigService.ecmUrl);
      })
    ),
    { dispatch: false }
  );

  rateSessionSummary$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.rateSessionSummary),
      concatLatestFrom(() => this.ngEntityStore.select(fromChat.getSelectedChat)),
      tap(([ {isPositiveRating: isPositive, useNotes}, chat ]) => {
        const operationName = useNotes
          ? ChatOperations.ChatNotesFeedback
          : ChatOperations.ChatSummaryFeedback;
        const logDimensions = {
          isPositive
        };
        LogHelper.logChatEvent(this.loggingFactory, operationName, chat, logDimensions);
      })
    ),
    { dispatch: false }
  );

  rateAsyncSessionSummary$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.rateAsyncEngagementsSummary),
      concatLatestFrom(({chatId}) => this.ngEntityStore.select(fromChat.getChat(chatId))),
      tap(([ {isPositiveRating: isPositive}, chat ]) => {
        LogHelper.logChatEvent(this.loggingFactory, ChatOperations.AsyncEngagementsSummaryFeedback, chat, { isPositive });
      })
    ),
    { dispatch: false }
  );

 getChatSummaryData$ = createEffect(() =>
    this.actions.pipe(
      ofType(UiActions.GetChatSummaryData),
      concatLatestFrom(({ chatId }) => [
        this.ngEntityStore.select(hasFeatureFlag(FeatureFlags.ChatConversationSummary)),
        this.ngEntityStore.select(fromChat.getChatMessages(chatId)),
        this.ngEntityStore.select(fromChat.getChat(chatId))
      ]),
      filter(([, summaryFlag]) => Boolean(summaryFlag)),
      tap(([{ chatId, isAsyncEngagementsRequest, traceId }, , chatMessages, chat]) => {
        const messages = ChatHelper.getAllSortedTranscriptMessages(chatMessages, chat.xaTranscriptEndTimestamp, chat.asyncEngagements, chat.isAsync, isAsyncEngagementsRequest);
        const messagesForSummary = this.mapMessagesForSummary(messages);

        if (messagesForSummary.length) {
          const model: GetChatSummaryDataRequest = {
            chatId,
            chatConversations: messagesForSummary,
            traceId
          };

          this.chatRequestApiService.getChatSummaryData(model);
        }
      })
    ),
    { dispatch: false }
  );

  chatSummaryUpdated$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.UpdateChatSummary),
      concatLatestFrom(({chatId}) => [
        this.ngEntityStore.select(fromChat.getChat(chatId)),
        this.ngEntityStore.select(fromChat.getSummaryPayload(chatId))
      ]),
      //Ignore execution if chat is already closed by the time we get a summary
      filter(([, chat]) => Boolean(chat)),
      tap(([{error, chatSummaryData, chatId}, chat, summaryPayload]) => {
        //Log Summary
        if (error) {
          LogHelper.logChatEvent(this.loggingFactory, ChatOperations.ChatSummaryError, chat);
        } else {
          const logPayload = {
            recap: chatSummaryData?.recap
          };
          LogHelper.logChatEvent(this.loggingFactory, ChatOperations.ChatSummary, chat, logPayload);

          if (summaryPayload && chat.state === ChatState.Closed && chat.summaryData.autoSaveState !== LoadingState.Success) {
            this.ngEntityStore.dispatch(UiActions.SendAutoSaveSummary({chatId}));
          }
        }
      })
    ),
    { dispatch: false }
  );

  sendAutoSaveSummary$ = createEffect(() =>
    this.actions.pipe(
      ofType(UiActions.SendAutoSaveSummary, UiActions.RetryAutoSaveSummary),
      concatLatestFrom(({chatId}) => [
        this.ngEntityStore.select(fromChat.getChat(chatId)),
        this.ngEntityStore.select(fromAgent.getAgentNetworkId),
        this.ngEntityStore.select(fromChat.getSummaryPayload(chatId)),
        this.ngEntityStore.select(hasFeatureFlag(FeatureFlags.UnifiedNotes))
      ]),
      filter(([, chat, , , isUnifiedNotesFlagEnabled]) => Boolean(isUnifiedNotesFlagEnabled && chat?.accountNumber)),
      tap(([{chatId}, chat, ntId, summaryPayload]) => {
        const request: SendUnifiedNotesRequest = {
          ntId,
          accountNumber: chat.accountNumber,
          summary: 'System Generated Note: ' + summaryPayload + '\nChat ID: ' + chatId
        };

        const hasTask = this.queueChatRequestService.hasTaskForChat(chatId, (taskType) => taskType === SendTaskType.SendUnifiedNotes);
        if (!hasTask){
          this.queueChatRequestService.addTask(
            () => this.chatRequestApiService.sendUnifiedNotes(request),
            (resp) => {
              if (resp?.success && resp.body?.success){         
                this.ngEntityStore.dispatch(UiActions.UpdateAutoSaveSummary({ chatId }));
                const logDimensions = {
                  noteId: resp.body.noteId,
                };
                LogHelper.logChatEvent(this.loggingFactory, ChatOperations.ChatSummarySaved, chat, logDimensions);
              }
              else{
                this.ngEntityStore.dispatch(UiActions.AutoSaveSummaryFailed({ chatId }));
              }
            },
            () => this.ngEntityStore.dispatch(UiActions.AutoSaveSummaryFailed({ chatId })),
            SendTaskType.SendUnifiedNotes,
            chatId
          );
        }
      })
    ),
    { dispatch: false }
  );

  autoSaveSummaryFailed$ = createEffect(() =>
    this.actions.pipe(
      ofType(UiActions.AutoSaveSummaryFailed),
      concatLatestFrom(({chatId}) => [
        this.ngEntityStore.select(fromChat.getChat(chatId)),
      ]),
      tap(([{}, chat]) => {
        LogHelper.logChatEvent(this.loggingFactory, ChatOperations.SaveChatSummaryError, chat);
      })
    ),
    { dispatch: false }
  );

  customerAwaitingReplyNotificationClicked$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.CustomerAwaitingReplyNotificationClicked),     
      concatLatestFrom(({chatId}) => [
        this.ngEntityStore.select(fromChat.getChat(chatId)),
      ]),     
      tap(([, chat]) => {
        LogHelper.logChatEvent(this.loggingFactory, ChatOperations.CustomerAwaitingReplyNotificationClicked, chat);
      })
    ),
    { dispatch: false }
  );

  addCustomerAwaitingReplySuggestion$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.AddCustomerAwaitingReplySuggestion),     
      concatLatestFrom(({chatId}) => [
        this.ngEntityStore.select(fromChat.getChat(chatId)),
      ]),     
      tap(([, chat]) => {
        LogHelper.logChatEvent(this.loggingFactory, ChatOperations.AddCustomerAwaitingReplySuggestion, chat);
      })
    ),
    { dispatch: false }
  );

  triggerCustomerAwaitingReplyNotification$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.TriggerCustomerAwaitingReplyNotification),     
      concatLatestFrom(({chatId}) => [
        this.ngEntityStore.select(fromChat.getChat(chatId)),
        this.ngEntityStore.select(fromChat.getSelectedChatId)
      ]),     
      tap(([{windowHasFocus, value}, chat, selectedChatId]) => {
        if (!windowHasFocus || (windowHasFocus && selectedChatId !== chat.chatId)){
          const minutes = Math.floor(value / 60);
          this.mercuryNotificationService.triggerCustomerAwaitingReplyNotifications(chat, minutes);
        }
      })
    ),
    { dispatch: false }
  );

  logRecievedTranslationError$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.NewMessage),
      map(action => toPayload<ChatInteraction>(action)),
      concatLatestFrom(action => this.ngEntityStore.select(fromChat.getChat(action.chatId))),
      filter(([chatMessage]) => {
        return chatMessage.sender === SenderType.Requester &&
        (<ChatMessage>chatMessage).translationData?.hasError;
      }),
      tap(([chatMessage, chat]) => {
        LogHelper.logTranslationError(this.loggingFactory, (<ChatMessage>chatMessage), chat);
      })
    ),
    { dispatch: false }
  );

  logTranslationRequest$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.LogTranslationRequest),     
      concatLatestFrom(({chatId}) => [
        this.ngEntityStore.select(fromChat.getChat(chatId))
      ]),     
      tap(([{messageType, isRetry}, chat]) => {
        LogHelper.logTranslationRequest(this.loggingFactory, chat, messageType, isRetry);
      })
    ),
    { dispatch: false }
  );

  enhanceChatMessage$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.EnhanceChatMessage),
      concatLatestFrom(() => [
        this.ngEntityStore.select(fromChat.getSelectedChat),
		    this.ngEntityStore.select(fromSettings.selectEnhanceMessagePromptId)
      ]),
      tap(([{message}, chat, promptId]) => {
        const { chatId } = chat;
        const request: GetEnhancedChatMessageRequest = {
          content: message,
          userId: this.userIdentityService.username,
          promptId
        };
        LogHelper.logChatEvent(this.loggingFactory, ChatOperations.EnhanceRequested, chat, {message});
        this.chatRequestApiService.getEnhancedChatMessage(request).then((resp) => {
          if (resp.success && resp.body.success){
            this.ngEntityStore.dispatch(ChatActions.UpdateEnhanceChatMessage({ chatId, message: resp.body.output, error: !resp.body.output }));
          }
          else{
            this.ngEntityStore.dispatch(ChatActions.UpdateEnhanceChatMessage({ chatId, message: '', error: true }));
          }
        }).catch((error) => {
          this.ngEntityStore.dispatch(ChatActions.UpdateEnhanceChatMessage({ chatId, message: '', error: true, errorMessage: error?.message ?? '' }));
        });
      })
    ),
    { dispatch: false }
  );

  logEnhancedChatMessage$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.UpdateEnhanceChatMessage),
      concatLatestFrom(({chatId}) => [
        this.ngEntityStore.select(fromChat.getChat(chatId))
      ]),
      tap(([{message, error, errorMessage}, chat]) => {
        if (!error) {
          LogHelper.logChatEvent(this.loggingFactory, ChatOperations.EnhanceResponseOffered, chat, {enhancedMessage: message});
        }
        else {
          const logDimensions = {
            ...(errorMessage && {errorMessage})
          };
          LogHelper.logChatEvent(this.loggingFactory, ChatOperations.EnhanceResponseFailed, chat, logDimensions);
        }
      })
    ),
    { dispatch: false }
  );

  logChatTranslationToggle$ = createEffect(() =>
    this.actions.pipe(
      ofType(ChatActions.TranslationToggled),
      concatLatestFrom(({chatId}) => [
        this.ngEntityStore.select(fromChat.getChat(chatId))
      ]),
      tap(([{isEnabled}, chat]) => {
        LogHelper.logChatEvent(this.loggingFactory, ChatOperations.TranslationToggle, chat, {translationOn: isEnabled});
      })
    ),
    { dispatch: false }
  );

  getMessageTypeSelectMessageLog(chatMessage: ChatInteraction, isHistoric: boolean): SelectMessageType{
    if (isHistoric) { return SelectMessageType.Historic; }
    const {sender, type} = chatMessage;
    if (sender === SenderType.XA && type === ChatMessageType.XaSuggestion){
      return SelectMessageType.XaCard;
    }
    else if (sender === SenderType.Requester){
      return SelectMessageType.CustomerUtterance;
    }
    return SelectMessageType.AgentUtterance;
  }

  private sendDisconnectPreventionMessage(chat: Chat){
    if (chat.isReconnectEngagement 
        || chat.isAsync 
        || chat.transferred
        || chat.state === ChatState.Closed
      ) { return; }
    const disconnectPreventionMessage = ChatHelper.getDisconnectPreventionMessage(chat.contactMethod);
    if (!disconnectPreventionMessage){ return; }
    const msg: SendChatMessage = {
      chatId: chat.chatId,
      message: disconnectPreventionMessage,
      timestamp: this.timeService.unix(),
      sender: SenderType.FixAgent,
      type: ChatMessageType.Message,
      sendMessageType: SendMessageType.Script,
      scriptTreeId: chat.scriptTreeId,
      traceId: Guid.raw(),
      status: ChatMessageStatus.Pending
    };
    this.ngEntityStore.dispatch(ChatActions.SendMessage({chatMessage: msg}));
  }

  private sendAutoGreetMessage(chat: Chat, uui: string, context: string){
    if (chat.isReconnectEngagement 
        || chat.transferred 
        || (chat.isAsync && chat.asyncEngagements && chat.asyncEngagements.length > 0)
        || (chat.isAsync && !chat.asyncEngagements) // if returning async chat, but asyncEngagements is undefined
        || chat.state === ChatState.Closed
      ) { return; }
    const greetingMessage = ChatHelper.getAutoGreetingMessage(uui, context, chat.agentAlias, chat.customerDetails?.firstName);
    const msg: SendChatMessage = {
      chatId: chat.chatId,
      message: greetingMessage,
      timestamp: this.timeService.unix(),
      sender: SenderType.FixAgent,
      type: ChatMessageType.Message,
      sendMessageType: SendMessageType.Script,
      scriptTreeId: chat.scriptTreeId,
      traceId: Guid.raw(),
      status: ChatMessageStatus.Pending
    };
    this.ngEntityStore.dispatch(ChatActions.SendMessage({chatMessage: msg}));
    const logDimensions = {
      contextCode: UuiHelper.getContextCode(uui),
      greeting: greetingMessage,
      isCtiRunning: this.ctiService.isCtiConnected
    };
    LogHelper.logChatEvent(this.loggingFactory, ChatOperations.SendAutoGreeting, chat, logDimensions);
  }

  private getNextCustomerActivityStatus(chatAutomationDetails: AbandonedChatAutomationDetails): CustomerActivityStatus {
    const { automationSettings, customerActivityStatus, warningIndex } = chatAutomationDetails;
    switch (customerActivityStatus) {
      case CustomerActivityStatus.Active:
        return CustomerActivityStatus.Inactive;
      case CustomerActivityStatus.Inactive:
        if (automationSettings.warnings && automationSettings.warnings.length !== 0) {
          return CustomerActivityStatus.InactiveWarning;
        }
        else {
          return CustomerActivityStatus.InactiveClosing;
        }
      case CustomerActivityStatus.InactiveWarning:
        if (!automationSettings.warnings || automationSettings.warnings.length === 0 ||
          warningIndex === (automationSettings.warnings.length - 1)) {
          return CustomerActivityStatus.InactiveClosing;
        }
        else {
          return CustomerActivityStatus.InactiveWarning;
        }
      case CustomerActivityStatus.Pinned:
        return CustomerActivityStatus.Active;
      default:
        return customerActivityStatus;
    }
  }

  private chatAutomationAction(chatAutomationDetails: AbandonedChatAutomationDetails, chat: Chat) {
    const { customerActivityStatus, automationSettings, warningIndex } = chatAutomationDetails;
    // sends message or closes chat based on customerActivityStatus
    switch (customerActivityStatus) {
      case CustomerActivityStatus.Active:
        this.sendInactiveMessage(chat, automationSettings.inactive.messageHtml);
        break;
      case CustomerActivityStatus.Inactive:
        if (automationSettings.warnings && automationSettings.warnings.length !== 0) {
          this.sendInactiveMessage(chat, automationSettings.warnings[warningIndex].messageHtml);
        }
        break;
      case CustomerActivityStatus.InactiveWarning:
        if (automationSettings.warnings && automationSettings.warnings.length !== 0
          && warningIndex !== (automationSettings.warnings.length - 1)) {
          this.sendInactiveMessage(chat, automationSettings.warnings[warningIndex + 1].messageHtml);
        }
        break;
      case CustomerActivityStatus.InactiveClosing:
        if (chat.isAsync) {
          const closeModel: CloseAsyncChat = {
            chatId: chat.chatId,
            resolved: false,
            contactReason: ''
          };
          this.ngEntityStore.dispatch(ChatActions.CloseAsyncChat(closeModel));
        }
        else {
          const closeChat: CloseChat = {
            chatId: chat.chatId
          };
          this.ngEntityStore.dispatch(ChatActions.Close(closeChat));
        }
        const logClose: LogCustomerActivityStatus = {
          chatId: chat.chatId,
          status: CustomerActivityStatus.Closed
        };

        this.logAutomationAction(chat, logClose, chatAutomationDetails);
        break;
      case CustomerActivityStatus.Pinned:
        const logUnpinned: LogCustomerActivityStatus = {
          chatId: chat.chatId,
          status: CustomerActivityStatus.Unpinned
        };

        this.logAutomationAction(chat, logUnpinned, chatAutomationDetails, { manualUnpin: false });
        break;
    }
  }

  private sendInactiveMessage(chat: Chat, message: string): void {
    if (typeof message !== undefined && message) {
      const chatMessage: SendChatMessage = {
        chatId: chat.chatId,
        message: message,
        timestamp: this.timeService.unix(),
        sender: SenderType.Mercury,
        type: ChatMessageType.Message,
        sendMessageType: SendMessageType.Script,
        scriptTreeId: chat.scriptTreeId,
        traceId: Guid.raw(),
        status: ChatMessageStatus.Pending
      };
      this.ngEntityStore.dispatch(ChatActions.SendMessage({ chatMessage }));
    }
  }

  private logAutomationAction(
    chat: Chat,
    updateStatus: LogCustomerActivityStatus,
    abandonedChatAutomationDetails: AbandonedChatAutomationDetails,
    data: any = {}) {
    const { lastUnPinClickTimestamp, lastPinClickTimestamp, pinCount, warningIndex, automationSettings } = abandonedChatAutomationDetails;
    let lastTimestamp: number = 0;

    const lastChatMessage = chat.messages
      .filter(m => m.sender === SenderType.Requester || m.sender === SenderType.FixAgent)
      .sort((a, b) => a.timestamp < b.timestamp ? 1 : -1)[0];

    const lastChatTimeStamp = lastChatMessage.timestamp;

    if (updateStatus.status === CustomerActivityStatus.Pinned) {
      lastTimestamp = Math.max(lastUnPinClickTimestamp, lastChatTimeStamp);
    }
    else if (updateStatus.lastStatus === CustomerActivityStatus.Pinned || updateStatus.status === CustomerActivityStatus.Unpinned) {
      lastTimestamp = lastPinClickTimestamp;
    }
    else {
      lastTimestamp = Math.max(lastPinClickTimestamp, lastUnPinClickTimestamp, lastChatTimeStamp);
    }

    const timeElapsedSinceLastChat = this.timeService.unix() - lastTimestamp;

    const logDimensions = {
      engagementId: chat.chatId,
      elapsedTime: timeElapsedSinceLastChat,
      data: data
    };
    switch (updateStatus.status) {
      case CustomerActivityStatus.Pinned:
        Object.assign(logDimensions, {
          operationName: AbandonedChatOperations.Pinned,
          data: {
            ...logDimensions.data,
            lastState: CustomerActivityStatus[updateStatus.lastStatus],
            chatStartElapsed: this.timeService.unix() - chat.startTimestamp,
            pinCount,
            chatContext: chat.contactReason
          }
        });
        break;
      case CustomerActivityStatus.Active:
        Object.assign(logDimensions, {
          operationName: AbandonedChatOperations.Active,
          data: {
            lastState: CustomerActivityStatus[updateStatus.lastStatus]
          }
        });
        break;
      case CustomerActivityStatus.Inactive:
        Object.assign(logDimensions, {
          operationName: AbandonedChatOperations.Inactive
        });
        break;
      case CustomerActivityStatus.InactiveWarning:
        Object.assign(logDimensions, {
          operationName: AbandonedChatOperations.Warning,
          data: {
            ...logDimensions.data,
            warningCount: warningIndex + 1,
            message: automationSettings.warnings[warningIndex].messageHtml
          }
        });
        break;
      case CustomerActivityStatus.InactiveClosing:
        Object.assign(logDimensions, {
          operationName: AbandonedChatOperations.AutoClosing
        });
        break;
      case CustomerActivityStatus.Unpinned:
        Object.assign(logDimensions, {
          operationName: AbandonedChatOperations.Unpinned,
          data: {
            ...logDimensions.data
          }
        });
        break;
      case CustomerActivityStatus.Closed:
        Object.assign(logDimensions, {
          operationName: AbandonedChatOperations.Closed
        });
    }
    this.loggingFactory.logEvent(new AbandonedChatAutomationEvent(logDimensions));
  }

  private loadLocalityAndPriorEngagements(chat: Chat): void {
    if (chat.authenticated || chat.isAccountConnected) {
      const getLocality = new GetChatAccountLocality();
      getLocality.accountNumber = chat.accountNumber;
      getLocality.chatId = chat.chatId;
      this.ngEntityStore.dispatch(ChatActions.GetChatAccountLocality(getLocality));
    }
    this.ngEntityStore.dispatch(ChatActions.GetPriorEngagements({ chat }));
  }

  private _getPreviousEngagements(chat: Chat): void {
		if ((chat.authenticated || chat.isAccountConnected) && !chat.engagementHistory?.length) {
			this.ngEntityStore.dispatch(ChatActions.GetPriorEngagements({ chat }));
		}
    if (!chat.asyncEngagements?.length){
      if (chat.isAsync && chat.conversationId) {
        const getAsyncEngagements: GetAsyncEngagements = {
          conversationId: chat.conversationId,
          chatId: chat.chatId
        };
        this.ngEntityStore.dispatch(ChatActions.GetAsyncEngagements(getAsyncEngagements));
      }
      if (chat.previousEngagementId) {
        const engagementById: GetEngagementById = {
          chatId: chat.chatId,
          engagementId: chat.previousEngagementId
        };
        this.ngEntityStore.dispatch(ChatActions.GetEngagementById(engagementById));
      }
    }
	}

  private getCloseChatInteraction(chat: Chat, smartResponses: SmartResponses[], cxtGptMessages: CxGptResponseVm[], agentGroups: AgentGroup[], businessUnits: BusinessUnit[], agentNetworkId: string, sendTimelineTranscript: boolean): CloseChatInteraction {
    const { chatId, customerGuid, uui, uid, businessUnitId, siteId, authenticated, accountNumber, agentGroupId, customerPhoneNumber, startTimestamp, agentAlias, queueId, queueName } = chat;
    const agentGroupName = agentGroups?.find(f => f.id === chat.agentGroupId)?.name;
    const businessUnitName = businessUnits?.find(f => f.id === chat.businessUnitId)?.name;
    const transcript = this.nlpTranscriptService.getNlpTranscript(chat, smartResponses, cxtGptMessages);

    const messageCount = this.nlpTranscriptService.getMessageCount(chat);

    LogHelper.logChatEvent(this.loggingFactory, ChatOperations.AsyncTimeOutInfo, chat, { messageCount, chatMode: chat.contactMethod, isAsync: chat.isAsync});

    return {
      transcript,
      chatId,
      accountNumber,
      uui,
      uid,
      businessUnitId,
      siteId,
      agentGroupName,
      businessUnitName,
      agentGroupId,
      messageCount,
      customerPhone: customerPhoneNumber,
      customerGuid: authenticated ? customerGuid : null,
      conversationId: chat.conversationId,
      agentNetworkId,
      chatMode: chat.contactMethod,
      startTimestamp,
      agentAlias,
      queueId,
      queueName,
      EndTimestamp : this.timeService.unix(),
      SendTimelineTranscriptEvent: sendTimelineTranscript
    };
  }

  private getAsyncMessagesForSummary(asyncEngagements: AsyncEngagementTranscript[]): ChatMessageForSummary[] {
    const asyncMessages = ChatHelper.getAsyncTranscriptMessages(asyncEngagements);
    const asyncFilteredMessages = asyncMessages.filter(m => m.type === ChatMessageType.Message && m.sender !== SenderType.System);
    ChatHelper.sortMessagesByTimestamp(asyncFilteredMessages);

    return this.mapMessagesForSummary(asyncFilteredMessages);
  }

  private getAgentIds(engagementTranscript: PriorEngagement[]): PrevAgents {

    const orderedHistoryList = engagementTranscript ? [...engagementTranscript].sort((a, b) => {
      return a.createDate < b.createDate ? 1 : -1;
    }) : [];

    const agentIds: string[] = [];

    orderedHistoryList.map(e => {
      return e.participants.filter(p => {
        return p.type === SenderType.FixAgent;
      }).map(p => {
        agentIds.push(p.id);
      });
    });

    let lastAgentList = orderedHistoryList.length > 0 ?
      orderedHistoryList[0].participants.filter(p => {
        return p.type === SenderType.FixAgent;
      }) : [];

    lastAgentList = lastAgentList.reverse();
    const lastAgentId = lastAgentList.length > 0 ? lastAgentList[0].id : '';

    return {
      lastAgentId: lastAgentId,
      agentIds: agentIds,
    };
  }

  private isLastUpdateOutdated(lastUpdatesCompleteTimestamp: number): boolean {
    const secondsSinceLastUpdateCompleted = this.timeService.unix() - (lastUpdatesCompleteTimestamp || 0);

    return secondsSinceLastUpdateCompleted > ChatGetUpdateSettings.LastUpdateCompletedMaxAgeInSeconds;
  }

  private isHubStateConnected(hubState: HubConnectionState): boolean{
    return hubState === HubConnectionState.Connected || hubState === HubConnectionState.Reconnected;
  }

  private updateCustomerActivityStatusActive(chatId: string, lastStatus: CustomerActivityStatus, messages: ChatInteraction[]): void{
    if (lastStatus !== CustomerActivityStatus.Pinned){
      const stopTimer = AbandonedChatAutomationHelper.getLastMessageSender(messages) === SenderType.Requester;
      this.ngEntityStore.dispatch(ChatUiActions.updateCustomerActivityStatus({
        chatId: chatId,
        status: CustomerActivityStatus.Active,
        lastStatus,
        stopTimer,
        currentTime: this.timeService.unix()
      }));
    }
  }

  private sendMessage(chatMessage: SendChatMessage, lastStatus: CustomerActivityStatus, messages: ChatInteraction[], suggestionType: SuggestionType, asyncInactiveFeatureFlagEnabled: boolean, chat: Chat) {
    const { chatId, traceId } = chatMessage;

    const inactiveStatuses = [
      CustomerActivityStatus.Inactive,
      CustomerActivityStatus.InactiveWarning,
      CustomerActivityStatus.InactiveClosing
    ];
  
    this.queueChatRequestService.addTask(
      () => this.chatRequestApiService.sendMessage(chatMessage),
      (response: HubResponse<SendMessageResponse>, numAutoRetries: number) => {
        this._dispatchMessageSent(response, chatMessage, numAutoRetries);
      },
      (response: HubResponse<SendMessageResponse>, numAutoRetries: number) => {
        const isTranslationError = response?.body?.translationError;
        this.ngEntityStore.dispatch(ChatActions.sendMessageFailed({chatId, traceId, numAutoRetries, isTranslationError}));
      },
      SendTaskType.SendMessage,
      chatId
    );
    if (chatMessage.sender === SenderType.FixAgent && suggestionType !== SuggestionType.CustomerAwaitingReply) {
      if (!asyncInactiveFeatureFlagEnabled || !chat.isAsync ||
          (asyncInactiveFeatureFlagEnabled && chat.isAsync && !inactiveStatuses.includes(lastStatus))
      ) {
          this.updateCustomerActivityStatusActive(chatMessage.chatId, lastStatus, messages);
      }
    }
  }

  private sendXaSuggestion(xaSuggestion: SendSuggestion, chatMessage: ChatInteraction){
    const { chatId, traceId } = xaSuggestion;

    this.queueChatRequestService.addTask(
      () => this.chatRequestApiService.sendXaSuggestion(xaSuggestion),
      (response: HubResponse<SendMessageResponse>, numAutoRetries: number) => {
        this._dispatchMessageSent(response, chatMessage, numAutoRetries);
      },
      (response: HubResponse<SendMessageResponse>, numAutoRetries: number) => {
        const isTranslationError = response?.body?.translationError;
        this.ngEntityStore.dispatch(ChatActions.sendSuggestionFailed({chatId, traceId, numAutoRetries, isTranslationError}));
      },
      SendTaskType.SendXaSuggestion,
      chatId
    );
  }

  private _dispatchMessageSent(response: HubResponse<SendMessageResponse>, chatMessage: ChatInteraction, numAutoRetries: number){
    const timestamp = this.timeService.unix(response.body.timestamp);
    this.ngEntityStore.dispatch(ChatActions.messageSent({ ...response.body, chatMessage, timestamp, numAutoRetries}));
  }

  private _logAgentFirstResponseSendMessage(chatMessage: SendChatMessage, lastStatus: CustomerActivityStatus, chat: Chat, suggestionType: SuggestionType, asyncInactiveFeatureFlagEnabled: boolean){
    if (chatMessage.sender === SenderType.FixAgent){
      const agentPreviousMessages =  chat.messages.filter(p => {
        return p.senderId === chatMessage.senderId && p.sender === chatMessage.sender;
      });

      if (agentPreviousMessages.length === 1){
        LogHelper.logChatEvent(this.loggingFactory, ChatOperations.AgentFirstResponse, chat, { message: chatMessage.message});
      }
    }
    
    this.sendMessage(chatMessage, lastStatus, chat.messages, suggestionType, asyncInactiveFeatureFlagEnabled, chat);
  }

  private updateActiveEngagements_isChatAccepted(engagements: ChatTranscriptResponse[], chat: Chat): boolean {
    return Boolean(engagements.find(e => e.engagementId === chat.chatId && chat.state === ChatState.AcceptanceUnknown));
  }

  private getSmartResponsePlaceholders(smartResponses: SmartResponses[], allPlaceHolders: Placeholder[]): Placeholder[] {
    let placeHolders = [];
    if (allPlaceHolders){
      const placeHolderKeys: string[] = [];
      smartResponses?.forEach(s => {
          s.smartResponses?.forEach(f => {
          placeHolderKeys.push(...f.entities);
        });
      });
      placeHolders = allPlaceHolders.filter(p => placeHolderKeys.includes(p.key));
    }
    return placeHolders;
  }

  private sendAutoPilotSuggestion(suggestion: Suggestion, chatId: string){
    const sendSuggestion: SendSuggestion = {
      ...suggestion,
      chatId,
      source: SendSuggestionSource.AutoPilot,
      traceId: Guid.raw(),
      timestamp: this.timeService.unix()
    };
    this.ngEntityStore.dispatch(ChatActions.SendSuggestion(sendSuggestion));
  }

  private validateItgError(isItgRunning: boolean, chatId: string, title: string, message: string){
    if (isItgRunning){
      this.ngEntityStore.dispatch(ChatUiActions.itgError({chatId, title, timestamp: this.timeService.unix(), message}));
    }
  }

  private syncItg(isItgRunning: boolean, getServerTranscriptMessages: () => ChatInteraction[], stateTranscriptMessages: ChatInteraction[], chatId: string): void{
    const { shouldSync, syncMessage } = ItgHelper.shouldSyncItg(isItgRunning, getServerTranscriptMessages, stateTranscriptMessages);
    if (!shouldSync) { return; }

    const getXaSuggestionsMessage = this.createGetXaSuggestionsMessageModel(syncMessage as ChatMessage);
    this.ngEntityStore.dispatch(ChatActions.GetXaSuggestions({ chatId, message: getXaSuggestionsMessage }));
  }

  private createGetXaSuggestionsMessageModel(chatMessage: ChatMessage) {
    const { messageId } = chatMessage;
    const actionType = chatMessage.intent ? XaSuggestionActionType.Intent : XaSuggestionActionType.Utterance;
    const message = chatMessage.intent ?? chatMessage.message;
    const getXaSuggestionsMessage: GetXaSuggestionsMessage = {
      actionType,
      message,
      messageId
    };
    return getXaSuggestionsMessage;
  }

  private updateChatSummaryData(chat: Chat, summaryPayload: string)
  {
    if (summaryPayload){
      const chatSummary: PreviousChatSummary = {
        chatId: chat.chatId,
        accountNumber: chat.accountNumber,
        summary: this.maskingService.maskPersonalInfo(summaryPayload),
        customerName: ChatHelper.getCustomerUser(chat)?.name,
        color: chat.color,
        createDate: this.timeService.unix(),
        channel: chat.channel,
        timeZoneName: chat.timeZone?.name
      };
      this.ngEntityStore.dispatch(AgentActions.UpdatePreviousChatSummaries({chatSummary}));
    }
  }

  private updateAgentUnusedSlotTimes(chatIds: string[], maxChats: number, isAvailable: boolean, agentUnusedSlotTimes: UnusedSlotTimes){
    const availableSlots = maxChats - chatIds?.length;
    let slotsUnusedElapsedSeconds = Array(maxChats).fill(0);
    if (agentUnusedSlotTimes?.currentSlotUnavailableStartTime){      
      slotsUnusedElapsedSeconds = AgentHelper.getSlotsUnusedElapsedSeconds(this.timeService.unix(), agentUnusedSlotTimes);
    }
		if (!isAvailable && availableSlots > 0){
			const unusedSlotTimes: UnusedSlotTimes = {
				currentSlotUnavailableStartTime: this.timeService.unix(),
				unAvailableStartTime: agentUnusedSlotTimes?.unAvailableStartTime ?? this.timeService.unix(),
        slotsUnusedElapsedSeconds: slotsUnusedElapsedSeconds,
        availableSlots
			};
			this.ngEntityStore.dispatch(AgentActions.UpdateUnusedSlotTimes({unusedSlotTimes}));
		}
	}

  private mapMessagesForSummary(messages: ChatInteraction[]): ChatMessageForSummary[] {
    return messages.map(chatMessage => {
      const messageForSummary: ChatMessageForSummary = {
        message: (<ChatMessage>chatMessage).message,
        sender: chatMessage.sender,
        messageId: chatMessage.messageId
      };

      return messageForSummary;
    });
  }

  private handleAutoSaveSummary(postChatRequest: TransferChatRequest | CloseChatRequest | CloseConversation,
    chat: Chat,
    isUnifiedNotesFlagOn: boolean,
    ntId: string,
    isTransfer: boolean,
    isAsyncPauseAbandoned: boolean,
    summaryPayload: string
  ) {
    if (ChatHelper.shouldAutoSaveSummary(isUnifiedNotesFlagOn, chat.summaryData, chat.accountNumber, isAsyncPauseAbandoned, summaryPayload)) {
      this.ngEntityStore.dispatch(UiActions.SendAutoSaveSummary({ chatId: chat.chatId }));
    }
    else if (ChatHelper.shouldBackgroundAutoSaveSummary(isUnifiedNotesFlagOn, chat.summaryData, chat.accountNumber, isAsyncPauseAbandoned)) {
      let dialogForSummary;
      if (isTransfer) {
        const messages = ChatHelper.getAllSortedTranscriptMessages(chat.messages, chat.xaTranscriptEndTimestamp, chat.asyncEngagements, chat.isAsync, false);
        dialogForSummary = this.mapMessagesForSummary(messages);
      }
      else {
        dialogForSummary = this.getAsyncMessagesForSummary(chat.asyncEngagements);
      }
      const postChatSummaryData: PostChatSummaryData = {
        dialogForSummary,
        ntId
      };
      postChatRequest.accountNumber = chat.accountNumber;
      postChatRequest.postChatSummaryData = postChatSummaryData;
      const customer = chat?.users.find((u) => u.type === SenderType.Requester) as Customer;
      const pendingChatSummaryData: PreviousChatSummary = {
        chatId: chat.chatId,
        customerName: customer?.name,
        accountNumber: chat.accountNumber,
        color: chat.color,
        timeZoneName: chat.timeZone?.name,
        channel: chat.channel
      };
      this.ngEntityStore.dispatch(UiActions.BackgroundAutoSaveSummaryTriggered({ pendingChatSummaryData }));
    }
  }

  private handlePauseAbandonedAsyncChat(chat: Chat, isAsyncPauseAbandoned: boolean, autoCloseReason: string){
    if (chat?.isAsync && isAsyncPauseAbandoned){
      // when we receive a 'Customer Closed Chat' event or the last heartbeat from customer is older than configured threshold, and the agent has not sent a message on an async chat
      const abandonedAsync: MarkAbandonedInAsyncQueueRequest = {
        siteId: chat.siteId,
        nuanceCustomerId: chat.nuanceCustomerId,
        chatId: chat.chatId
      };
      this.chatRequestApiService.MarkAbandonedInAsyncQueue(abandonedAsync);

      const closeAsyncChatArgs: CloseAsyncChat = {
        chatId: chat.chatId,
        resolved: false,
        contactReason: null,
        isAsyncPauseAbandoned: true
      };
      this.ngEntityStore.dispatch(ChatActions.CloseAsyncChat(closeAsyncChatArgs));
      LogHelper.logChatEvent(this.loggingFactory, ChatOperations.PauseAbandonedChat, chat, {autoCloseReason});
    }
  }

  private sendTranslateTextsToEnglishRequest(chat: Chat, textsToTranslate: TextTranslationRequest[], isAsyncRequest: boolean) {
    if (!chat) {
      return;
    }

    const { chatId } = chat;
    const translationRequestVm: TranslationRequest = {
      chatId,
      translateTo: Language.English,
      textsToTranslate
    };
    
    LogHelper.logTranslationRequest(this.loggingFactory, chat, TranslatedMessageType.Batch, false);
    this.chatRequestApiService.translateTexts(translationRequestVm).then(resp => {
      const translatedMessages = resp?.body?.translatedMessages;
      if (resp?.success && translatedMessages?.length){
        this.ngEntityStore.dispatch(LanguageTranslationActions.chatMessagesTranslationUpdated({ chatId, translatedMessages, isAsyncTranslated: isAsyncRequest }));
      }
      else{
        LogHelper.logTranslationError(this.loggingFactory, null, chat, 'Batch Translation Failure');
        this.ngEntityStore.dispatch(LanguageTranslationActions.chatMessagesTranslationUpdated({ chatId, translatedMessages, isError: true }));
      }
    }).catch(() => {
        LogHelper.logTranslationError(this.loggingFactory, null, chat, 'Batch Translation Failure');
        this.ngEntityStore.dispatch(LanguageTranslationActions.chatMessagesTranslationUpdated({ chatId, translatedMessages: null, isError: true }));
    });
  }

  private mapAsyncEngagementsToTranslationRequest(asyncEngagements: AsyncEngagementTranscript[]) {
    const textsToTranslate: TextTranslationRequest[] = [];
    asyncEngagements?.forEach(e => {
      const untranslatedMessages = e?.interactions?.filter(i => i.type === ChatMessageType.Message && ChatHelper.isCustomerOrAgentSender(i.sender) && !i.translationData);
      untranslatedMessages.forEach(m => {
        const textTranslationRequest: TextTranslationRequest = {
          textToTranslate: (<ChatMessage>m).message,
          traceId: m.messageId
        };

        textsToTranslate.push(textTranslationRequest);
      });
    });

    return textsToTranslate;
  }
}
