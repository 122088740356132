// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { ChatMember } from './chatMember';
import { PopUp } from '../../utils/comcast.cs.webparts.popups';
import { ChatState, DeviceType, Language } from './enums';
import { DispositionCategory } from './dispositionCategory';
import { BusinessUnitTransferOption } from './businessUnitTransferOption';
import { Channel } from './channel';
import { TimeZone } from './timeZone';
import { AsyncEngagementTranscript, ChatTranscript} from './chatTranscript';
import { ChatInteraction } from './chatInteraction';
import { ScriptSent } from './scriptSent';
import { Color } from './color';
import { Suggestion } from './suggestion';
import { SelectedHistoryEngagement } from './selected-history-engagement';
import { SendSuggestion } from './requests/send-suggestion';
import { DispositionSelection } from 'projects/entities/src/public_api';
import { ScriptInUse } from './setScriptInUse';
import { ItgSuggestion } from './itgSuggestion';
import { SchedulerSuggestion } from './schedulerSuggestion';
import { SelectedSchedule } from './selectedSchedule';
import { ErrorMessage } from './responses/getXaSuggestionsResponse';
import { SecureDataCollection } from './secureDataCollection';
import { SecureDataRequest } from './secureDataRequest';
import { SmartResponseSent } from './smartResponsesSent';
import { SmartResponseFeedbackVm } from './smart-response-feedback-vm';
import { StoredTextToSend } from './stored-text-to-send';
import { AgentFeatureFlags } from './agentFeatureFlags';
import { TranscriptEvent } from './NlpTranscript';
import { CustomerDetails } from './customerDetails';
import { ChatSummaryData } from './chatSummaryData';
import { EnhancedChatMessageData } from './enhancedChatMessageData';
import { CxGptResponseSent } from './cx-gpt-response-sent';

export class Chat {
    chatId: string;
    startTimestamp: number;
    users: ChatMember[];
    queueId: string;
    queueName: string;
    state: ChatState;
    channel: Channel;
    color: Color;
    messages: ChatInteraction[] = [];
    engagementHistory: ChatTranscript[] = [];
    einstein360Window: PopUp;
    siteId: string;
    businessUnitId: string;
    agentGroupId: string;
    messagingEnabled: boolean;
    isTransferrable: boolean;
    dispositions: DispositionCategory[] = [];
    transferOptions: BusinessUnitTransferOption[] = [];
    agentResponseMaxSlaInSeconds: number;
    agentResponseMidSlaInSeconds: number;
    timeZone: TimeZone;
    transferred: boolean;
    selectedPhoneNumber: string;
    scriptsSent: ScriptSent[] = [];
    nuanceCustomerId: string;
    customerGuid: string; // custGuid when authenticated. something else when unauthenticated
    uui: string;
    uid: string;
    contactReason: string;
    isLoading: boolean;
    autoCloseChat: boolean;
    autoCloseTimer: number;
    businessRule: string;
    chatType: string;
    pageMarkers: string[];
    locale: string;
    autoTransfer: boolean;
    autoTransferSeconds: number;
    autoTransferWarningSeconds: number;
    isAsync: boolean;
    conversationId: string;
    asyncEngagements: AsyncEngagementTranscript[];
    asyncEngagementsSummaryData: ChatSummaryData;
    unresolvedContactReason: string;
    agentTransferNotes: string;
    targetQueueId: string;
    targetQueueBusinessUnitId: string;
    accountNumber: string;
    authenticated: boolean;
    isAccountConnected: boolean;
    secureDataCollection: SecureDataCollection = new SecureDataCollection();
    secureDataRequests: SecureDataRequest[] = [];

    scriptTreeId: string;
    scriptsInUse: ScriptInUse[] = [];
    isFirstLoad: boolean;

    selectedHistoryEngagement: SelectedHistoryEngagement = null;

    xaTranscriptSessionId: string; // sessionId to get transcript that comes before current chat
    xaSuggestionsSessionId: string; // sessionId returned for Xa suggestions to continue session
    suggestions: Suggestion[] = [];
    clickedSuggestion: SendSuggestion;
    deviceType: DeviceType;
    previousEngagementId: string;
    isReconnectEngagement: boolean;
    selectedDispositions: DispositionSelection = null;
    sendQuickResponses: boolean = true;
    itgSuggestions: ItgSuggestion[] = [];
    schedulerSuggestions: SchedulerSuggestion[] = [];
    selectedSchedule: SelectedSchedule = null;
    isTimeSlotTakenError: boolean = false;
    xaResponseErrorMessages: ErrorMessage[] = null;
    storedTextToSend: StoredTextToSend = null;
    oneCtiConnected: boolean;
    recommendedIntents: string[] = [];
    startedItgIntents: string[] = [];
    smartResponsesSent: SmartResponseSent[];
    xaTranscriptEndTimestamp: number;
    smartResponseFeedback?: SmartResponseFeedbackVm;
    agentsFeatureFlags?: AgentFeatureFlags[];
    events: TranscriptEvent[];
    customerDetails: CustomerDetails;
    agentAlias: string;
    summaryData: ChatSummaryData;
    enhancedChatMessageData: EnhancedChatMessageData;
    customerAwaitingReplyMessagePriority: number;
    cxGptResponseSent: CxGptResponseSent[]; 
    contactMethod: string;
    customerLanguage: Language;
    customerPhoneNumber: string;
    isAbandonedAsyncChat: boolean;
	  defaultGroupTranslationLanguage: Language;
    isTranslationConfigEnabled: boolean;

    constructor(data: any = {}) {
        this.engagementHistory = [];
        this.color = null;
        this.users = [];
        this.queueId = data.queueId;
        this.queueName = data.queueName;
        this.messagingEnabled = true;
        this.isTransferrable = true;
        this.timeZone = null;
        this.isLoading = true;
        this.isFirstLoad = true;

        this.chatId = data.chatRequestId || data.chatId;
        this.state = data.state ?? ChatState.Open;
        this.channel = data.channel;
        this.scriptTreeId = data.scriptTreeId;
        this.siteId = data.siteId;
        this.businessUnitId = data.businessUnitId;
        this.agentGroupId = data.agentGroupId;
        this.agentResponseMaxSlaInSeconds = data.agentResponseMaxSlaInSeconds ?? 120;
        this.agentResponseMidSlaInSeconds = data.agentResponseMidSlaInSeconds ?? this.agentResponseMaxSlaInSeconds / 2;
        this.startTimestamp = data.startTimestamp;
        this.nuanceCustomerId = data.nuanceCustomerId;
        this.uui = data.uui;
        this.contactReason = data.contactReason;
        this.autoCloseChat = data.autoCloseChat;
        this.autoCloseTimer = data.autoCloseTimer;
        this.businessRule = data.businessRule;
        this.chatType = data.chatType;
        this.pageMarkers = data.pageMarkers;
        this.locale = data.locale;
        this.autoTransfer = data.autoTransfer;
        this.autoTransferSeconds = data.autoTransferSeconds ?? 30;
        this.autoTransferWarningSeconds = data.autoTransferWarningSeconds ?? 2 * Math.floor(this.autoTransferSeconds / 3);
        this.isAsync = data.isAsync;
        this.conversationId = data.conversationId;
        this.agentTransferNotes = data.agentTransferNotes;
        this.targetQueueBusinessUnitId = data.targetQueueBusinessUnitId;
        this.targetQueueId = data.targetQueueId;
        this.suggestions = data.xaSuggestions ?? [];
        this.deviceType = data.deviceType;
        this.accountNumber = data.accountNumber ?? '';
        this.authenticated = data.authenticated ?? false;
        this.isAccountConnected = data.isAccountConnected ?? false;
        this.previousEngagementId = data.previousEngagementId ?? '';
        this.isReconnectEngagement = data.previousEngagementId ? true : false;
        this.itgSuggestions = data.itgSuggestions ?? [];
        this.schedulerSuggestions = data.schedulerSuggestions ?? [];
        this.oneCtiConnected = data.oneCtiConnected ?? false;
        this.recommendedIntents = data.recommendedIntents ?? [];
        this.startedItgIntents = data.startedItgIntents ?? [];
        this.smartResponsesSent = data.smartResponsesSent ?? [];
        this.xaTranscriptEndTimestamp = data.xaTranscriptEndTimestamp;
        this.agentsFeatureFlags = data.agentsFeatureFlags;
        this.events = data.events ?? [];
        this.customerDetails = data.customerDetails;
        this.agentAlias = data.agentAlias;
        this.summaryData = data.summaryData;
        this.customerAwaitingReplyMessagePriority = 0;
        this.cxGptResponseSent = data.cxGptResponseSent ?? [];
        this.contactMethod = data.contactMethod;
        this.customerLanguage = data.customerLanguage;
        this.customerPhoneNumber = data.customerPhoneNumber;
        this.isAbandonedAsyncChat = data.isAbandonedAsyncChat;
		    this.defaultGroupTranslationLanguage = data.defaultTranslationLanguage;
        this.isTranslationConfigEnabled = data.isTranslationConfigEnabled;
    }
}
