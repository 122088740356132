// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { DataCollectionBannerText } from '../constants/constants';
import { BannerDetails } from '../domain/models/banner-details';
import { DataCollectionState } from '../domain/models/enums';
import { BannerTypes } from './enums';

export class SecureDataCollectionHelper {
  public static getBannerDetails(state: DataCollectionState): BannerDetails {
    switch (state){
      case DataCollectionState.Requesting:
      case DataCollectionState.Requested:
        const pendingRequestDetails: BannerDetails = {
          type: BannerTypes.Info,
          active: true,
          text: DataCollectionBannerText.requesting,
          dismissible: false
        };
        return pendingRequestDetails;
      case DataCollectionState.TokenTimedOut:
        const expiredRequestDetails: BannerDetails = {
          type: BannerTypes.Error,
          active: true,
          text: DataCollectionBannerText.expired,
          dismissible: true
        };
        return expiredRequestDetails;
      case DataCollectionState.AgentCancelled:
      case DataCollectionState.CustomerClosed:
        const canceledRequestDetails: BannerDetails = {
          type: BannerTypes.Error,
          active: true,
          text: DataCollectionBannerText.canceled,
          dismissible: true
        };
        return canceledRequestDetails;
      default:
        return null;
    }
  }

  public static isRequestEnabledDataCollectionState(state: DataCollectionState): boolean {
    switch (state){
      case DataCollectionState.Inactive:
      case DataCollectionState.AgentCancelled:
      case DataCollectionState.CustomerClosed:
      case DataCollectionState.TokenTimedOut:
      case DataCollectionState.CustomerCompleted:
        return true;
      default:
        return false;
    }
  }

  public static isDataCollectionActive(state: DataCollectionState): boolean {
    switch (state) {
      case DataCollectionState.Requested:
      case DataCollectionState.Requesting:
      case DataCollectionState.CustomerStarted:
      case DataCollectionState.CustomerApplied:
      case DataCollectionState.Available:
      case DataCollectionState.LinkSent:
        return true;
      default:
        return false;
    }
  }

  public static isDataCollectionFinished(state: DataCollectionState): boolean {
    switch (state) {
      case DataCollectionState.CustomerClosed:
      case DataCollectionState.CustomerCompleted:
      case DataCollectionState.AgentCancelled:
      case DataCollectionState.TokenTimedOut:
        return true;
      default:
        return false;
    }
  }
}
